import type { Entry } from 'contentful'

import { getContentEntry } from './client'
import { CDX_SETTINGS_SITE_CONTENT_MODEL_ID } from '../globals/siteSettings'
import type { CDXSettingsSiteFields } from '../globals/siteSettings'

export const fetchSiteConfig = async (queryOpts: {
  env?: string
  isPreview?: boolean
  locale?: string
}): Promise<Entry<CDXSettingsSiteFields>> => {
  const entry = (await getContentEntry({
    env: queryOpts.env,
    isPreview: queryOpts.isPreview,
    contentType: CDX_SETTINGS_SITE_CONTENT_MODEL_ID,
    locale: queryOpts.locale,
  })) as Entry<CDXSettingsSiteFields>

  return entry
}
