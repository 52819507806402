import { createContext } from 'react'

export const CarouselContext = createContext<{
  activeSlideIndex: number
  numberOfSlides: number
  hideButtonsOnEdges: boolean | undefined
  paginate: (direction: number) => void
}>({
  activeSlideIndex: 0,
  numberOfSlides: 0,
  hideButtonsOnEdges: false,
  paginate: (direction) => void null,
})
