import { componentMap } from ':cbam/components/componentMap'
import { BASE_PATH, APPLICATION_SETTINGS_EXTRA_PROPS } from ':cbam/constants'
import {
  ContentPage,
  getStaticPropsContentPage,
  getStaticPathsContentPage,
} from '@cbhq/cdx-next-contentful'
import type { ContentPageProps } from '@cbhq/cdx-next-contentful'

const Page = (props: ContentPageProps) => (
  <ContentPage
    {...props}
    applicationSettingsExtraProps={APPLICATION_SETTINGS_EXTRA_PROPS}
    componentMap={componentMap}
  />
)

export default Page

export const getStaticProps = getStaticPropsContentPage()

export const getStaticPaths = getStaticPathsContentPage({
  slugBlacklist: [`${BASE_PATH}/search`],
})
