import type { Document } from '@contentful/rich-text-types'

import { customComponentThemeMap } from ':cbam/components/customComponentThemeMap'
import { LogoMark } from '@cbhq/cds-web/icons/LogoMark'
import type {
  ComponentsThemeStyle,
  TwoColumnLayoutMainContentPosition,
  CTAButtonsProps,
  EmailOnlyUserFormProps,
  NeverProps,
} from '@cbhq/cdx-components'
import {
  SectionComponentLayout,
  TwoColumnLayout,
  CTAButtons,
  Div,
  EmailOnlyUserForm,
  LegalDisclaimer,
  createListRichTextOptions,
  RichText,
  Text,
} from '@cbhq/cdx-components'

type CTAEmailFormContentProps = EmailOnlyUserFormProps & {
  ctaType?: 'form'
} & NeverProps<CTAButtonsProps>

type CTALinksContentProps = CTAButtonsProps & {
  ctaType?: 'links'
} & NeverProps<EmailOnlyUserFormProps>

export type HeroPrimaryContentProps = {
  eyebrow?: string
  hasEyebrowIcon?: boolean
  headline: string
  excerpt?: string
  list?: Document
  legalText?: Document
  ctaType?: 'links' | 'form'
} & (CTALinksContentProps | CTAEmailFormContentProps)

type HeroConentRenderProps = {
  alignContent: 'start' | 'center'
  style: ComponentsThemeStyle
  layout: TwoColumnLayoutMainContentPosition
}

export const HeroPrimaryContent = ({
  eyebrow,
  hasEyebrowIcon = false,
  headline,
  excerpt,
  list,
  legalText,

  ctaType = 'links',

  ctaButtonLabel,
  ctaButtonUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,

  successMessage,
  placeholder,
  submitButtonLabel,
  onSuccess,
  onSubmit,

  alignContent,
  layout,
  style,
}: HeroPrimaryContentProps & HeroConentRenderProps) => {
  const { background, foreground, foregroundSecondary } =
    customComponentThemeMap[style]

  const titleContent = (
    <>
      {!!eyebrow && (
        <Div css={{ gap: '8px' }}>
          {hasEyebrowIcon && <LogoMark size={16} />}
          <Text variant="label1" as="p" color={foregroundSecondary}>
            {eyebrow}
          </Text>
        </Div>
      )}

      <Text
        variant={{ sm: 'display2', lg: 'display1' }}
        as="h1"
        color={foreground}
      >
        {headline}
      </Text>
    </>
  )

  const isForm = ctaType === 'form'

  const actionContent = isForm ? (
    <EmailOnlyUserForm
      submitButtonLabel={submitButtonLabel}
      successMessage={successMessage}
      placeholder={placeholder}
      onSuccess={onSuccess}
      onSubmit={onSubmit!}
      style={style}
    />
  ) : (
    <CTAButtons
      ctaButtonLabel={ctaButtonLabel}
      ctaButtonUrl={ctaButtonUrl}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonUrl={secondaryButtonUrl}
      contentBackground={background}
    />
  )

  const descriptionContent = (
    <>
      {excerpt && (
        <Text
          spacingTop={{ sm: 1, md: 2, lg: 3 }}
          variant={{ sm: 'body', lg: 'title2' }}
          as="p"
          color={foregroundSecondary}
        >
          {excerpt}
        </Text>
      )}

      {list && (
        <RichText
          content={list}
          richTextOptions={createListRichTextOptions()}
          color={foregroundSecondary}
        />
      )}

      {actionContent}

      {legalText && (
        <Div spacingTop={{ sm: 3, lg: 5 }} flexDirection="column">
          <LegalDisclaimer
            legalDisclaimer={legalText}
            color={foregroundSecondary}
          />
        </Div>
      )}
    </>
  )

  return (
    <SectionComponentLayout
      hasSpacingHorizontal
      hasSpacingVertical
      background="transparent"
    >
      {layout === 'top' || layout === 'bottom' ? (
        <TwoColumnLayout
          mainContentPosition="left"
          mainContent={
            <Div
              flexDirection="column"
              justifyContent="center"
              alignItems={alignContent}
              flexGrow={1}
              spacingVertical={5}
            >
              {titleContent}
            </Div>
          }
          mainContentPercentage={50}
          secondaryContent={
            <Div
              flexDirection="column"
              justifyContent="center"
              alignItems={alignContent}
              flexGrow={1}
            >
              {descriptionContent}
            </Div>
          }
          secondaryContentPercentage={50}
        />
      ) : (
        <Div
          flexDirection="column"
          justifyContent="center"
          alignItems={alignContent}
          flexGrow={1}
          minHeight={{ sm: 300, lg: 725 }}
        >
          {titleContent}
          {descriptionContent}
        </Div>
      )}
    </SectionComponentLayout>
  )
}
