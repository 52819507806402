import { HeroPrimaryContent } from './HeroPrimaryContent'
import type { HeroPrimaryContentProps } from './HeroPrimaryContent'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { TwoColumnLayout } from '../../layout/TwoColumnLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import type { ImageFit } from '../../ui/Image/Image'
import type { LottieTypes } from '../../ui/Lottie'
import type { MediaType } from '../../ui/media'
import { Media } from '../../ui/media'

export type HeroPrimaryImageFit = ImageFit

export type HeroPrimaryProps = HeroPrimaryContentProps & {
  mediaUrl?: string
  mediaAlt?: string
  mediaType?: MediaType | 'none'
  style?: ComponentsThemeStyle
  layout: 'right' | 'left' | 'top' | 'bottom'
  imageFit?: HeroPrimaryImageFit
  lottiLoop?: boolean
  lottieType?: LottieTypes
  thumbnailUrl?: string
  thumbnailAlt?: string
  hasLightbox?: boolean
  anchor?: string
}

const DEFAULT_ALT_TEXT = 'Hero image'

export const HeroPrimary = ({
  style = 'default',
  layout,

  mediaUrl,
  mediaAlt,
  mediaType = 'image',
  hasLightbox,
  imageFit,
  lottiLoop,
  lottieType,
  anchor,

  thumbnailUrl,
  thumbnailAlt,

  ...contentProps
}: HeroPrimaryProps) => {
  const { background } = componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HeroPrimary">
        <SectionComponentLayout
          as="header"
          background={background}
          hasSpacingHorizontal={false}
          anchor={anchor}
        >
          <TwoColumnLayout
            mainContentPosition={layout}
            mainContentPositionOnWrap={layout === 'bottom' ? 'bottom' : 'top'}
            mainContent={
              <HeroPrimaryContent
                {...contentProps}
                alignContent="start"
                style={style}
                layout={layout}
              />
            }
            mainContentPercentage={50}
            secondaryContent={
              <>
                {mediaType === 'image' && (
                  <Media
                    priority
                    loading="eager"
                    mediaType="image"
                    alt={mediaAlt || DEFAULT_ALT_TEXT}
                    src={mediaUrl}
                    maxHeight={
                      layout === 'top' || layout === 'bottom'
                        ? { sm: 197, md: 403, lg: 756 }
                        : { sm: 473, md: 'unset' }
                    }
                    imageFit={imageFit}
                    hasLightbox={hasLightbox}
                  />
                )}

                {mediaType === 'video' && mediaUrl && (
                  <Media
                    mediaType="video"
                    src={mediaUrl}
                    thumbnailUrl={thumbnailUrl}
                    thumbnailAlt={thumbnailAlt}
                  />
                )}

                {mediaType === 'lotti' && lottieType && (
                  <Media
                    mediaType="lotti"
                    lottieLoop={lottiLoop}
                    lottieType={lottieType}
                    lottieImageFit={imageFit}
                  />
                )}
              </>
            }
            secondaryContentPercentage={50}
          />
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
