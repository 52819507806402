import type { Entry } from 'contentful'

import { HighlightAssetList as HighlightAssetListComponent } from '@cbhq/cdx-components'
import type { HighlightAssetProps } from '@cbhq/cdx-components'

import { getEntryProps } from '../../utils/getEntryProps'

export const HIGHLIGHT_ASSET_LIST_MODEL_ID = 'cdxHighlightAssetList'

export type HighlightAssetFields = {
  name: string
  logo: Entry<{
    file: {
      url: string
    }
  }>
  link?: Entry<{
    url: string
  }>
}

export type HighlightAssetListProps = {
  assets: Array<Entry<HighlightAssetFields>>
  title?: string
  anchor?: string
}

export const HighlightAssetList = ({
  assets,
  title,
  anchor,
}: HighlightAssetListProps) => {
  const formattedAssets = assets?.map((asset): HighlightAssetProps => {
    const { name = '', link, logo } = getEntryProps(asset)
    const { url: href = '' } = getEntryProps(link)
    const { file } = getEntryProps(logo)

    return {
      name,
      link: href,
      logoSrc: file?.url || '',
    }
  })

  return (
    <HighlightAssetListComponent
      assets={formattedAssets}
      title={title}
      anchor={anchor}
    />
  )
}
