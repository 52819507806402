import { componentsThemeMap } from '../../componentsThemeMap'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'

export type HighlightAvatarCardsProps = {
  headline?: string
  subhead?: string
  children: React.ReactNode
  gridColumns: number
  style: ComponentsThemeStyle
  anchor?: string
  hasSpacingTop?: boolean
}

export const HighlightAvatarCards = ({
  headline,
  subhead,
  children,
  gridColumns,
  style = 'default',
  anchor,
  hasSpacingTop,
}: HighlightAvatarCardsProps) => {
  const { background, foreground, foregroundSecondary } =
    componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightAvatarCards">
        <SectionComponentLayout
          anchor={anchor}
          hasSpacingHorizontal
          background={background}
          hasSpacingTop={hasSpacingTop}
          hasNonDefaultBackgroundSpacingVertical
        >
          <Div width="100%" flexDirection="column">
            <Div alignItems="flex-start" flex={1} flexDirection="column">
              {headline && (
                <Text
                  as="h3"
                  variant="display2"
                  color={foreground}
                  spacingEnd={{ md: 10 }}
                >
                  {headline}
                </Text>
              )}
              {subhead && (
                <Text
                  as="p"
                  variant="body"
                  spacingTop={2}
                  color={foregroundSecondary}
                >
                  {subhead}
                </Text>
              )}
            </Div>

            <Div spacingTop={7} flexDirection="column" alignItems="center">
              <Div
                display="grid"
                width={{ xl: '100%' }}
                rowGap={{ sm: 12, md: 8, lg: 12 }}
                columnGap={{ md: 9, lg: 2, xl: 7 }}
                gridTemplateColumns={{
                  sm: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                  lg: `repeat(${gridColumns}, 1fr)`,
                }}
              >
                {children}
              </Div>
            </Div>
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
