import type { Entry } from 'contentful'

import { CMSContent } from '@cbhq/cdx-components'

import type { CDXSettingsProjectFields } from '../../globals/projectSettings'
import type { CDXSettingsSiteFields } from '../../globals/siteSettings'
import { getEntryProps } from '../../utils/getEntryProps'
import type { TemplateLayoutProps } from '../PageTemplateLayout'
import { PageTemplateLayout } from '../PageTemplateLayout'
import type { PageTemplateProvidersProps } from '../PageTemplateProviders'
import { PageTemplateProviders } from '../PageTemplateProviders'

export const LANDING_TEMPLATE_CONTENT_MODEL_ID = 'cdxTemplateLandingPage'

export type LandingTemplateFields = {
  content: Entry<any>[]
  projectSettings: Entry<CDXSettingsProjectFields>
  countrySpecificFooterDisclaimer?: Entry<any>[]
}

export type LandingTemplateExtraProps = {
  siteConfig: Entry<CDXSettingsSiteFields>
  canonicalUrl?: string
}

export const LandingTemplate = ({
  content,
  projectSettings,
  countrySpecificFooterDisclaimer,

  siteConfig,

  path,
  onSearch,
  templateWidth,
  applicationSettingsExtraProps,

  componentMap,
  ...pageTemplateProviderProps
}: LandingTemplateFields &
  LandingTemplateExtraProps &
  PageTemplateProvidersProps &
  TemplateLayoutProps) => {
  const { productId, fallbackImage } = getEntryProps(projectSettings)

  const fallbackImageUrl = fallbackImage?.fields.file.url
  const fallbackImageAlt = fallbackImage?.fields.title
  return (
    <PageTemplateProviders
      siteConfig={siteConfig}
      componentMap={componentMap}
      {...pageTemplateProviderProps}
    >
      <PageTemplateLayout
        siteConfig={siteConfig}
        projectConfig={projectSettings}
        path={path}
        templateWidth={templateWidth}
        onSearch={onSearch}
        applicationSettingsExtraProps={applicationSettingsExtraProps}
      >
        <CMSContent
          content={content || []}
          additionalProps={{ productId, fallbackImageAlt, fallbackImageUrl }}
        />
      </PageTemplateLayout>
    </PageTemplateProviders>
  )
}
