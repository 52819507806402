import { HeroFeaturedEditorial as HeroFeaturedEditorialComponent } from '@cbhq/cdx-components'

import type { ComposePageEntry } from '../../globals/composePage'
import { getComposeEntryProps } from '../../globals/composePage'
import { mapEntryLink } from '../../globals/navlink'
import type { EditorialTemplateFields } from '../../templates/Editorial'
import { getEntryProps } from '../../utils/getEntryProps'

export type HeroFeaturedEditorialFields = {
  featuredEditorialPage: ComposePageEntry<EditorialTemplateFields>
  layout: 'left' | 'right'
  eyebrow?: string
}

export const HERO_FEATURED_EDITORIAL_CONTENT_MODEL_ID =
  'cdxHeroFeaturedEditorial'

export const HeroFeaturedEditorial = ({
  featuredEditorialPage,
  layout,
  eyebrow,
}: HeroFeaturedEditorialFields) => {
  if (!featuredEditorialPage) return null

  // TODO: map to new template fields
  const {
    headline,
    excerpt,
    image,
    composeSlug,
    tags,
    readTime,
    publicationDate,
  } = getComposeEntryProps(featuredEditorialPage)

  const categoryTag = tags?.find((tag) => tag.fields.type !== 'internal')
  const { label, link } = getEntryProps(categoryTag)
  const tagLink = mapEntryLink(link)

  return (
    <HeroFeaturedEditorialComponent
      headline={headline || ''}
      excerpt={excerpt}
      mediaUrl={image?.fields.file?.url}
      mediaAlt={image?.fields.title}
      bordered
      layout={layout}
      eyebrow={eyebrow}
      url={composeSlug}
      readTime={readTime}
      publicationDate={publicationDate}
      categoryTagLabel={label}
      categoryTagUrl={tagLink.url && `/${tagLink.url}`}
    />
  )
}
