import type { Document } from '@contentful/rich-text-types'
import type { Entry } from 'contentful'

import type {
  ComparisonColumn,
  RowLabel,
  ComponentsThemeStyle,
} from '@cbhq/cdx-components'
import { HighlightComparisonTable as HighlightComparisonTableComponent } from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'

export const HIGHLIGHT_COMPARISON_TABLE_CONTENT_MODEL_ID =
  'cdxHighlightComparisonTable'

export type HighlightComparisonTableFields = {
  heading: string
  subhead?: Document
  legalDisclaimer?: string
  rowLabels?: Entry<ComponentRowLabelFields>[]
  columns?: Entry<ComponentComparisonColumnFields>[]
  tableStyle?: 'alternate' | 'white'
  hasSpacingTop?: boolean
  style?: ComponentsThemeStyle
  anchor?: string
}

export type ComponentRowLabelFields = {
  label?: string
  subLabel?: Document
}

export type ComponentComparisonColumnFields = {
  heading: string
  subhead?: Document
  highlighted?: boolean
  cells: Entry<ComponentComparisonColumnRowFields>[]
  ctaButton?: Entry<CDXGlobalNavLinkFields>
  underline?: Document
}

export type ComponentComparisonColumnRowFields = {
  heading?: string
  subhead?: Document
  hasCheck?: boolean
  isPositiveCheck?: boolean
}

export const HighlightComparisonTable = ({
  columns = [],
  rowLabels = [],
  heading,
  legalDisclaimer,
  style,
  subhead,
  tableStyle,
  anchor,
  hasSpacingTop,
}: HighlightComparisonTableFields) => {
  const mappedRowLabels: RowLabel[] = rowLabels.map(({ fields }) => fields)

  const mappedComparisonColumns: ComparisonColumn[] = columns.map((column) => {
    const fields = column.fields
    const { url, label } = mapEntryLink(fields.ctaButton)

    return {
      ...fields,
      cells: fields.cells.map((cells) => cells.fields),
      ctaLabel: label,
      ctaUrl: url,
    }
  })

  return (
    <HighlightComparisonTableComponent
      headline={heading}
      legalDisclaimer={legalDisclaimer}
      style={style}
      subhead={subhead}
      tableStyle={tableStyle}
      comparisonColumns={mappedComparisonColumns}
      rowLabels={mappedRowLabels}
      hasSpacingTop={hasSpacingTop}
      anchor={anchor}
    />
  )
}
