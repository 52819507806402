import type { Entry } from 'contentful'

import {
  HighlightStatChips as HighlightStatChipsComponent,
  StatChip,
} from '@cbhq/cdx-components'

import type { ContentfulComponentsStyleOptions } from '../contentToPropsMap'
import { CONTENTFUL_COMPONENTS_STYLE_MAP } from '../contentToPropsMap'

export const HIGHLIGHT_STAT_CHIPS_CONTENT_MODEL_ID = 'cdxHighlightStatChips'

export type HighlightStatChipsFields = {
  anchor?: string
  subText?: string
  headline?: string
  style: ContentfulComponentsStyleOptions
  chips: Entry<StatChipFields>[]
  hasSpacingTop?: boolean
}

type StatChipFields = {
  metric?: string
  caption?: string
}

export const HighlightStatChips = ({
  headline,
  subText,
  chips,
  style: styleProp,
  anchor,
  hasSpacingTop,
}: HighlightStatChipsFields) => {
  const style = CONTENTFUL_COMPONENTS_STYLE_MAP[styleProp]

  return (
    <HighlightStatChipsComponent
      style={style}
      anchor={anchor}
      subText={subText}
      headline={headline}
      hasSpacingTop={hasSpacingTop}
    >
      {chips &&
        chips.map(({ fields }, index) => (
          <StatChip style={style} key={index} {...fields} />
        ))}
    </HighlightStatChipsComponent>
  )
}
