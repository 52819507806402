import type { Document } from '@contentful/rich-text-types'

import type { SpectrumAlias } from '@cbhq/cds-common'
import type { PaletteAlias } from '@cbhq/cds-web'
import { Icon } from '@cbhq/cds-web/icons'

import { labelOptions } from './RichtextOptions'
import { ANALYTICS_KEY } from '../../constants'
import { Button } from '../../ui/Button'
import { Div } from '../../ui/Div'
import { LinkButton } from '../../ui/Link'
import { RichText } from '../../ui/RichText'
import { Text } from '../../ui/Text'

type RowProps = {
  isColumnHead?: boolean
  foreground?: PaletteAlias
  foregroundSecondary?: PaletteAlias
  background?: PaletteAlias | SpectrumAlias
  borderColor?: PaletteAlias
  headline?: string
  subhead?: Document
  label?: string
  subLabel?: Document
  alignContent?: 'start' | 'center'
  justifyContent?: 'center' | 'start'
  borderedEnd?: boolean
  bordered?: boolean
  hasCheck?: boolean
  isPositiveCheck?: boolean
  ctaUrl?: string
  ctaLabel?: string
  ctaVariant?: 'primary' | 'secondary' | 'positive'
}

export const Row = ({
  isColumnHead,
  headline,
  subhead,
  label,
  subLabel,
  foreground,
  foregroundSecondary,
  background,
  borderColor = 'primary',
  alignContent = 'center',
  justifyContent = 'center',
  borderedEnd,
  bordered,
  hasCheck,
  isPositiveCheck = true,
  ctaUrl,
  ctaLabel,
  ctaVariant,
}: RowProps) => {
  return (
    <Div
      flexDirection="column"
      spacing={2}
      minHeight={88}
      background={background}
      justifyContent={justifyContent}
      alignItems={alignContent}
      css={{ p: { textAlign: alignContent } }}
      borderedStart={bordered}
      borderedEnd={bordered}
      borderedBottom={borderedEnd}
      borderColor={borderColor}
    >
      {headline && (
        <Text
          variant={isColumnHead ? 'title3' : 'label1'}
          color={foreground}
          css={{ textAlign: alignContent }}
        >
          {headline}
        </Text>
      )}

      {subhead && (
        <RichText
          content={subhead}
          color={foregroundSecondary}
          richTextOptions={labelOptions}
          defaultSpacingTop={0.5}
        />
      )}

      {label && (
        <Text
          variant={{ sm: 'label2', lg: 'label1' }}
          color={foreground}
          css={{ textAlign: alignContent }}
        >
          {label}
        </Text>
      )}

      {ctaLabel &&
        (ctaUrl ? (
          <LinkButton
            variant={ctaVariant}
            href={ctaUrl}
            data-qa={ANALYTICS_KEY.Row}
          >
            {ctaLabel}
          </LinkButton>
        ) : (
          <Button variant={ctaVariant} disabled>
            {ctaLabel}
          </Button>
        ))}

      {subLabel && (
        <RichText
          content={subLabel}
          color={foregroundSecondary}
          richTextOptions={labelOptions}
          defaultSpacingTop={0.5}
        />
      )}

      {hasCheck && (
        <Icon
          name="circleCheckmark"
          color={isPositiveCheck ? 'positive' : 'foregroundMuted'}
          size="m"
        />
      )}
    </Div>
  )
}
