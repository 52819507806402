import type { Document } from '@contentful/rich-text-types'

import type { IconName, PaletteForeground } from '@cbhq/cds-common'
import type { ElevationLevels } from '@cbhq/cds-web'

import { colorToVariable, componentsThemeMap } from '../../componentsThemeMap'
import type {
  ComponentsThemeStyle,
  ComponenThemeProps,
} from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { LinkButton } from '../../ui/Link'
import { RichText, createListRichTextOptions } from '../../ui/RichText'
import { Text } from '../../ui/Text'

export type ComparisonCardProps = {
  title?: string
  subtitle?: string
  description?: string
  listTitle?: string
  list?: Document
  listStyle?: IconName
  ctaLabel?: string
  ctaUrl?: string
  style?: ComponentsThemeStyle
}

const customComponentThemeMap: {
  [key in ComponentsThemeStyle]: ComponenThemeProps & {
    elevation: ElevationLevels
  }
} = {
  default: {
    ...componentsThemeMap.default,
    elevation: 1,
  },
  dark: {
    ...componentsThemeMap.dark,
    background: 'foregroundMuted',
    foreground: 'primaryForeground',
    foregroundSecondary: 'primaryForeground',
    elevation: 0,
  },
  subtle: {
    ...componentsThemeMap.subtle,
    elevation: 1,
  },
  wash: {
    ...componentsThemeMap.wash,
    elevation: 1,
  },
  darkwash: {
    ...componentsThemeMap.darkwash,
    elevation: 1,
  },
  primary: {
    ...componentsThemeMap.primary,
    elevation: 1,
  },
}

export const ComparisonCard = ({
  title,
  subtitle,
  description,
  listTitle,
  list,
  ctaLabel,
  ctaUrl,
  style = 'default',
  listStyle,
}: ComparisonCardProps) => {
  const { background, foreground, foregroundSecondary, elevation, borderLine } =
    customComponentThemeMap[style]

  return (
    <Div
      gap={4}
      background={background}
      elevation={elevation}
      spacing={{ sm: 2, md: 5 }}
      flexDirection="column"
      borderRadius="roundedSmall"
      justifyContent="space-between"
      height="100%"
      maxWidth={400}
      css={{
        borderColor: colorToVariable(borderLine || 'line'),
      }}
    >
      <Div flexDirection="column" justifyContent="space-between" flex={1}>
        <Text
          as="span"
          spacingTop={1}
          variant={{ sm: 'title4', md: 'title2' }}
          color={foregroundSecondary}
          flex={1}
        >
          {title}
        </Text>
        <Text
          as="span"
          color={foreground}
          spacingTop={1}
          variant="headline"
          flex={1}
        >
          {subtitle}
        </Text>
        <Text as="p" variant="body" spacingTop={1} color={foregroundSecondary}>
          {description}
        </Text>
      </Div>
      <Div flexDirection="column" justifyContent="space-between" flex={1}>
        <Text
          as="span"
          color={foreground}
          spacingTop={1}
          variant="headline"
          flex={1}
        >
          {listTitle}
        </Text>
        {list && (
          <Div
            css={{
              display: 'contents',
              p: {
                color: colorToVariable(foregroundSecondary),
              },
            }}
          >
            <RichText
              content={list}
              richTextOptions={createListRichTextOptions(
                listStyle,
                foregroundSecondary as PaletteForeground,
              )}
              color={foregroundSecondary}
            />
          </Div>
        )}
      </Div>
      <Div
        alignItems="center"
        flex={1}
        css={{
          div: {
            flex: 1,
            button: {
              flex: 1,
            },
          },
        }}
      >
        {ctaLabel && ctaUrl && (
          <Div alignSelf="flex-end">
            <LinkButton
              href={ctaUrl}
              variant="secondary"
              data-qa={ANALYTICS_KEY.ComparisonCardCTA}
            >
              {ctaLabel}
            </LinkButton>
          </Div>
        )}
      </Div>
    </Div>
  )
}
