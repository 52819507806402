import { useState } from 'react'
import type { ReactElement } from 'react'

import { TabNavigation as TabNavigationCDS } from '@cbhq/cds-web/tabs/TabNavigation'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'

export type Tab = {
  id: string
  label: string
}

type TabNavigationProps = {
  tabs: Tab[]
  children: ReactElement[]
  startTabId: string
  style?: ComponentsThemeStyle
}

export const TabNavigation = ({
  tabs,
  children,
  startTabId,
  style = 'default',
}: TabNavigationProps) => {
  const [activeTab, setActiveTab] = useState<string>(
    startTabId || tabs?.[0]?.id,
  )

  const { background } = componentsThemeMap[style]

  return (
    <>
      <TabNavigationCDS
        value={activeTab}
        tabs={tabs}
        onChange={(value: string) => {
          setActiveTab(value)
        }}
        background={background as any}
      />
      {tabs?.length > 0 &&
        children.filter((child: any, i) => {
          return Number(activeTab) === i
        })}
    </>
  )
}
