import { useMemo } from 'react'

import type { Entry } from 'contentful'

import {
  WayfindingTabCards as TabCardsSection,
  TabCard,
  Div,
  CMSContent,
} from '@cbhq/cdx-components'
import type { Tab, ComponentsThemeStyle } from '@cbhq/cdx-components'

import type { HighlightCardIllustrationFields } from '../../components/HighlightCardIllustration'
import type { WayfindingCardArticleFields } from '../../components/WayfindingCardArticle'
import type { WayfindingResourceListFields } from '../../components/WayfindingResourceList'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'

export const TAB_CARDS_CONTENT_MODEL_ID = 'cdxTabCards'

export type TabCardFields = {
  label: string
  link?: Entry<CDXGlobalNavLinkFields>
  content: Array<
    | Entry<HighlightCardIllustrationFields>
    | Entry<WayfindingCardArticleFields>
    | Entry<WayfindingResourceListFields>
  >
}

export type TabCardSectionFields = {
  tabCards: Array<Entry<TabCardFields>>
  eyebrow?: string
  headline?: string
  anchor: string
  style?: ComponentsThemeStyle
}

export const WayfindingTabCards = ({
  tabCards = [],
  eyebrow,
  headline,
  anchor = 'tab-cards',
  style,
}: TabCardSectionFields) => {
  // CDS Tabs expects an id and label.  We'll use index as id to match card children
  const tabs = useMemo(() => {
    return tabCards.map((tab: Entry<TabCardFields>, i: number): Tab => {
      return { label: tab?.fields?.label, id: i.toString() }
    })
  }, [tabCards])

  return (
    <TabCardsSection
      tabs={tabs}
      headline={headline}
      eyebrow={eyebrow}
      anchor={anchor}
      style={style}
    >
      {tabCards &&
        tabCards.map((card, i) => {
          const navLink = card.fields?.link?.fields?.url
          const navLinkLabel = card.fields?.link?.fields?.label

          return (
            <Div flexDirection="column" key={i} spacingTop={7}>
              <TabCard
                navLink={navLink}
                navLinkLabel={navLinkLabel}
                style={style}
              >
                <CMSContent
                  content={card.fields.content?.[0]}
                  additionalProps={{
                    as: 'div',
                    aspectRatio: 'square',
                    hasSpacingHorizontal: false,
                    hasSpacingTop: false,
                    style,
                  }}
                />

                <CMSContent
                  content={card.fields.content?.slice(1)}
                  additionalProps={{
                    as: 'div',
                    aspectRatio: 'square',
                    hasSpacingHorizontal: false,
                    hasSpacingTop: true,
                    style,
                  }}
                />
              </TabCard>
            </Div>
          )
        })}
    </TabCardsSection>
  )
}
