import AccordionCell from './AccordionCell'
import type { AccordionCellProps } from './AccordionCell'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { CTAButtons } from '../../ui/CTAButtons'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'

export type HighlightAccordionProps = {
  title?: string
  subtitle?: string
  ctaLabel?: string
  ctaUrl?: string
  cells?: AccordionCellProps[]
  anchor?: string
  style?: ComponentsThemeStyle
  hasSpacingTop?: boolean
}

export const HighlightAccordion = ({
  title,
  subtitle,
  ctaLabel,
  ctaUrl,
  cells,
  anchor,
  style = 'default',
  hasSpacingTop,
}: HighlightAccordionProps) => {
  const { background, foreground } = componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightAccordion">
        <SectionComponentLayout
          hasSpacingHorizontal
          anchor={anchor}
          hasNonDefaultBackgroundSpacingVertical
          background={background}
          hasSpacingTop={hasSpacingTop}
        >
          <Div
            justifyContent="space-between"
            alignItems="flex-start"
            flexDirection="column"
            alignSelf="center"
            width="100%"
          >
            {title && (
              <Text
                as="h3"
                color={foreground}
                spacingEnd={{ md: 10 }}
                variant={{ sm: 'title2', md: 'display2' }}
              >
                {title}
              </Text>
            )}

            <Div alignItems="center" width="100%">
              <Div flexGrow={1} flexShrink={1} flexBasis="100%">
                {subtitle && (
                  <Text
                    as="p"
                    spacingTop={2}
                    color={foreground}
                    spacingEnd={{ md: 10 }}
                    variant={{ sm: 'body', md: 'title4' }}
                  >
                    {subtitle}
                  </Text>
                )}
              </Div>

              <Div
                spacingStart={4}
                flexGrow={1}
                flexShrink={1}
                display={{ sm: 'none', md: 'flex' }}
              >
                {ctaUrl && ctaLabel && (
                  <CTAButtons
                    ctaButtonUrl={ctaUrl}
                    ctaButtonLabel={ctaLabel}
                    contentBackground={background}
                  />
                )}
              </Div>
            </Div>

            <Div
              width="100%"
              spacingTop={title || subtitle ? 8 : 3}
              flexDirection="column"
            >
              {cells &&
                cells.map((fields, index) => {
                  return (
                    <AccordionCell
                      style={style}
                      key={index}
                      {...fields}
                      borderBottom={
                        cells.length > 1 && index === cells.length - 1
                      }
                    />
                  )
                })}
            </Div>

            {ctaUrl && ctaLabel && (
              <Div spacingTop={5} width="100%" display={{ md: 'none' }}>
                <CTAButtons
                  ctaButtonUrl={ctaUrl}
                  ctaButtonLabel={ctaLabel}
                  contentBackground={background}
                />
              </Div>
            )}
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
