import type { Entry } from 'contentful'

import { ActionBannerCenter as ActionBannerCenterComponent } from '@cbhq/cdx-components'

import type { CDXGlobalMediaFields } from '../../globals/media'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'
import type { ContentfulComponentsStyleOptions } from '../contentToPropsMap'
import { CONTENTFUL_COMPONENTS_STYLE_MAP } from '../contentToPropsMap'

export const ACTION_BANNER_CENTER_CONTENT_MODEL_ID = 'cdxActionBannerCenter'

export type ActionBannerCenterFields = {
  headline: string
  subhead?: string
  media?: Entry<CDXGlobalMediaFields>
  link: Entry<CDXGlobalNavLinkFields>
  style?: ContentfulComponentsStyleOptions
  anchor?: string
  hasSpacingTop?: boolean
}

export const ActionBannerCenter = ({
  headline,
  subhead,
  media,
  link,
  style = 'Default',
  anchor,
  hasSpacingTop = true,
}: ActionBannerCenterFields) => {
  const navLink = link && mapEntryLink(link)
  const styleValue = CONTENTFUL_COMPONENTS_STYLE_MAP[style]

  return (
    <ActionBannerCenterComponent
      headline={headline}
      subhead={subhead}
      ctaButtonLabel={navLink?.label}
      ctaButtonUrl={navLink?.url}
      mediaAlt={media?.fields?.altText}
      mediaUrl={media?.fields?.media?.fields?.file?.url}
      hasSpacingTop={hasSpacingTop}
      style={styleValue}
      anchor={anchor}
    />
  )
}
