import { EOU_PREVIEW_API, EOU_SUBSCRIBE_API } from './constants'
import { getAllCookies } from './getAllCookies'
import type { EmailApiProductId } from './types'

export type SubscribeData = {
  cryptoAssetIds?: string[]
}

type PostSubscribeArgs = {
  email: string
  countryCode: string
  locale: string
  currency: string
  productId: EmailApiProductId
  data?: SubscribeData
  onError?: (e: string | Error) => void
  isProd?: boolean
}

const emailOnlyUserApiMutation = `
  mutation emailOnlyUserApiMutation(
    $input: SubscribeEmailOnlyUserInput!
  ) {
    subscribeEmailOnlyUser(input: $input) {
      __typename
      ... on SubscribeEmailOnlyUserSuccess {
        dummy
      }
      ... on GenericError {
        message
      }
    }
  }
`

export const postSubscribe = async ({
  email,
  countryCode,
  locale,
  currency,
  productId,
  data,
  onError,
  isProd = false,
}: PostSubscribeArgs) => {
  const { utm } = getAllCookies({ utm: '' })
  const { searchParams } = new URL(window.location.href)
  const getUtmFromUrlOrCookie = (key: string) =>
    searchParams.get(key) || utm?.[key] || ''

  try {
    const response = await fetch(isProd ? EOU_SUBSCRIBE_API : EOU_PREVIEW_API, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        query: emailOnlyUserApiMutation,
        operationName: 'emailOnlyUserApiMutation',
        variables: {
          input: {
            email,
            countryCode,
            locale,
            currency,
            productId,
            subscribeMetadata: {
              utmCampaign: getUtmFromUrlOrCookie('utm_campaign'),
              utmContent: getUtmFromUrlOrCookie('utm_content'),
              utmMedium: getUtmFromUrlOrCookie('utm_medium'),
              utmSource: getUtmFromUrlOrCookie('utm_source'),
              utmTerm: getUtmFromUrlOrCookie('utm_term'),
            },
          },
        },
      }),
    })

    return response.ok
  } catch (e) {
    onError && onError(e as Error)

    return false
  }
}
