import type { Entry, Asset } from 'contentful'

import type { IconName, NavigationIconName } from '@cbhq/cds-common'
import type {
  IllustrationPictogramNames,
  IllustrationSpotRectangleNames,
  IllustrationSpotSquareNames,
} from '@cbhq/cds-web'
import {
  HighlightCardIllustration as HighlightComponent,
  CardIllustration,
} from '@cbhq/cdx-components'

import type { CDXGlobalTagFields } from '../../globals/tag'
import { CONTENTFUL_COMPONENTS_STYLE_MAP } from '../contentToPropsMap'
import type { ContentfulComponentsStyleOptions } from '../contentToPropsMap'

export type IconTypes = IconName & NavigationIconName

export type HighlightCardIllustrationFields = {
  headline: string
  subhead?: string
  ctaButtonLabel?: string
  ctaButtonUrl?: string
  layout: '2up' | '3up' | '4up' | 'split' | 'split-reverse'
  style: ContentfulComponentsStyleOptions
  hasTab?: boolean
  cards: Entry<CardIllustrationFields>[]
  hasSpacingTop?: boolean
}

export type CardIllustrationFields = {
  title: string
  description: string
  icon?: IconTypes
  image?: Asset
  pictogram?:
    | IllustrationPictogramNames
    | IllustrationSpotSquareNames
    | IllustrationSpotRectangleNames
  pictogramStyle?: 'Pictogram' | 'Spot Square' | 'Spot Rectangle'
  ctaLabel: string
  ctaUrl: string
  ctaType?: 'phone' | 'link' | 'card' | 'card-cta'
  tags?: CDXGlobalTagFields[]
}

type InnerProps = {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
  options?: Record<string, string>
  hasSpacingHorizontal?: boolean
}

export const HIGHLIGHT_CARD_ILLUSTRATION_CONTENT_MODEL_ID =
  'cdxHighlightCardIllustration'

export const COMPONENT_CARD_ILLUSTRATION_CONTENT_MODEL_ID =
  'cdxComponentCardIllustration'

const layoutColumnsMap: {
  [key in HighlightCardIllustrationFields['layout']]:
    | number
    | 'split'
    | 'split-reverse'
} = {
  '2up': 2,
  '3up': 3,
  '4up': 4,
  split: 'split',
  'split-reverse': 'split-reverse',
}

export const HighlightCardIllustration = ({
  headline,
  subhead,
  ctaButtonLabel,
  ctaButtonUrl,
  layout,
  style: styleProp,
  cards = [],
  hasSpacingTop = true,

  hasSpacingHorizontal = true,
  as,
  options,
}: HighlightCardIllustrationFields & InnerProps) => {
  const style = CONTENTFUL_COMPONENTS_STYLE_MAP[styleProp]

  return (
    <HighlightComponent
      hasSpacingTop={hasSpacingTop}
      hasSpacingHorizontal={hasSpacingHorizontal}
      headline={headline}
      subhead={subhead}
      ctaButtonLabel={ctaButtonLabel}
      ctaButtonUrl={ctaButtonUrl}
      columns={layoutColumnsMap[layout]}
      style={style}
      as={as}
      {...options}
    >
      {cards.map((card, index) => (
        <CardIllustration
          key={index}
          {...card.fields}
          style={style}
          icon={card.fields.icon}
          pictogram={card.fields.pictogram}
          pictogramStyle={card.fields.pictogramStyle}
          imageAlt={card?.fields?.image?.fields?.title}
          imageUrl={card?.fields?.image?.fields?.file?.url}
        />
      ))}
    </HighlightComponent>
  )
}
