import type { Document } from '@contentful/rich-text-types'
import type { Asset, Entry } from 'contentful'

import { postSubscribe } from '@cbhq/cdx-api'
import type { LottieTypes, FormSettingsFields } from '@cbhq/cdx-components'
import {
  HeroPrimary as HeroComponent,
  useDeploymentTargetContext,
} from '@cbhq/cdx-components'

import { mapEntryLink } from '../../globals/navlink'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import type {
  ContentfulLayoutOptionss,
  ContentfulComponentsStyleOptions,
  ContentfulMediaTypeOptions,
  ContentfulImageFitOptions,
} from '../contentToPropsMap'
import {
  CONTENTFUL_COMPONENTS_STYLE_MAP,
  LAYOUT_MAP,
  MEDIA_TYPE_MAP,
  IMAGE_FIT_MAP,
} from '../contentToPropsMap'

export type HeroFields = {
  anchor?: string
  // TODO: deprecate this in Legacy then update Contentful entries and remove
  ctaButtonUrl?: string
  ctaButtonLabel?: string
  ctaType?: 'links' | 'form'
  excerpt?: string
  eyebrow?: string
  formSettings?: Entry<FormSettingsFields>
  hasEyebrowIcon?: boolean
  hasLightbox?: boolean
  headline: string
  imageFit?: ContentfulImageFitOptions
  layout: ContentfulLayoutOptionss
  legalText?: Document
  list?: Document
  // TODO: rename this field to secondaryLink in Contentful
  link?: Entry<CDXGlobalNavLinkFields>
  lottiLoop: boolean
  lottiType?: LottieTypes
  media?: Asset
  mediaType?: ContentfulMediaTypeOptions
  primaryLink?: Entry<CDXGlobalNavLinkFields>
  subhead?: string
  style: ContentfulComponentsStyleOptions

  thumbnail?: Asset
  videoUrl?: string
}

export const HERO_PRIMARY_CONTENT_MODEL_ID = 'cdxHeroPrimary5050'

export const HeroPrimary = ({
  headline,
  excerpt,
  media,
  layout,
  eyebrow,
  imageFit,
  legalText,

  ctaButtonUrl,
  ctaButtonLabel,
  primaryLink,
  link: secondaryLink,

  list,
  style,
  subhead,
  anchor,
  videoUrl,
  lottiLoop,
  lottiType,
  hasEyebrowIcon,
  mediaType: mediaTypeProp,
  hasLightbox,
  ctaType,
  formSettings,
  thumbnail,
}: HeroFields) => {
  const primaryNavLink = primaryLink && mapEntryLink(primaryLink)
  const secondaryNavLink = secondaryLink && mapEntryLink(secondaryLink)

  const mediaType = mediaTypeProp && MEDIA_TYPE_MAP[mediaTypeProp]
  const mediaUrl = mediaType === 'video' ? videoUrl : media?.fields.file?.url

  const { submitButtonLabel, productId, placeholder, successMessage } =
    formSettings?.fields || {}

  const { deploymentTargetName } = useDeploymentTargetContext()

  // TODO: handle locale
  const { country, localeCode, currency } = {
    country: 'US',
    localeCode: 'en',
    currency: 'USD',
  }

  const onSubmit = async (email: string) => {
    if (!productId) return false

    const result = await postSubscribe({
      email,
      countryCode: country,
      locale: localeCode,
      currency,
      productId: productId,
      isProd: deploymentTargetName === 'production',
    })

    // TODO: log event

    return result
  }

  const defaultProps = {
    list,
    anchor,
    hasEyebrowIcon,
    eyebrow,
    headline,
    mediaUrl,
    mediaType,
    legalText,
    lottiLoop,
    lottieType: lottiType,
    excerpt: excerpt || subhead,
    layout: LAYOUT_MAP[layout],
    hasLightbox: hasLightbox,
    mediaAlt: media?.fields.title,
    imageFit: imageFit && IMAGE_FIT_MAP[imageFit],
    style: CONTENTFUL_COMPONENTS_STYLE_MAP[style],
    thumbnailUrl: thumbnail?.fields.file?.url,
    thumbnailAlt: thumbnail?.fields.title,
  }

  if (ctaType === 'form') {
    return (
      <HeroComponent
        {...defaultProps}
        ctaType="form"
        submitButtonLabel={submitButtonLabel}
        placeholder={placeholder}
        successMessage={successMessage}
        onSubmit={onSubmit}
      />
    )
  }

  return (
    <HeroComponent
      {...defaultProps}
      ctaButtonUrl={primaryNavLink?.url || ctaButtonUrl}
      ctaButtonLabel={primaryNavLink?.label || ctaButtonLabel}
      secondaryButtonUrl={secondaryNavLink?.url}
      secondaryButtonLabel={secondaryNavLink?.label}
    />
  )
}
