import type { Asset, Entry } from 'contentful'

import type { ComponentsThemeStyle } from '@cbhq/cdx-components'
import {
  formatDateWithoutTimeZone,
  Wayfinding,
  WayfindingCardArticle,
} from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'
import { getEntryProps } from '../../utils/getEntryProps'

export const HIGHLIGHT_VIDEO_LIST_CONTENT_MODEL_ID = 'cdxHighlightVideoList'

export type HighlightVideoCardComponentFields = {
  headline: string
  body?: string
  mediaUrl?: string
  thumbnail?: Asset
  date?: string
  readTime?: string
  link?: Entry<CDXGlobalNavLinkFields>
}

export type HighlightVideoListFields = {
  headline: string
  body?: string
  link?: Entry<CDXGlobalNavLinkFields>
  style: ComponentsThemeStyle
  videoList: Entry<HighlightVideoCardComponentFields>[]
  anchor?: string
  hasSpacingTop?: boolean
}

export const HighlightVideoList = ({
  headline,
  body,
  link,
  style,
  videoList,
  anchor,
  hasSpacingTop = true,
}: HighlightVideoListFields) => {
  const { url, label } = mapEntryLink(link)

  return (
    <Wayfinding
      columns={1}
      headline={headline}
      body={body}
      ctaButtonUrl={url}
      ctaButtonLabel={label}
      style={style}
      anchor={anchor}
      hasSpacingTop={hasSpacingTop}
    >
      {videoList?.map((entry, index) => {
        const { headline, body, mediaUrl, thumbnail, date, readTime, link } =
          getEntryProps(entry, { date: '', headline: '' })
        const { url } = mapEntryLink(link)

        return (
          <WayfindingCardArticle
            key={index}
            headline={headline}
            href={url}
            body={body}
            mediaType="video"
            mediaUrl={mediaUrl}
            readTime={readTime}
            mediaAlt="placeholder"
            thumbnailUrl={thumbnail?.fields.file.url}
            datePublished={formatDateWithoutTimeZone(date)}
          />
        )
      })}
    </Wayfinding>
  )
}
