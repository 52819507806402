import type { Document, TableRow } from '@contentful/rich-text-types'
import { BLOCKS } from '@contentful/rich-text-types'

import { logInfo } from '../utils/logs'

const data = {}

export type JSON = {
  data: Record<string, string | number>[]
  headers?: Record<string, string | number>[]
}

export function formatJSONToRichTextTable(json: JSON): Document {
  try {
    const headers: TableRow = json?.headers
      ? {
          nodeType: BLOCKS.TABLE_ROW,
          data,
          content: json?.headers?.map((header) => ({
            nodeType: BLOCKS.TABLE_HEADER_CELL,
            data,
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data,
                content: [
                  {
                    nodeType: 'text',
                    data,
                    content: [],
                    value: Object.keys(header)?.[0] + '',
                    marks: [{ type: 'bold' }],
                  },
                ],
              },
            ],
          })),
        }
      : {
          nodeType: BLOCKS.TABLE_ROW,
          data,
          content: Object.keys(json?.data?.[0])?.map((firstRow) => ({
            nodeType: BLOCKS.TABLE_HEADER_CELL,
            data,
            content: [
              {
                nodeType: BLOCKS.PARAGRAPH,
                data,
                content: [
                  {
                    nodeType: 'text',
                    data,
                    value: firstRow + '',
                    marks: [{ type: 'bold' }],
                  },
                ],
              },
            ],
          })),
        }

    const headersValues = json?.headers?.map(
      (header) => Object.values(header)?.[0],
    )
    const rows: TableRow[] = json?.data?.map((row) => ({
      nodeType: BLOCKS.TABLE_ROW,
      data,
      content: Object.keys(row)
        ?.filter((key) => (headersValues ? headersValues?.includes(key) : true))
        ?.sort((aKey, bKey) => {
          const aI = headersValues?.indexOf(aKey) ?? 0
          const bI = headersValues?.indexOf(bKey) ?? 0
          return aI - bI
        })
        ?.map((key) => ({
          nodeType: BLOCKS.TABLE_CELL,
          data,
          content: [
            {
              nodeType: BLOCKS.PARAGRAPH,
              data,
              content: [
                {
                  nodeType: 'text',
                  data,
                  value: row[key] + '',
                  marks: [],
                },
              ],
            },
          ],
        })),
    }))
    return {
      nodeType: BLOCKS.DOCUMENT,
      data,
      content: [
        {
          nodeType: BLOCKS.TABLE,
          data,
          content: [headers, ...rows],
        },
      ],
    }
  } catch (error) {
    logInfo('Error formatting JSON to RichText Table', (error as Error).message)
    return {
      nodeType: BLOCKS.DOCUMENT,
      data,
      content: [
        {
          nodeType: BLOCKS.TABLE,
          data,
          content: [],
        },
      ],
    }
  }
}
