import type { ReactNode } from 'react'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { CTAButtons } from '../../ui/CTAButtons'

type CardProps = {
  children: ReactNode
  navLink?: string
  navLinkLabel?: string
  style?: ComponentsThemeStyle
}

export const TabCard = ({
  children,
  navLink,
  navLinkLabel,
  style = 'default',
}: CardProps) => {
  const colors = componentsThemeMap[style]

  return (
    <>
      {children}
      {navLink && navLinkLabel && (
        <CTAButtons
          ctaButtonLabel={navLinkLabel}
          ctaButtonUrl={navLink}
          contentBackground={colors.background}
        />
      )}
    </>
  )
}
