import type { Document } from '@contentful/rich-text-types'

import { useToggler } from '@cbhq/cds-common/hooks/useToggler'
import { IconButton } from '@cbhq/cds-web/buttons/IconButton'
import { Collapsible } from '@cbhq/cds-web/collapsible/Collapsible'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { colorToVariable, componentsThemeMap } from '../../componentsThemeMap'
import { Div } from '../../ui/Div'
import { accordionCellRichTextOptions } from '../../ui/RichText'
import { RichText } from '../../ui/RichText/RichText'
import { Text } from '../../ui/Text'
import { formatJSONToRichTextTable } from '../../utils/formatJSONToRichTextTable'

export type AccordionCellProps = {
  header?: string
  body?: Document
  borderBottom: boolean
  json?: {
    data: Record<string, string | number>[]
    headers: Record<string, string>[]
  }
  htmlId?: string
  style?: ComponentsThemeStyle
}

const AccordionCell = ({
  header,
  body,
  borderBottom,
  json,
  htmlId,
  style = 'default',
}: AccordionCellProps) => {
  const [collapsed, { toggle }] = useToggler(true)

  const { foreground, foregroundSecondary, borderLine } =
    componentsThemeMap[style]

  const richTextTableFromJson = json && formatJSONToRichTextTable(json)

  const finalBody =
    body && json
      ? {
          ...body,
          content: [
            ...(body?.content ?? []),
            ...(richTextTableFromJson?.content ?? []),
          ],
        }
      : body || richTextTableFromJson

  const borderStyle = `1px solid ${colorToVariable(borderLine || 'line')}`

  return (
    <Div
      id={htmlId}
      width="100%"
      spacingVertical={4}
      justifyContent="flex-start"
      alignItems="flex-start"
      flexDirection="column"
      css={{
        borderTop: borderStyle,
        borderBottom: borderBottom ? borderStyle : undefined,
      }}
    >
      <Div
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        onClick={toggle}
      >
        <Div flexGrow={1} flexShrink={1} spacingEnd={4}>
          {header && (
            <Text
              as="p"
              color={foreground}
              spacingEnd={{ md: 10 }}
              variant={{ sm: 'title3', md: 'title2' }}
            >
              {header}
            </Text>
          )}
        </Div>
        <IconButton
          name={collapsed ? 'add' : 'minus'}
          variant={style === 'primary' ? 'secondary' : 'foregroundMuted'}
          transparent
        />
      </Div>
      {finalBody && (
        <Div
          width="100%"
          css={{
            '& > div': {
              width: '100%',
            },

            '& table': {
              width: '100%',
            },
          }}
        >
          <Collapsible collapsed={collapsed} maxHeight={1600}>
            <Div flexDirection="column" spacingTop={4} width={'100%'}>
              <RichText
                content={finalBody}
                richTextOptions={accordionCellRichTextOptions}
                color={foregroundSecondary}
              />
            </Div>
          </Collapsible>
        </Div>
      )}
    </Div>
  )
}

export default AccordionCell
