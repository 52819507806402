import Cookies from 'js-cookie'

export function getAllCookies(initialCookies?: Record<string, any>) {
  if (typeof window === 'undefined' && initialCookies) {
    return deserializeCookies(initialCookies)
  }

  return deserializeCookies(Cookies.get() || {})
}
function deserializeCookies(
  cookies: Record<string, string>,
): Record<string, any> {
  const parsedCookies: Record<string, any> = {}

  Object.keys(cookies).forEach((c) => {
    try {
      parsedCookies[c] = JSON.parse(cookies[c])
    } catch (e) {
      // JSON.parse will throw if the cookie value is a string
      parsedCookies[c] = cookies[c]
    }
  })
  return parsedCookies
}
