import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import type { Entry } from 'contentful'

import type { RichTextOption, RichTextPropsType } from '@cbhq/cdx-components'
import {
  CMSContent,
  Text,
  composeRichTextComponents,
  InlineHyperLink,
} from '@cbhq/cdx-components'

import { GLOBAL_NAV_LINK_CONTENT_MODEL_ID } from '../../globals/navlink'
import { isContentEntryOfType } from '../../utils/isContentEntryOfType'

export const cellOptions: RichTextOption = composeRichTextComponents({
  customRenderOptions: {
    [BLOCKS.PARAGRAPH]: (props?: RichTextPropsType) =>
      function Paragraph(node, children) {
        /**
         * Embedded content are rendered inside Paragraph blocks, and we can't render sections/divs inside <p> tags,
         * so if the embedded content is a Content Model and not GlobalNavLink, it should render as div.
         * Embedded elements have props but no children prop, italic and bold elements have only children prop.
         */
        const shouldRenderAsDiv = (children as any[]).some(
          (c) =>
            c.props &&
            !c.props?.children &&
            !isContentEntryOfType(
              GLOBAL_NAV_LINK_CONTENT_MODEL_ID,
              c.props.content,
            ),
        )

        if (!(children as any[]).some((child) => !!child)) return null

        return (
          <Text
            as={shouldRenderAsDiv ? 'div' : 'p'}
            display="block"
            variant="body"
            spacingTop={props?.paragraphSpacingTop}
            color={props?.secondaryColor}
            css={{
              wordBreak: 'normal',
              overflowWrap: 'anywhere',
            }}
          >
            {children}
          </Text>
        )
      },
    [INLINES.HYPERLINK]: (props?: RichTextPropsType) =>
      InlineHyperLink({
        dataQa: 'RichtextTableLink',
      }),
    [INLINES.EMBEDDED_ENTRY]: (props?: RichTextPropsType) =>
      function EmbeddedEntry(node) {
        if (!node?.data?.target) return null

        return <CMSContent content={node?.data?.target as Entry<any>} />
      },
  },
})
