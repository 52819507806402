import { Fragment } from 'react'

import type { Document } from '@contentful/rich-text-types'

import type { SpectrumAlias } from '@cbhq/cds-common'

import { Row } from './Row'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { RichText } from '../../ui/RichText'
import { Text } from '../../ui/Text'

export type HighlightComparisonTableProps = {
  headline: string
  subhead?: Document
  legalDisclaimer?: string
  rowLabels?: RowLabel[]
  comparisonColumns?: ComparisonColumn[]
  tableStyle?: 'alternate' | 'white'
  hasSpacingTop?: boolean
  style?: ComponentsThemeStyle
  anchor?: string
}

export type RowLabel = {
  label?: string
  subLabel?: Document
}

export type ComparisonColumn = {
  heading: string
  subhead?: Document
  highlighted?: boolean
  cells: ComparisonColumnCell[]
  ctaLabel?: string
  ctaUrl?: string
  underline?: Document
}

export type ComparisonColumnCell = {
  heading?: string
  subhead?: Document
  hasCheck?: boolean
  isPositiveCheck?: boolean
}

const customComponentsThemeMap = {
  ...componentsThemeMap,
  dark: {
    ...componentsThemeMap.dark,
    backgroundAlternate: 'gray80' as SpectrumAlias,
  },
}

export const HighlightComparisonTable = ({
  headline,
  subhead,
  legalDisclaimer,
  hasSpacingTop,
  style = 'default',
  tableStyle = 'alternate',
  anchor,

  rowLabels = [],
  comparisonColumns = [],
}: HighlightComparisonTableProps) => {
  const {
    foreground,
    foregroundSecondary,
    background,
    backgroundAlternate,
    primary,
  } = customComponentsThemeMap[style]

  const highlightedHeaderColor = style === 'primary' ? background : 'background'

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightComparisonTable">
        <SectionComponentLayout
          hasSpacingHorizontal
          background={background}
          hasSpacingTop={hasSpacingTop}
          hasNonDefaultBackgroundSpacingVertical
          anchor={anchor}
        >
          <Div flexDirection="column" alignItems="center">
            <Text
              variant={{ sm: 'title2', lg: 'display2' }}
              as="h2"
              color={foreground}
              css={{ textAlign: 'center' }}
            >
              {headline}
            </Text>

            {subhead && (
              <Div flexDirection="column" css={{ textAlign: 'center' }}>
                <RichText content={subhead} color={foregroundSecondary} />
              </Div>
            )}

            <Div
              display={{ sm: 'none', lg: 'grid' }}
              justifyContent="center"
              spacingTop={7}
              gridTemplateColumns={`repeat(${
                comparisonColumns.length + 1
              }, 1fr)`}
            >
              <Div as="span" />

              {comparisonColumns.map((column, index) => {
                return (
                  <Row
                    key={`column-heading-${index}`}
                    isColumnHead
                    headline={column.heading}
                    subhead={column.subhead}
                    foreground={
                      column.highlighted ? highlightedHeaderColor : foreground
                    }
                    foregroundSecondary={
                      column.highlighted
                        ? highlightedHeaderColor
                        : foregroundSecondary
                    }
                    background={column.highlighted ? primary : background}
                  />
                )
              })}

              {rowLabels.map((row, index) => (
                <Fragment key={`label-${index}`}>
                  <Row
                    key={index}
                    headline={row.label}
                    subhead={row.subLabel}
                    alignContent="start"
                    foreground={foreground}
                    foregroundSecondary={foregroundSecondary}
                    background={
                      tableStyle === 'alternate' && index % 2 === 0
                        ? backgroundAlternate
                        : background
                    }
                  />

                  {comparisonColumns.map((column, columnIndex) => {
                    const cell = column.cells[index]

                    if (!cell)
                      return (
                        <Div as="span" key={`data-${index}-${columnIndex}`} />
                      )

                    return (
                      <Row
                        key={`data-${index}-${columnIndex}`}
                        label={cell.heading}
                        subLabel={cell.subhead}
                        hasCheck={cell.hasCheck}
                        isPositiveCheck={cell.isPositiveCheck}
                        bordered={column.highlighted}
                        foreground={foreground}
                        foregroundSecondary={foregroundSecondary}
                        background={
                          tableStyle === 'alternate' && index % 2 === 0
                            ? backgroundAlternate
                            : background
                        }
                        borderColor={primary}
                      />
                    )
                  })}
                </Fragment>
              ))}

              <Div as="span" />

              {comparisonColumns.map((column, index) => {
                return (
                  <Row
                    key={`cta-${index}`}
                    ctaLabel={column.ctaLabel}
                    ctaUrl={column.ctaUrl}
                    ctaVariant={
                      column.highlighted
                        ? style === 'primary'
                          ? 'positive'
                          : 'primary'
                        : 'secondary'
                    }
                    subLabel={column.underline}
                    justifyContent="start"
                    bordered={column.highlighted}
                    foregroundSecondary={foregroundSecondary}
                    borderedEnd={column.highlighted}
                    borderColor={primary}
                  />
                )
              })}
            </Div>

            <Div
              display={{ sm: 'flex', lg: 'none' }}
              flexDirection="column"
              justifyContent="center"
              spacingTop={7}
            >
              {comparisonColumns.map((column, index) => {
                return (
                  <Div
                    flexDirection="column"
                    key={`cta-${index}`}
                    spacingBottom={
                      index !== comparisonColumns.length - 1 ? 7 : 0
                    }
                  >
                    <Row
                      isColumnHead
                      headline={column.heading}
                      subhead={column.subhead}
                      foreground={
                        column.highlighted ? 'background' : foreground
                      }
                      foregroundSecondary={
                        column.highlighted ? 'background' : foregroundSecondary
                      }
                      background={column.highlighted ? primary : background}
                    />

                    {column.cells.map((cell, cellIndex) => (
                      <Row
                        key={`data-${index}-${cellIndex}`}
                        label={cell.heading}
                        headline={rowLabels[cellIndex].label}
                        subhead={rowLabels[cellIndex].subLabel}
                        subLabel={cell.subhead}
                        hasCheck={cell.hasCheck}
                        bordered={column.highlighted}
                        foreground={foreground}
                        foregroundSecondary={foregroundSecondary}
                        background={
                          tableStyle === 'alternate' && cellIndex % 2 === 0
                            ? backgroundAlternate
                            : background
                        }
                        borderColor={primary}
                      />
                    ))}

                    <Row
                      ctaLabel={column.ctaLabel}
                      ctaUrl={column.ctaUrl}
                      ctaVariant={
                        column.highlighted
                          ? style === 'primary'
                            ? 'secondary'
                            : 'primary'
                          : 'secondary'
                      }
                      subLabel={column.underline}
                      justifyContent="start"
                      bordered={column.highlighted}
                      foregroundSecondary={foregroundSecondary}
                      borderedEnd={column.highlighted}
                      borderColor={primary}
                    />
                  </Div>
                )
              })}
            </Div>

            {legalDisclaimer && (
              <Text
                spacingTop={5}
                variant="legal"
                color={foregroundSecondary}
                as="p"
                css={{ whiteSpace: 'break-spaces' }}
              >
                {legalDisclaimer}
              </Text>
            )}
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
