import { ANALYTICS_KEY } from '../../constants'
import type { TextVariant } from '../../ui/Text'
import { Link } from '../Link'
import { ArrowText } from '../LinkArrow/ArrowText'

export type CtaType = 'phone' | 'email' | 'link' | 'card-cta' | 'card'

type LinkArrowProps = {
  ctaUrl: string
  callPhone?: boolean
  children: React.ReactNode
  hideIcon?: boolean
  variant?: TextVariant
  className?: string
  ctaType?: string
}

export const LinkArrow = ({
  ctaUrl,
  hideIcon,
  variant,
  children,
  ctaType = 'link',
  className,
}: LinkArrowProps) => {
  const isPhone = ctaType === 'phone'
  const ctaHref =
    ctaType === 'email' ? ctaUrl : isPhone ? `tel:${ctaUrl}` : ctaUrl
  return (
    <Link
      href={ctaHref}
      data-qa={ANALYTICS_KEY.LinkArrow}
      className={className}
    >
      <ArrowText variant={variant} hideIcon={hideIcon}>
        {children}
      </ArrowText>
    </Link>
  )
}
