import { HighlightTextImageContent } from './HighlightImageTextContent'
import type { HighlightTextImageContentProps } from './HighlightImageTextContent'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { TwoColumnLayout } from '../../layout/TwoColumnLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import type { ImageFit } from '../../ui/Image'
import { Media } from '../../ui/media'

export type HighlightLayout = 'left' | 'right' | 'stacked' | 'vertical'

export type HighlightImageTextProps = HighlightTextImageContentProps & {
  mediaUrl?: string
  mediaAlt?: string
  imageFit?: ImageFit
  layout: HighlightLayout
  hasSpacingTop?: boolean
  style?: ComponentsThemeStyle
  anchor?: string
  mediaType?: 'image' | 'video'
  thumbnailUrl?: string
  thumbnailAlt?: string
  hasLightbox?: boolean
}

const DEFAULT_ALT_TEXT = 'Highlight banner image'

export const HighlightImageText = ({
  layout,
  hasSpacingTop,
  style = 'default',
  mediaType = 'image',
  mediaUrl,
  mediaAlt,
  imageFit,
  anchor,
  thumbnailUrl,
  thumbnailAlt,
  hasLightbox,
  ...contentProps
}: HighlightImageTextProps) => {
  const isCentered = layout === 'stacked' || layout === 'vertical'

  const { background } = componentsThemeMap[style]

  const imageContent = (
    <Div
      spacingHorizontal={!isCentered ? 0 : { sm: 0, md: 6, lg: 11 }}
      width="100%"
    >
      <Media
        mediaType={mediaType}
        src={mediaUrl}
        alt={mediaAlt || DEFAULT_ALT_TEXT}
        maxHeight={
          isCentered
            ? { sm: imageFit === 'contain' ? 'unset' : 375, md: 260, lg: 413 }
            : { sm: imageFit === 'contain' ? 'unset' : 375, md: 'unset' }
        }
        aspectRatio={!isCentered ? { md: 1 } : undefined}
        thumbnailUrl={thumbnailUrl}
        thumbnailAlt={thumbnailAlt}
        hasLightbox={hasLightbox}
        imageFit={imageFit}
      />
    </Div>
  )

  const textContent = (
    <HighlightTextImageContent
      {...contentProps}
      alignContent={
        layout === 'vertical'
          ? 'start'
          : isCentered || !imageContent
          ? 'two-columns'
          : 'start'
      }
      style={style}
    />
  )

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightImageText">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal={false}
          background={background}
          anchor={anchor}
        >
          <TwoColumnLayout
            mainContentPosition={isCentered ? 'top' : layout}
            mainContent={textContent}
            mainContentPercentage={50}
            secondaryContent={imageContent}
            secondaryContentPercentage={50}
          />
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
