import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'

import { composeRichTextComponents } from './composeRichTextComponents'
import type { ComposeRichTextComponentsProps } from './composeRichTextComponents'
import { ANALYTICS_KEY } from '../../../constants'
import { Link } from '../../../ui/Link'
import { Text } from '../../Text'
import { BlockEmbeddedEntry, InlineHyperLink } from '../components'
import type { RichTextOption, RichTextPropsType } from '../types'

export const accordionCellRichTextOptionsConfig: ComposeRichTextComponentsProps =
  {
    customRenderMarks: {
      [MARKS.BOLD]: (text) => (
        <Text variant="headline" as="span">
          {text}
        </Text>
      ),
    },
    customRenderOptions: {
      [INLINES.ASSET_HYPERLINK]: (props?: RichTextPropsType) =>
        InlineHyperLink({
          dataQa: ANALYTICS_KEY.AccordionRichText,
          ...props,
        }),

      [INLINES.HYPERLINK]: (props?: RichTextPropsType) =>
        InlineHyperLink({
          dataQa: ANALYTICS_KEY.AccordionRichText,
          ...props,
        }),

      [BLOCKS.EMBEDDED_ENTRY]: (props?: RichTextPropsType) =>
        BlockEmbeddedEntry({
          additionalProps: {
            hasSpacingHorizontal: false,
            fullViewportWidth: false,
          },
          ...props,
        }),
      [INLINES.ENTRY_HYPERLINK]: () => (node, children) =>
        (
          <Link
            href={node?.data?.target?.fields?.slug}
            data-qa={ANALYTICS_KEY.AccordionRichText}
          >
            {children ?? ''}
          </Link>
        ),
    },
  }

export const accordionCellRichTextOptions: RichTextOption =
  composeRichTextComponents(accordionCellRichTextOptionsConfig)
