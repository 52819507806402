import type { Entry } from 'contentful'

import { getContentEntries } from './client'
import type { EntryProps, ContentfulQueryOptions } from './types'
import type { CDXGlobalTagFields } from '../globals/tag'
import { EDITORIAL_TEMPLATE_CONTENT_MODEL_ID } from '../templates/Editorial/EditorialTemplate'
import { filterArticleFields } from '../utils/filterArticleFields'

export const fetchArticlesWithTag = async (
  productTag: string,
  tags: Entry<CDXGlobalTagFields> | Entry<CDXGlobalTagFields>[] | undefined,
  queryOpts: ContentfulQueryOptions,
  limit?: number,
  skip?: number,
  order: 'asc' | 'desc' = 'asc',
  withfilter = true,
): Promise<EntryProps> => {
  const tagId = Array.isArray(tags)
    ? tags.map((tag) => tag.sys.id).join(',')
    : tags?.sys.id
  const defaultValue = { items: [], total: 0 }

  if (!tagId) return defaultValue

  const articles = (await getContentEntries({
    env: queryOpts.env ?? undefined,
    isPreview: queryOpts.isPreview ?? undefined,
    // This is a workaround to properly fetch blog articles. for some reason LOCALE is not falling back to default (EN) thus returns empty array
    // TODO: check contentful fields
    locale: productTag === 'productsBlog' ? '' : queryOpts.locale ?? undefined,
    queryParams: {
      // When matching linked entry fields, it is necessary to provided linked item contentType
      // https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/search-on-references/search-on-references/console/js
      'fields.content.sys.contentType.sys.id':
        EDITORIAL_TEMPLATE_CONTENT_MODEL_ID,
      'metadata.tags.sys.id[all]': productTag,
      'fields.content.fields.tags.sys.id[in]': tagId,
      'fields.slug[nin]': queryOpts.slug || undefined,
      order:
        order === 'asc' ? '-fields.publicationDate' : 'fields.publicationDate',
      limit,
      skip,
    },
  })) as unknown as EntryProps

  if (!withfilter) return articles || defaultValue

  // When fetching related content, should fetch the following fields, but Contentful does not support select over 2 nested levels
  const articlesWithFilteredFields = filterArticleFields(articles?.items)

  return !articles
    ? defaultValue
    : ({
        items: articlesWithFilteredFields,
        total: articles.total,
      } as EntryProps)
}
