import { BLOCKS, INLINES } from '@contentful/rich-text-types'

import { ANALYTICS_KEY } from '../../constants'
import type { RichTextOption, RichTextPropsType } from '../../ui/RichText'
import { composeRichTextComponents } from '../../ui/RichText'
import { InlineHyperLink } from '../../ui/RichText/components'
import { Text } from '../../ui/Text'

export const labelOptions: RichTextOption = composeRichTextComponents({
  customRenderOptions: {
    [BLOCKS.PARAGRAPH]: (props?: RichTextPropsType) =>
      function Paragraph(node, children) {
        // Contentful Richtext content (children) is an array of strings in reality
        if (!children || !(children as string[])[0]) return null
        return (
          <Text
            as="p"
            variant="label2"
            color={props?.color}
            spacingTop={props?.defaultSpacingTop}
          >
            {children}
          </Text>
        )
      },
    [INLINES.HYPERLINK]: (props?: RichTextPropsType) =>
      InlineHyperLink({
        dataQa: ANALYTICS_KEY.LabelRichText,
        ...props,
      }),
  },
  hasOverrideContentfulParagraphWrapping: true,
})
