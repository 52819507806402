import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { TwoColumnLayout } from '../../layout/TwoColumnLayout'
import type { CTAButtonsProps } from '../../ui/CTAButtons'
import { CTAButtons } from '../../ui/CTAButtons'
import { Div } from '../../ui/Div'
import type { EmailOnlyUserFormProps, FormTypeOptions } from '../../ui/Form'
import { EmailOnlyUserForm } from '../../ui/Form'
import { Text } from '../../ui/Text'
import type { NeverProps } from '../../utils/types'

export type CTAContentType = 'links' | 'form'

type CTAEmailFormContentProps = {
  ctaType?: 'form'
  formType?: FormTypeOptions
} & NeverProps<CTAButtonsProps> &
  EmailOnlyUserFormProps

type CTALinksContentProps = {
  ctaType?: 'links'
} & NeverProps<EmailOnlyUserFormProps> &
  CTAButtonsProps

export type ActionContentProps = {
  headline: string
  body?: string
  ctaType?: 'links' | 'form'
} & (CTAEmailFormContentProps | CTALinksContentProps)

type InnerProps = {
  alignContent: 'two-columns' | 'start'
  style: ComponentsThemeStyle
}

export const ActionBannerContent = ({
  headline,
  body,
  ctaType = 'links',
  ctaButtonLabel,
  ctaButtonUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,
  successMessage,
  submitButtonLabel,
  placeholder,
  onSuccess,
  onSubmit,
  formType,
  alignContent,
  style,
}: ActionContentProps & InnerProps) => {
  const { background, foreground, foregroundSecondary } =
    componentsThemeMap[style]

  const isForm = ctaType === 'form'
  const isTwoColumns = alignContent === 'two-columns'

  const actionContent = isForm ? (
    <EmailOnlyUserForm
      submitButtonLabel={submitButtonLabel}
      successMessage={successMessage}
      placeholder={placeholder}
      onSuccess={onSuccess}
      onSubmit={onSubmit!}
      style={style}
      formType={formType}
    />
  ) : (
    <CTAButtons
      ctaButtonLabel={ctaButtonLabel}
      ctaButtonUrl={ctaButtonUrl}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonUrl={secondaryButtonUrl}
      contentBackground={background}
    />
  )

  return (
    <SectionComponentLayout
      hasSpacingHorizontal
      hasSpacingVertical
      flexDirection="row"
      height="100%"
      background="transparent"
    >
      <Div alignItems="center" flex={1}>
        <TwoColumnLayout
          mainContentPosition={isTwoColumns ? 'left' : 'top'}
          wrapOnTablet
          gap={4}
          mainContent={
            <Div flex={1}>
              <Text
                variant={{ sm: 'title2', lg: 'display2' }}
                as="h2"
                color={foreground}
              >
                {headline}
              </Text>
            </Div>
          }
          secondaryContent={
            <Div flexDirection="column" gap={3} width="100%" flex={1}>
              {body && (
                <Text
                  variant="body"
                  as="p"
                  spacingTop={isTwoColumns ? { sm: 2, lg: 0 } : 2}
                  color={foregroundSecondary}
                >
                  {body}
                </Text>
              )}

              <Div
                justifyContent={
                  isTwoColumns
                    ? { sm: 'center', lg: 'flex-start' }
                    : 'flex-start'
                }
              >
                {actionContent}
              </Div>
            </Div>
          }
        />
      </Div>
    </SectionComponentLayout>
  )
}
