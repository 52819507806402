import { useState, useEffect } from 'react'

import styled from 'styled-components'

import {
  getWistiaThumbnail,
  getYoutubeThumbnail,
  isWistia,
  isYoutube,
} from './linkUtils'
import { ANALYTICS_KEY } from '../../constants'
import { BackgroundContainer } from '../../layout/BackgroundContainer'
import { useLogger } from '../../providers/LoggerProvider'
import type { ImageLoad } from '../../ui/Image'
import { Image } from '../../ui/Image'

type ThumbnailProps = {
  showVideo?: boolean
  thumbnailAlt?: string
  thumbnailUrl?: string
  mediaUrl: string
  loading?: ImageLoad
}

const DEFAULT_THUMBNAIL_ALT = 'Video'

export const Thumbnail = ({
  showVideo,
  thumbnailAlt = DEFAULT_THUMBNAIL_ALT,
  thumbnailUrl: customThumbnail,
  mediaUrl,
  loading,
}: ThumbnailProps) => {
  const { notifyError } = useLogger()
  const [thumbnail, setThumbnail] = useState<string | undefined>(() => {
    if (customThumbnail) return customThumbnail

    if (isYoutube(mediaUrl)) {
      return getYoutubeThumbnail(mediaUrl)
    }
  })

  useEffect(() => {
    if (customThumbnail) {
      setThumbnail(customThumbnail)
      return
    }

    if (isWistia(mediaUrl)) {
      try {
        getWistiaThumbnail(mediaUrl)?.then(
          (nextThumbnail) => nextThumbnail && setThumbnail(nextThumbnail),
        )
      } catch (error) {
        notifyError(
          'Could not extract Youtube video ID from source URL',
          (error as Error).message,
        )
      }

      return
    }

    if (isYoutube(mediaUrl)) {
      const nextThumbnail = getYoutubeThumbnail(mediaUrl)

      setThumbnail(nextThumbnail)
    }
  }, [customThumbnail, mediaUrl, notifyError])

  return (
    <ImageContainer
      $freezeAnimation={showVideo}
      background={!thumbnail ? 'backgroundAlternate' : 'transparent'}
      data-qa={ANALYTICS_KEY.Thumbnail}
    >
      {thumbnail && (
        <Image alt={thumbnailAlt} src={thumbnail} loading={loading} />
      )}
    </ImageContainer>
  )
}

const ImageContainer = styled(BackgroundContainer)<{
  $freezeAnimation?: boolean
}>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  ${({ $freezeAnimation }) =>
    $freezeAnimation ? `transition: transform 200000s` : ''};

  &:hover,
  &:focus {
    ${({ $freezeAnimation }) =>
      $freezeAnimation ? '' : `transition: transform 5s`};

    transform: scale(1.05);
  }
`
