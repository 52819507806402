import { Children, cloneElement, useMemo } from 'react'

import type { Document } from '@contentful/rich-text-types'

import type { PaletteAlias } from '@cbhq/cds-web'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { CTAButtons } from '../../ui/CTAButtons'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { LegalDisclaimer } from '../../ui/LegalDisclaimer'
import { Text } from '../../ui/Text'

const customComponentThemeMap: {
  [key in ComponentsThemeStyle]: {
    background: PaletteAlias
    foreground: PaletteAlias
    foregroundSecondary: PaletteAlias
  }
} = {
  ...componentsThemeMap,
  dark: {
    ...componentsThemeMap.dark,
    background: 'foreground',
    foreground: 'primaryForeground',
    foregroundSecondary: 'primaryForeground',
  },
}

export type HighlightCardIllustrationProps = {
  headline: string
  subhead?: string
  ctaButtonUrl?: string
  ctaButtonLabel?: string
  columns: number | 'split' | 'split-reverse'
  style: ComponentsThemeStyle
  hasSpacingTop?: boolean
  hasSpacingHorizontal?: boolean
  children: React.ReactNode
  tabs?: React.ReactNode
  anchor?: string
  legalDisclaimer?: Document
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>
}

export const HighlightCardIllustration = ({
  headline,
  subhead,
  ctaButtonUrl,
  ctaButtonLabel,
  columns,
  children,
  tabs,
  hasSpacingTop,
  hasSpacingHorizontal = true,
  legalDisclaimer,
  anchor,
  style = 'default',
  as,
}: HighlightCardIllustrationProps) => {
  const flexDirection =
    columns === 'split'
      ? 'row'
      : columns === 'split-reverse'
      ? 'row-reverse'
      : undefined

  const { background, foreground, foregroundSecondary } =
    customComponentThemeMap[style]

  const childrenToRender = useMemo(() => {
    let arrayChildren = Children.toArray(children)

    return arrayChildren.map((child, index) => {
      return (
        <AnalyticsProvider
          key={index}
          analyticsPrefix={`${ANALYTICS_KEY.Child}${index}`}
        >
          {cloneElement(child as any, {
            style,
          })}
        </AnalyticsProvider>
      )
    })
  }, [children, style])

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightCardIllustration">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal={hasSpacingHorizontal}
          background={background}
          anchor={anchor}
          hasNonDefaultBackgroundSpacingVertical
          as={as}
        >
          <Div
            gap={flexDirection ? 5 : 0}
            justifyContent="space-between"
            spacingBottom={style !== 'default' ? 7 : 0}
            flexDirection={{ sm: 'column', md: flexDirection || 'column' }}
          >
            <Div
              flexDirection={flexDirection ? 'column' : 'row'}
              width={flexDirection ? { sm: '100%', md: 420 } : '100%'}
            >
              <Div flexDirection="column" width="100%">
                <Text
                  as="h2"
                  color={foreground}
                  variant={
                    flexDirection
                      ? 'display2'
                      : { sm: 'title2', lg: 'display3' }
                  }
                >
                  {headline}
                </Text>
                {!!subhead && (
                  <Div spacingTop={1.5}>
                    <Text
                      as="p"
                      color={foregroundSecondary}
                      variant={
                        flexDirection
                          ? { sm: 'body', lg: 'title2' }
                          : { sm: 'body', lg: 'title4' }
                      }
                    >
                      {subhead}
                    </Text>
                  </Div>
                )}

                {tabs}
              </Div>

              <Div display={{ sm: 'none', md: 'flex' }}>
                <CTAButtons
                  ctaButtonUrl={ctaButtonUrl}
                  ctaButtonLabel={ctaButtonLabel}
                  contentBackground={background}
                />
              </Div>
            </Div>

            <Div
              flexDirection="column"
              spacingTop={{ sm: 7, md: flexDirection ? 0 : 7 }}
              width={flexDirection ? { sm: '100%', md: 530 } : '100%'}
            >
              <Div
                display="grid"
                gridTemplateColumns={
                  flexDirection
                    ? 'repeat(1, 1fr)'
                    : {
                        sm: 'repeat(1, 1fr)',
                        md: 'repeat(2 ,1fr)',
                        lg:
                          Number(columns) === 4
                            ? 'repeat(2 ,1fr)'
                            : `repeat(${Number(columns)}, 1fr)`,
                        xl: `repeat(${Number(columns)}, 1fr)`,
                      }
                }
                rowGap={{ sm: 3, lg: 6 }}
                columnGap={{ sm: Number(columns) === 4 ? 3 : 6, md: 3 }}
              >
                {childrenToRender}
              </Div>
            </Div>

            {ctaButtonUrl && ctaButtonLabel && (
              <Div
                width="100%"
                flexDirection="column"
                spacingTop={4}
                alignSelf="center"
                display={{ md: 'none' }}
              >
                <CTAButtons
                  ctaButtonUrl={ctaButtonUrl}
                  ctaButtonLabel={ctaButtonLabel}
                  contentBackground={background}
                />
              </Div>
            )}

            {legalDisclaimer && (
              <LegalDisclaimer
                spacingTop={{ sm: 3, md: 4, lg: 5 }}
                legalDisclaimer={legalDisclaimer}
                color={foregroundSecondary}
              />
            )}
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
