import { useState, useCallback, useMemo, useEffect, Fragment } from 'react'

import styled from 'styled-components'

import { Lightbox } from './Lightbox'
import {
  isWistia,
  isYoutube,
  isVimeo,
  standardizeYoutubeUrl,
} from './linkUtils'
import { PlayerFrame } from './PlayerFrame'
import { Thumbnail } from './Thumbnail'
import PlayButtonIcon from './videoPlay.svg'
import { media } from '../../breakpoints'
import { useLogger } from '../../providers/LoggerProvider'
import { Div } from '../Div'
import { Image } from '../Image'
import { Text } from '../Text'

type LazyFacadeProps = {
  mediaUrl: string
  thumbnailUrl?: string
  thumbnailAlt?: string
  autoplay?: boolean
  hasLightbox?: boolean
}

export const LazyFacade = ({
  mediaUrl,
  thumbnailUrl,
  thumbnailAlt = '',
  autoplay,
  hasLightbox,
}: LazyFacadeProps) => {
  const [showVideo, setShowVideo] = useState(false)
  const { notifyError } = useLogger()

  const onPlay = useCallback(() => {
    setShowVideo(true)
  }, [])

  const onClose = useCallback(() => {
    setShowVideo(false)
  }, [])

  useEffect(() => {
    if (autoplay && !hasLightbox) {
      setShowVideo(true)
    }
  }, [autoplay, hasLightbox])

  const sanitizedUrl = useMemo((): string | undefined => {
    if (isYoutube(mediaUrl)) return standardizeYoutubeUrl(mediaUrl)

    if (isWistia(mediaUrl) || isVimeo(mediaUrl)) return mediaUrl

    return
  }, [mediaUrl])

  if (!sanitizedUrl) {
    const error = 'Invalid video URL, current only supporting: Youtube, Wistia.'

    notifyError(error)

    throw new Error(error)
  }

  const Container = hasLightbox ? Lightbox : Fragment
  const lightBoxProps = hasLightbox && { close: onClose }

  return (
    <Facade onClick={onPlay}>
      {showVideo && (
        <Container {...lightBoxProps}>
          <PlayerFrame mediaUrl={sanitizedUrl} autoplay={autoplay} />
        </Container>
      )}

      <Thumbnail
        showVideo={showVideo}
        thumbnailAlt={thumbnailAlt}
        thumbnailUrl={thumbnailUrl}
        mediaUrl={sanitizedUrl}
        loading="eager"
      />

      {!showVideo && <FacadePlayOverlay />}
    </Facade>
  )
}

const OVERLAY_CLASS = 'overlay'

const Facade = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  &:hover,
  &:focus {
    cursor: pointer;

    .${OVERLAY_CLASS} {
      filter: brightness(0.9);
      transition: filter 0.2s cubic-bezier(0, 0, 0.2, 1);
    }
  }
`

const FacadePlayOverlay = () => (
  <OverlayContainer className={OVERLAY_CLASS}>
    <OverlayInnerContainer
      position="absolute"
      top="50%"
      width="fit-content"
      alignItems="center"
      right={{ sm: '50%', md: '10%' }}
    >
      <Div height={{ sm: 64, lg: 104 }} width={{ sm: 64, lg: 104 }}>
        <Image alt="Play video" src={PlayButtonIcon as any} />
      </Div>

      <Text
        display={{ sm: 'none', md: 'flex' }}
        variant={{ sm: 'display2', lg: 'display1' }}
        spacingStart={{ sm: 1, lg: 3 }}
        as="p"
        color="gray0"
      >
        Play
      </Text>
    </OverlayInnerContainer>
  </OverlayContainer>
)

const OverlayInnerContainer = styled(Div)`
  transform: translate(50%, -50%);

  ${media.md} {
    transform: translate(0%, -50%);
  }
`

const OverlayContainer = styled.div`
  pointer-events: none;
  user-select: none;
  height: 100%;
`
