import type { Entry } from 'contentful'

import { HighlightAccordion as AccordionComponent } from '@cbhq/cdx-components'
import type {
  AccordionCellProps,
  ComponentsThemeStyle,
} from '@cbhq/cdx-components'

import { mapEntryLink } from '../../globals/navlink'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'

export type HighlightAccordionFields = {
  title?: string
  subtitle?: string
  link?: Entry<CDXGlobalNavLinkFields>
  cells?: Entry<AccordionCellProps>[]
  anchor?: string
  style?: ComponentsThemeStyle
  hasSpacingTop?: boolean
}

export const HIGHLIGHT_ACCORDION_CONTENT_MODEL_ID = 'cdxHighlightAccordion'

export const HighlightAccordion = ({
  title,
  subtitle,
  link,
  cells,
  anchor,
  style = 'default',
  hasSpacingTop,
}: HighlightAccordionFields) => {
  const navLink = link && mapEntryLink(link)

  return (
    <AccordionComponent
      title={title}
      subtitle={subtitle}
      ctaLabel={navLink?.label}
      ctaUrl={navLink?.url}
      cells={cells?.map((cell) => cell.fields)}
      anchor={anchor}
      style={style}
      hasSpacingTop={hasSpacingTop}
    />
  )
}
