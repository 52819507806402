// https://www.joshwcomeau.com/react/prefers-reduced-motion/

import { useState, useEffect } from 'react'

const REDUCED_MOTION_QUERY = '(prefers-reduced-motion: no-preference)'

export const usePrefersReducedMotion = () => {
  // Default to no-animations, since we don't know what the
  // user's preference is on the server.
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(true)

  useEffect(() => {
    const mediaQueryList = window.matchMedia(REDUCED_MOTION_QUERY)
    // Set the true initial value, now that we're on the client:
    setPrefersReducedMotion(!window.matchMedia(REDUCED_MOTION_QUERY).matches)
    // Register our event listener
    const listener = (event: MediaQueryListEvent) =>
      setPrefersReducedMotion(!event.matches)

    mediaQueryList.addEventListener('change', listener)

    return () => {
      mediaQueryList.removeEventListener('change', listener)
    }
  }, [])

  return prefersReducedMotion
}
