import type { PaletteAlias } from '@cbhq/cds-common'
import { Avatar } from '@cbhq/cds-web/media/Avatar'

import { Div } from '../../ui/Div'
import { ArrowText } from '../../ui/LinkArrow'
import { Text } from '../../ui/Text'

export type HighlightBlockQuoteAuthorProps = {
  photoUrl?: string
  name: string
  title?: string
  bio?: string
  color?: PaletteAlias
  secondaryColor?: PaletteAlias
  linkLabel?: string
}

export const HighlightBlockQuoteAuthor = ({
  photoUrl,
  name,
  title,
  bio,
  color,
  secondaryColor,
  linkLabel,
}: HighlightBlockQuoteAuthorProps) => (
  <Div flexDirection="column">
    <Div gap={2}>
      <Avatar alt={name} src={photoUrl} size="xxl" />

      <Div flexDirection="column" justifyContent="center">
        <Text variant="label1" as="p" color={color || 'foreground'}>
          {name}
        </Text>
        {title && (
          <Text variant="label2" as="p" color={secondaryColor}>
            {title}
          </Text>
        )}
      </Div>
    </Div>

    {bio && (
      <Text variant="label2" color={secondaryColor} spacingTop={3} as="p">
        {bio}
      </Text>
    )}
    {linkLabel && <ArrowText>{linkLabel}</ArrowText>}
  </Div>
)
