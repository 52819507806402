import type { Document } from '@contentful/rich-text-types'
import type { Asset, Entry } from 'contentful'

import type { EmailApiProductId } from '@cbhq/cdx-api'
import type { ColumnDefinition } from '@cbhq/cdx-components'
import {
  HighlightTable as HighlightTableComponent,
  Image,
  ModalProvider,
} from '@cbhq/cdx-components'

import { useContentfulMicrocopy } from '../../globals/microcopy'
import type { CDXSettingsNavLinkFields } from '../../globals/navlink'

type CMSOptions = {
  productId: EmailApiProductId
}

type ModalFields = {
  productId?: EmailApiProductId
  headerTitle: string
  description: string
  legalDisclaimer?: Document
  media?: Asset
}

type HighlightAssetFields = {
  name: string
  symbol: string
  reward: string
  marketCap: string
  type: 'Staking' | 'DeFi Yield' | 'Standard'
  logo?: Asset
  dateAdded: string
  showAsNew?: boolean
  commissionFee?: string
  link?: Entry<CDXSettingsNavLinkFields>
}

export type HighlightAssetTableFields = {
  headline: string
  subhead?: Document
  legalDisclaimer?: string
  assets?: Entry<HighlightAssetFields>[]
  anchor?: string
  nameLabel?: string
  rewardLabel?: string
  typeLabel?: string
  hasSpacingTop?: boolean
  options: CMSOptions
  modal?: Entry<ModalFields>
}

export const HIGHLIGHT_ASSET_TABLE_CONTENT_MODEL_ID = 'cdxHighlightAssetTable'

export const HighlightAssetTable = ({
  headline,
  subhead,
  legalDisclaimer,
  assets,
  nameLabel,
  rewardLabel,
  anchor,
  hasSpacingTop = true,
}: HighlightAssetTableFields) => {
  const microcopies = useContentfulMicrocopy()

  const data =
    assets?.map((entry) => {
      const { logo, dateAdded } = entry.fields

      let typeDisplay = microcopies.global?.standard

      if (entry.fields.type === 'Staking') {
        typeDisplay = microcopies.global?.staking
      }
      if (entry.fields.type === 'DeFi Yield') {
        typeDisplay = microcopies.global?.defiYield
      }

      return {
        ...entry.fields,
        logo:
          logo && logo.fields?.file?.url ? (
            <Image
              alt={logo.fields.title}
              src={logo.fields.file.url}
              width={24}
              height={24}
            />
          ) : (
            ''
          ),
        dateAdded,
        typeDisplay,
      }
    }) ?? []

  const columns: ColumnDefinition<(typeof data)[0]>[] = [
    {
      label: '',
      size: 'auto',
      dataKey: 'logo',
    },
    {
      label: nameLabel || microcopies.global?.asset || 'Protocol',
      size: '1fr',
      dataKey: 'name',
      subDataKey: 'symbol',
    },
    {
      label: rewardLabel || 'Reward rate',
      size: '20%',
      dataKey: 'reward',
      color: 'positive',
    },
    {
      label: microcopies.global?.commissionFee || 'Commission fee**',
      size: '20%',
      dataKey: 'commissionFee',
      subDataKey: 'typeDisplay',
    },
  ]

  return (
    <ModalProvider>
      <HighlightTableComponent
        headline={headline}
        subhead={subhead}
        legalDisclaimer={legalDisclaimer}
        data={data}
        columns={columns}
        hasSpacingTop={hasSpacingTop}
        anchor={anchor}
      />
    </ModalProvider>
  )
}
