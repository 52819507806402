import type { Asset } from 'contentful'

import { HighlightVideo as HighlightVideoComponent } from '@cbhq/cdx-components'
import type { ComponentsThemeStyle } from '@cbhq/cdx-components'

export type HighlightVideoFields = {
  mediaUrl: string
  thumbnail?: Asset
  caption?: string
  anchor?: string
  style?: ComponentsThemeStyle
  hasLightbox?: boolean
  hasSpacingTop?: boolean
}

export const HIGHLIGHT_VIDEO_CONTENT_MODEL_ID = 'cdxHighlightVideo'

export const HighlightVideo = ({
  mediaUrl,
  thumbnail,
  caption,
  anchor,
  style,
  hasLightbox,
  hasSpacingTop,
}: HighlightVideoFields) => {
  return (
    <HighlightVideoComponent
      mediaUrl={mediaUrl}
      thumbnailUrl={thumbnail?.fields.file?.url}
      thumbnailAlt={thumbnail?.fields.title}
      caption={caption}
      anchor={anchor}
      style={style}
      hasLightbox={hasLightbox}
      hasSpacingTop={hasSpacingTop}
    />
  )
}
