import { componentsThemeMap } from '../../componentsThemeMap'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { Div } from '../../ui/Div'
import { Text } from '../../ui/Text'

export type ChipProps = {
  metric?: string
  caption?: string
  style?: ComponentsThemeStyle
}

export const StatChip = ({ metric, caption, style = 'default' }: ChipProps) => {
  const { foreground, foregroundSecondary, borderLine } =
    componentsThemeMap[style]

  return (
    <Div
      width="100%"
      maxWidth={327}
      spacingHorizontal={3}
      flexDirection="column"
      alignItems="flex-start"
      borderLeft="1px solid"
      borderColor={borderLine}
      justifyContent="flex-start"
    >
      {metric && (
        <Text
          as="span"
          height={118}
          color={foreground}
          variant="display1"
          alignItems="center"
        >
          {metric}
        </Text>
      )}
      {caption && (
        <Text
          as="label"
          spacingTop={0.5}
          variant="caption"
          color={foregroundSecondary}
        >
          {caption}
        </Text>
      )}
    </Div>
  )
}
