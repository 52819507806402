import styled from 'styled-components'

import type { PaletteAlias } from '@cbhq/cds-common'

import { useCarouselContext } from './useCarouselContext'
import { colorToVariable } from '../../componentsThemeMap'
import { ScreenReaderOnly } from '../ScreenReaderOnly'

type CarouselIndicatorsProps = {
  className?: string
  color?: PaletteAlias
  colorInactive?: PaletteAlias
}

export const CarouselIndicators = ({
  color = 'primary',
  colorInactive = 'line',
  ...props
}: CarouselIndicatorsProps) => {
  const { activeSlideIndex, numberOfSlides, paginate } = useCarouselContext()
  return (
    <Container {...props}>
      {new Array(numberOfSlides).fill(1).map((_, index) => (
        <CarouselIndicatorButton
          key={index}
          style={{
            background:
              index === activeSlideIndex
                ? colorToVariable(color)
                : colorToVariable(colorInactive),
          }}
          onClick={() => paginate(index - (activeSlideIndex % numberOfSlides))}
        >
          <ScreenReaderOnly>
            Go to slide {index + 1}{' '}
            {index === activeSlideIndex ? '(current slide)' : ''}
          </ScreenReaderOnly>
        </CarouselIndicatorButton>
      ))}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;

  bottom: 64px;
  left: 0;
  right: 0;

  width: 100%;
  margin: 0 auto;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`

const CarouselIndicatorButton = styled.button`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
`
