import { HighlightAsset } from './HighlightAsset'
import { componentsThemeMap } from '../../componentsThemeMap'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'

export type HighlightAssetProps = {
  name?: string
  logoSrc?: string
  link?: string
}

export type HighlightAssetListProps = {
  assets: HighlightAssetProps[]
  title?: string
  hasSpacingTop?: boolean
  anchor?: string
  style?: ComponentsThemeStyle
}

export const HighlightAssetList = ({
  assets,
  title,
  hasSpacingTop = true,
  anchor,
  style = 'default',
}: HighlightAssetListProps) => {
  const { background, foreground } = componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightAssetList">
        <SectionComponentLayout
          hasSpacingHorizontal
          hasSpacingTop={hasSpacingTop}
          anchor={anchor}
          background={background}
        >
          {title && (
            <Text
              variant="title2"
              color={foreground}
              as="h2"
              spacingEnd={{ lg: 2 }}
            >
              {title}
            </Text>
          )}

          <Div spacingVertical={5} flexWrap="wrap">
            {assets?.map(({ logoSrc, link, name }: HighlightAssetProps, i) => (
              <AnalyticsProvider
                key={`${name}-${i}`}
                analyticsPrefix={`${ANALYTICS_KEY.Asset}${i}`}
              >
                <Div spacingEnd={2} spacingTop={2} width="inherit">
                  <HighlightAsset
                    logoSrc={logoSrc}
                    link={link}
                    name={name}
                    style={style}
                  />
                </Div>
              </AnalyticsProvider>
            ))}
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
