import type { Document } from '@contentful/rich-text-types'
import type { Entry } from 'contentful'

import type { IconName } from '@cbhq/cds-common'
import {
  HighlightComparisonCard as HighlightComponent,
  ComparisonCard,
} from '@cbhq/cdx-components'
import type { ComponentsThemeStyle } from '@cbhq/cdx-components'

import { mapEntryLink } from '../../globals/navlink'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'

export const HIGHLIGHT_COMPARISON_CARD_CONTENT_MODEL_ID =
  'cdxHighlightComparisonCard'

export type ComparisonCardProps = {
  title?: string
  subtitle?: string
  description?: string
  listTitle?: string
  list?: Document
  link?: Entry<CDXGlobalNavLinkFields>
  listStyle?: IconName
}
export type HighlightComparisonCardFields = {
  headline?: string
  subhead?: string
  ctaButtonLabel?: string
  ctaButtonUrl?: string
  style?: ComponentsThemeStyle
  hasSpacingTop?: boolean
  cards?: Entry<ComparisonCardProps>[]
  anchor?: string
}

export const HighlightComparisonCard = ({
  headline,
  subhead,
  ctaButtonLabel,
  ctaButtonUrl,
  style = 'default',
  hasSpacingTop,
  cards = [],
  anchor,
}: HighlightComparisonCardFields) => {
  return (
    <HighlightComponent
      headline={headline}
      subhead={subhead}
      ctaButtonLabel={ctaButtonLabel}
      hasSpacingTop={hasSpacingTop}
      ctaButtonUrl={ctaButtonUrl}
      style={style}
      anchor={anchor}
      cardCount={cards.length}
    >
      {cards.map((card, index) => {
        const navLink = card?.fields?.link && mapEntryLink(card?.fields?.link)

        return (
          <ComparisonCard
            key={index}
            title={card?.fields.title}
            subtitle={card?.fields.subtitle}
            description={card?.fields.description}
            ctaUrl={navLink?.url}
            ctaLabel={navLink?.label}
            listTitle={card?.fields?.listTitle}
            list={card?.fields?.list}
            listStyle={card?.fields?.listStyle}
          />
        )
      })}
    </HighlightComponent>
  )
}
