import { useRef, useEffect, useCallback } from 'react'

import styled from 'styled-components'

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    Vimeo: any
  }
}

type PlayerFrameWithVimeoApiProps = {
  src: string
}

export function PlayerFrameWithVimeoApi({ src }: PlayerFrameWithVimeoApiProps) {
  const ref = useRef<HTMLDivElement>(null)

  const loadAndPlayVideo = useCallback(() => {
    const player = new window.Vimeo.Player(ref.current, {
      url: src,
    })
    player.play()
  }, [src])

  useEffect(() => {
    if (!window.Vimeo) {
      const tag = document.createElement('script')
      tag.src = 'https://player.vimeo.com/api/player.js'

      // will load the video after the script is loaded
      tag.onload = loadAndPlayVideo

      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag)
    } else {
      loadAndPlayVideo()
    }
  }, [loadAndPlayVideo])

  return (
    <Container>
      <div ref={ref} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;

  iframe {
    position: absolute;
    inset: 0px;
    width: 100%;
    height: 100%;
    border: none;
  }
`
