import type { Entry } from 'contentful'

import { getEntryTypeId } from './getEntryTypeId'

export const isContentEntryOfType = <T>(
  typeId: string,
  entry: Entry<T>,
): boolean => {
  const entryTypeId = getEntryTypeId(entry)

  return Boolean(entryTypeId) && entryTypeId === typeId
}
