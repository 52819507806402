import type { Entry } from 'contentful'

import type { CDXGlobalNavLinkFields } from '../globals/navlink'

/**
 * Filters Global Tag's Link field and sorts out unnecessary data
 */

export const filterLinkFields = (navLink: Entry<CDXGlobalNavLinkFields>) => {
  // when sometime compose page are not defined
  // and navLink only returns {internalName: 'Our Clients',label: 'Our Clients',url: '/clients'}
  if (!navLink?.fields?.composePage) return navLink

  return {
    fields: {
      ...navLink.fields,
      composePage: navLink.fields.composePage
        ? {
            fields: {
              slug: navLink.fields.composePage.fields?.slug
                ? navLink.fields.composePage.fields.slug
                : null,
            },
          }
        : null,
    },
  }
}
