import { Fallback } from '@cbhq/cds-web/layout/Fallback'
import { PageTemplateLayout } from '@cbhq/cdx-contentful'
import { Div } from '@cbhq/cdx-components'

export const FallbackLoader = () => {
  return (
    <PageTemplateLayout path="">
      <Div
        flexWrap={{
          sm: 'wrap-reverse',
          md: 'nowrap',
        }}
        justifyContent="space-evenly"
        width="100%"
        height="fit-content"
        spacingTop={10}
        flexDirection={{ sm: 'column', lg: 'row' }}
      >
        <Div
          flexShrink={1}
          flexGrow={1}
          flexBasis={{ sm: '100%', md: '70%' }}
          flexDirection="column"
        >
          <Div width="100%" flexDirection="column">
            <Div width="100%" flexDirection="column">
              <Fallback as="div" width={'70%'} height={50} />
              <Fallback as="div" width={'95%'} height={50} />
            </Div>
            <Div flexDirection="column">
              <Fallback as="div" width={'95%'} height={50} />
              <Fallback as="div" width={'95%'} height={50} />
              <Fallback as="div" width={'80%'} height={50} />
              <Fallback as="div" width={'95%'} height={50} />
            </Div>
            <Div display={{ sm: 'none', lg: 'flex' }}>
              <Fallback as="div" width={'95%'} height={400} />
            </Div>
          </Div>
        </Div>

        <Div
          flexShrink={1}
          flexGrow={1}
          flexBasis={{ sm: '100%', md: '30%' }}
          flexDirection="column"
        >
          <Div gap={1} spacingBottom={5} spacingTop={{ sm: 5, lg: 0 }}>
            <Fallback width={60} height={60} shape="circle" />
            <Fallback width={60} height={60} shape="circle" />
            <Fallback width={60} height={60} shape="circle" />
            <Fallback width={60} height={60} shape="circle" />
          </Div>
          {<Fallback width={'100%'} height={200} />}
          {}
        </Div>
      </Div>
    </PageTemplateLayout>
  )
}
