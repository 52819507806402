import type { Entry } from 'contentful'

import { filterLinkFields } from './filterLinkFields'
import { HERO_FEATURED_EDITORIAL_CONTENT_MODEL_ID } from '../components/HeroFeaturedEditorial/index'
import { WAYFINDING_CARD_ARTICLE_CONTENT_MODEL_ID } from '../components/WayfindingCardArticle'
import type { ComposePageEntry } from '../globals/composePage'
import type { CDXGlobalNavLinkFields } from '../globals/navlink'
import type { LandingTemplateFields } from '../templates/Landing'
import { getEntryTypeId } from '../utils/getEntryTypeId'

/**
 * Filters Homepage's Featured Hero and Wayfinding sections' fields and sorts out unnecessary data
 */

export const filterHomeSectionFields = (
  composePage: ComposePageEntry<LandingTemplateFields>,
) => ({
  ...composePage,
  fields: {
    ...composePage.fields,
    content: {
      ...composePage.fields.content,
      fields: {
        ...composePage.fields.content.fields,
        content: composePage?.fields?.content?.fields?.content?.map((entry) => {
          return getEntryTypeId(entry) ===
            HERO_FEATURED_EDITORIAL_CONTENT_MODEL_ID
            ? {
                sys: entry.sys,
                fields: {
                  ...entry.fields,
                  featuredEditorialPage: {
                    fields: {
                      slug: entry.fields.featuredEditorialPage.fields.slug,
                      publicationDate:
                        entry.fields.featuredEditorialPage.fields
                          .publicationDate,
                      content: {
                        fields: {
                          headline:
                            entry.fields.featuredEditorialPage.fields.content
                              .fields.headline || null,
                          readTime:
                            entry.fields.featuredEditorialPage.fields.content
                              .fields.readTime || null,
                          image:
                            entry.fields.featuredEditorialPage.fields.content
                              .fields.image || null,
                          tags: (
                            entry.fields.featuredEditorialPage.fields.content
                              .fields.tags || []
                          ).map((tag: any) => ({
                            fields: {
                              id: tag.fields.id,
                              label: tag.fields.label,
                              link: tag.fields.link
                                ? filterLinkFields(tag.fields.link)
                                : null,
                            },
                          })),
                        },
                      },
                    },
                  },
                },
              }
            : getEntryTypeId(entry) !== WAYFINDING_CARD_ARTICLE_CONTENT_MODEL_ID
            ? entry
            : {
                sys: entry.sys,
                fields: {
                  ...entry.fields,
                  link: filterLinkFields(entry.fields.link) ?? null,
                  tags: (entry.fields.tags || []).map(
                    (tag: Entry<CDXGlobalNavLinkFields>) => ({
                      fields: {
                        ...tag.fields,
                        link: filterLinkFields(entry.fields.link) ?? null,
                      },
                    }),
                  ),
                },
              }
        }),
      },
    },
  },
})
