import type {
  ComposePageEntry,
  EditorialTemplateExtraProps,
  EditorialTemplateFields,
} from '@cbhq/cdx-contentful'
import {
  getComposeEntryProps,
  fetchSiteConfig,
  filterRelatedStoryFields,
  getEntryProps,
  handleAutomaticWayfinding,
} from '@cbhq/cdx-contentful'

import { getAppConfig } from '../../config'
import type { DataHandlerReturn, StaticQueryOpts } from '../../types'

export const handleStaticPropsEditorial = async (
  composePage: ComposePageEntry<EditorialTemplateFields>,
  queryOpts: StaticQueryOpts,
): DataHandlerReturn<EditorialTemplateFields, EditorialTemplateExtraProps> => {
  const { contentfulConfig } = getAppConfig()

  const { projectSettings, publicationDate, excerpt, headline } =
    getComposeEntryProps(composePage)

  const {
    defaultSeoDescription,
    seoFallbackImage,
    defaultEditorialWayfinding,
  } = getEntryProps(projectSettings)

  const { content: template } = getEntryProps(composePage)
  const wayfindingCardArticles =
    template?.fields?.editorialWayfinding || defaultEditorialWayfinding

  if (wayfindingCardArticles) {
    await handleAutomaticWayfinding(
      contentfulConfig.appTagId,
      wayfindingCardArticles,
      {
        ...queryOpts,
        slug: queryOpts.slug,
      },
    )
  }

  const siteConfig = await fetchSiteConfig(queryOpts)
  const canonicalUrl = `${process.env.HOSTNAME}${queryOpts.slug}`

  const composePageWithRelatedStories = !template?.fields.relatedStories
    ? null
    : (filterRelatedStoryFields(
        composePage,
      ) as ComposePageEntry<EditorialTemplateFields>)

  return {
    page: composePageWithRelatedStories ?? composePage,
    seoDefaultProps: {
      title: headline || null,
      description: excerpt || defaultSeoDescription || null,
      imageUrl:
        composePage.fields.content.fields.image?.fields.file.url ||
        seoFallbackImage?.fields.file.url ||
        null,
    },
    extraProps: {
      publicationDate: publicationDate || null,
      siteConfig,
      canonicalUrl,
    },
  }
}
