import {
  useState,
  Children,
  isValidElement,
  cloneElement,
  useEffect,
  useCallback,
  useRef,
} from 'react'

import type { AnimationProps, DraggableProps } from 'framer-motion'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'

import { CarouselButtons } from './CarouselButtons'
import { CarouselContext } from './CarouselContext'
import { CarouselIndicators } from './CarouselIndicators'
import { usePrefersReducedMotion } from '../../hooks/usePrefersReducedMotion'
import { ScreenReaderOnly } from '../ScreenReaderOnly'

export type CarouselProps = {
  children?: React.ReactElement | React.ReactElement[]
  className?: string
  variants?: AnimationProps['variants']
  initial?: AnimationProps['initial']
  animate?: AnimationProps['animate']
  exit?: AnimationProps['exit']
  autoplay?: boolean
  autoplayLoop?: boolean
  autoplayOffset?: number
  autoplayInterval?: number
  stopAutoplayOnInteraction?: boolean
  transition?: AnimationProps['transition']
  drag?: DraggableProps['drag']
  dragConstraints?: DraggableProps['dragConstraints']
  dragElastic?: DraggableProps['dragElastic']
  swipeConfidenceThreshold?: number
  hideButtonsOnEdges?: boolean
  carouselButtons?: JSX.Element
  carouselIndicators?: JSX.Element
  /** To use, provide a height to the Component through styled or any other means, this will make the position absolute */
  isFixedHeight?: boolean
}

const CAROUSEL_DEFAULT_TRANSITION = {
  x: { type: 'spring', stiffness: 300, damping: 30 },
  opacity: { duration: 0.2 },
}

const CAROUSEL_DEFAULT_VARIANTS = {
  enter: (offset: number) => ({
    x: offset > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (offset: number) => ({
    zIndex: 0,
    x: offset < 0 ? 1000 : -1000,
    opacity: 0,
    position: 'absolute' as any,
  }),
}

const CAROUSEL_REDUCED_MOTION_VARIANTS = {
  center: { zIndex: 1 },
  exit: { zIndex: 0, position: 'absolute' as any },
}

export const wrap = (min: number, max: number, v: number) => {
  const rangeSize = max - min
  return ((((v - min) % rangeSize) + rangeSize) % rangeSize) + min
}

export const Carousel = ({
  children,
  className,
  variants = CAROUSEL_DEFAULT_VARIANTS,
  initial = 'enter',
  animate = 'center',
  exit = 'exit',
  autoplay,
  autoplayLoop,
  autoplayInterval = 3000,
  autoplayOffset = 1,
  stopAutoplayOnInteraction,
  transition = CAROUSEL_DEFAULT_TRANSITION,
  drag = 'x',
  dragConstraints = { left: 0, right: 0 },
  dragElastic = 1,
  swipeConfidenceThreshold = 8000,
  hideButtonsOnEdges,
  carouselButtons = <CarouselButtons />,
  carouselIndicators = <CarouselIndicators />,
  isFixedHeight = false,
  ...props
}: CarouselProps) => {
  const hasInteracted = useRef(false)
  const autoplayTimeoutId = useRef<number>()
  const [[activeSlideOffset, offset], setSlide] = useState([0, 0])
  const noAnimation = usePrefersReducedMotion()

  if (noAnimation) {
    autoplay = false
    if (variants === CAROUSEL_DEFAULT_VARIANTS) {
      variants = CAROUSEL_REDUCED_MOTION_VARIANTS
    }
  }

  const paginate = useCallback(
    (offset: number, ignoreInteraction?: boolean) => {
      clearTimeout(autoplayTimeoutId.current)
      if (!ignoreInteraction) hasInteracted.current = true
      setSlide(([slideOffset]) => [slideOffset + offset, offset])
    },
    [],
  )

  const slides = Children.toArray(children).map((child, slideIndex) => {
    if (!isValidElement(child)) return child
    return cloneElement(child, { slideIndex } as any)
  })

  // We only have N slides, but we paginate them absolutely (ie 1, 2, 3, 4, 5...) and
  // then wrap that within 0-N to find our slide in the array below. By passing an
  // absolute activeSlideIndex as the `motion` component's `key` prop, `AnimatePresence` will
  // detect it as an entirely new element. So you can infinitely paginate as few as 1 elements.
  const activeSlideIndex = wrap(0, slides.length, activeSlideOffset)

  useEffect(() => {
    if (
      !autoplay ||
      (!autoplayLoop && activeSlideIndex === slides.length - 1) ||
      (stopAutoplayOnInteraction && hasInteracted.current)
    )
      return

    autoplayTimeoutId.current = window.setTimeout(
      () => paginate(autoplayOffset, true),
      autoplayInterval,
    )
    return () => clearTimeout(autoplayTimeoutId.current)
  }, [
    activeSlideIndex,
    slides.length,
    paginate,
    noAnimation,
    autoplay,
    autoplayLoop,
    autoplayInterval,
    autoplayOffset,
    stopAutoplayOnInteraction,
  ])

  return (
    <Container
      className={className}
      $hasDefaultHeight={!isFixedHeight}
      {...props}
    >
      <Wrapper>
        <CarouselContext.Provider
          value={{
            activeSlideIndex,
            numberOfSlides: slides.length,
            hideButtonsOnEdges,
            paginate,
          }}
        >
          <AnimatePresence initial={false} custom={offset}>
            <motion.div
              key={activeSlideOffset}
              style={{
                position: isFixedHeight ? 'absolute' : 'inherit',
                width: '100%',
                height: '100%',
              }}
              custom={offset}
              variants={variants}
              initial={initial}
              animate={animate}
              exit={exit}
              transition={transition}
              drag={drag}
              dragConstraints={dragConstraints}
              dragElastic={dragElastic}
              onDragEnd={(event, { offset, velocity }) => {
                const swipePower = Math.abs(offset.x) * velocity.x
                if (swipePower < -swipeConfidenceThreshold) paginate(1)
                else if (swipePower > swipeConfidenceThreshold) paginate(-1)
              }}
            >
              {slides[activeSlideIndex]}
            </motion.div>

            <ScreenReaderOnly aria-live="polite" aria-atomic="true">
              Showing slide {activeSlideIndex + 1} of {slides.length}
            </ScreenReaderOnly>
          </AnimatePresence>

          {carouselButtons}
          {carouselIndicators}
        </CarouselContext.Provider>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div<{ $hasDefaultHeight?: boolean }>`
  width: 100%;
  height: ${({ $hasDefaultHeight }) =>
    $hasDefaultHeight ? '700px' : 'inherit'};

  overflow-x: ${({ $hasDefaultHeight: $hasDefaultHeight }) =>
    $hasDefaultHeight ? 'hidden' : 'visible'};

  user-select: none;
  cursor: grab;

  :active {
    cursor: grabbing;
  }
`

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`
