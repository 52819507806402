import styled from 'styled-components'

import { LazyFacade } from './LazyFacade'

export type VideoPlayerProps = {
  src: string
  loading?: 'lazy' | 'eager' | 'auto'
  thumbnailUrl?: string
  thumbnailAlt?: string
  autoplay?: boolean
  hasLightbox?: boolean
}

const RATIO_16_9 = 1920 / 1080

export const VideoPlayer = ({
  src,
  thumbnailUrl,
  thumbnailAlt,
  autoplay,
  hasLightbox,
}: VideoPlayerProps) => {
  return (
    <Container>
      <Content>
        <LazyFacade
          mediaUrl={src}
          thumbnailUrl={thumbnailUrl}
          thumbnailAlt={thumbnailAlt}
          autoplay={autoplay}
          hasLightbox={hasLightbox}
        />
      </Content>
    </Container>
  )
}

const Content = styled.div<{
  borderRadius?: string | number
}>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
`

// padding-top: 16:9 Aspect Ratio (divide 9 by 16 = 0.5625)
const Container = styled.div`
  display: block;
  padding-top: 56.25%;
  position: relative;
  height: 100%;
`
