import type { ReactElement } from 'react'

import type { Block, Text, Document } from '@contentful/rich-text-types'

import type { PaletteAlias } from '@cbhq/cds-common'
import type {
  ColumnDefinition,
  ColumnDefinitionWidth,
  ComponentsThemeStyle,
} from '@cbhq/cdx-components'
import {
  Div,
  componentsThemeMap,
  RichText,
  HighlightTable as HighlightTableComponent,
} from '@cbhq/cdx-components'

import { cellOptions } from './cellOptions'

export const HIGHLIGHT_TABLE_CONTENT_MODEL_ID = 'cdxHighlightTable'

export type HighlightTableFields = {
  headline?: string
  subhead?: Document
  anchor?: string
  hasSpacingTop?: boolean
  style?: ComponentsThemeStyle
  tableStyle?: 'alternate' | 'white'
  legalDisclaimer?: string // TODO: may change to richtext
  table?: Document
  mobileColumnsVisibility?: ('visible' | 'hidden')[]
  columnsWidth?: ColumnWidth[]
  columnsVerticalAlignment?: ('center' | 'top' | 'bottom')[]
}

type InnerProps = {
  options?: { hasSpacingHorizontal?: boolean; fullViewportWidth?: boolean }
}

type ColumnWidth = `${string}${ColumnDefinitionWidth}`

export const HighlightTable = ({
  headline,
  subhead,
  anchor,
  hasSpacingTop,
  style = 'default',
  tableStyle,
  legalDisclaimer,
  table,
  mobileColumnsVisibility = [],
  columnsWidth = [],
  options,
  columnsVerticalAlignment,
}: HighlightTableFields & InnerProps) => {
  const { foreground, foregroundSecondary } = componentsThemeMap[style]

  const mobileVisibility = mobileColumnsVisibility.map((def) =>
    def === 'hidden' ? true : false,
  )

  const { columns, data } = richTextToTableDefinitions(
    table,
    { mobileVisibility, width: columnsWidth },
    {
      color: foreground,
      secondaryColor: foregroundSecondary,
    },
    columnsVerticalAlignment,
  )

  if (!columns || !data) return null

  return (
    <HighlightTableComponent
      headline={headline}
      subhead={subhead}
      legalDisclaimer={legalDisclaimer}
      anchor={anchor}
      style={style}
      tableStyle={tableStyle}
      hasSpacingTop={hasSpacingTop}
      data={data}
      columns={columns}
      {...options}
    />
  )
}

const richTextToTableDefinitions = (
  document: Document | undefined,
  columnsConfigs: { mobileVisibility: boolean[]; width: ColumnWidth[] },
  richtextProps?: {
    color?: PaletteAlias
    secondaryColor?: PaletteAlias
  },
  columnsVerticalAlignment?: ('center' | 'top' | 'bottom')[],
) => {
  const table = document?.content.find(
    (content) => content.nodeType === 'table',
  )

  if (!table) return {}

  const tableHeadings = (table.content[0] as Block).content as Block[]

  const hasLabels = tableHeadings[0].nodeType === 'table-header-cell'

  const asColumns: ColumnDefinition<Record<string, ReactElement>>[] =
    tableHeadings.map((header, index) => {
      let labelText = ''

      if (hasLabels) {
        const labelNode = header.content.find(
          (c) => c.nodeType === 'paragraph',
        ) as Block
        labelText = (
          labelNode?.content.find((c) => c.nodeType === 'text') as Text
        )?.value
      }

      return {
        label: labelText,
        dataKey: `key-${index}`,
        size: columnsConfigs.width[index],
        hideOnMobile: columnsConfigs.mobileVisibility[index] || false,
        verticalAlignment: columnsVerticalAlignment?.[index] ?? 'center',
      }
    })

  const tableDataRows = hasLabels
    ? (table.content as Block[]).slice(1)
    : (table.content as Block[])

  const asData = tableDataRows.map((row) => {
    const columns = row.content as Block[]

    const toRichText = columns.map((column, index) => (
      <Div flexDirection="column" key={index} gap={3} flex={1}>
        <RichText
          {...richtextProps}
          content={{ ...column, nodeType: 'document' } as Document}
          paragraphSpacingTop={0}
          richTextOptions={cellOptions}
        />
      </Div>
    )) as ReactElement[]

    return toRichText.reduce<Record<string, ReactElement>>(
      (acc, cur, index) => ({ ...acc, [`key-${index}`]: cur }),
      {},
    )
  })

  return { columns: asColumns, data: asData }
}
