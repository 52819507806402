import type { Asset, Entry } from 'contentful'

import {
  HighlightLogo as HighlightLogoComponent,
  LogoImage,
} from '@cbhq/cdx-components'

import { getComposePageSeoImageUrlAndDescription } from '../../globals/composePage'
import { mapEntryLink } from '../../globals/navlink'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import type { ContentfulComponentsStyleOptions } from '../contentToPropsMap'
import { CONTENTFUL_COMPONENTS_STYLE_MAP } from '../contentToPropsMap'

export const HIGHLIGHT_LOGO_CONTENT_MODEL_ID = 'cdxHighlightLogo'

export type HighlightLogoFields = {
  anchor?: string
  subhead?: string
  headline?: string
  gridColumns?: number
  style: ContentfulComponentsStyleOptions
  link: Entry<CDXGlobalNavLinkFields>
  logoList: Entry<CDXLogoComponentFields>[]
  hasSpacingTop?: boolean
}

type CDXLogoComponentFields = {
  icon?: Asset
  link: Entry<CDXGlobalNavLinkFields>
}

export const HighlightLogo = ({
  link,
  anchor,
  subhead,
  headline,
  logoList,
  gridColumns = 5,
  style: styleProp,
  hasSpacingTop = false,
}: HighlightLogoFields) => {
  const style = CONTENTFUL_COMPONENTS_STYLE_MAP[styleProp]
  const seeEntryLink = mapEntryLink(link)

  return (
    <HighlightLogoComponent
      style={style}
      hasSpacingTop={hasSpacingTop}
      anchor={anchor}
      subhead={subhead}
      headline={headline}
      gridColumns={gridColumns}
      ctaUrl={seeEntryLink?.url}
      ctaLabel={seeEntryLink?.label}
    >
      {logoList &&
        logoList.map(({ fields: { link, icon } }, index) => {
          const logoLink = mapEntryLink(link)
          const { url, description } = getComposePageSeoImageUrlAndDescription(
            link?.fields?.composePage,
          )
          const imageSrc = icon?.fields?.file?.url || url

          const imageAlt = icon?.fields?.description || description

          return (
            <LogoImage
              key={logoLink?.label + index}
              label={logoLink?.label}
              imageAlt={imageAlt}
              imageSrc={imageSrc}
              url={logoLink?.url}
              imageFit="contain"
            />
          )
        })}
    </HighlightLogoComponent>
  )
}
