import type { Asset, Entry } from 'contentful'

import {
  HighlightAvatarCards as HighlightAvatarLogosComponent,
  AvatarCard,
} from '@cbhq/cdx-components'

import { Linkedin } from './icons/Linkedin'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'
import type { ContentfulComponentsStyleOptions } from '../contentToPropsMap'
import { CONTENTFUL_COMPONENTS_STYLE_MAP } from '../contentToPropsMap'

export const HIGHLIGHT_AVATAR_CARDS_CONTENT_MODEL_ID = 'cdxHighlightAvatarCards'

export type HighlightAvatarCardsFields = {
  anchor?: string
  subhead?: string
  headline?: string
  gridColumns?: number
  firstRowColumns?: number
  style: ContentfulComponentsStyleOptions
  link: Entry<CDXGlobalNavLinkFields>
  avatarCards: Entry<HighlightAvatarCardFields>[]
  hasSpacingTop?: boolean
}

export type HighlightAvatarCardFields = {
  headline?: string
  subtext?: string
  hideLinkedinLogo?: boolean
  avatar?: Asset
  link: Entry<CDXGlobalNavLinkFields>
}

export const HighlightAvatarCards = ({
  headline,
  subhead,
  anchor,
  avatarCards,
  gridColumns = 4,
  firstRowColumns = 2,
  style: styleProp,
  hasSpacingTop,
}: HighlightAvatarCardsFields) => {
  const style = CONTENTFUL_COMPONENTS_STYLE_MAP[styleProp]

  return (
    <HighlightAvatarLogosComponent
      headline={headline}
      subhead={subhead}
      anchor={anchor}
      style={style}
      gridColumns={gridColumns}
      hasSpacingTop={hasSpacingTop}
    >
      {avatarCards &&
        avatarCards.map(
          (
            { fields: { link, headline, subtext, avatar, hideLinkedinLogo } },
            index,
          ) => {
            const { url, label } = mapEntryLink(link)

            const imageSrc = avatar?.fields?.file?.url
            const imageAlt = avatar?.fields?.description

            return (
              <AvatarCard
                headline={headline}
                subtext={subtext}
                url={url}
                label={label}
                style={style}
                imageFit="contain"
                imageAlt={imageAlt || ''}
                imageSrc={imageSrc}
                key={label + index}
                icon={!hideLinkedinLogo && <Linkedin />}
                gridRow={setGridRow(index + 1, firstRowColumns, gridColumns)}
              />
            )
          },
        )}
    </HighlightAvatarLogosComponent>
  )
}

const setGridRow = (gridItemNumber: number, firstRowCols = 2, cols = 4) => {
  let gridRow = 1
  let gridItemNumberCloned = gridItemNumber
  while (
    gridItemNumberCloned > firstRowCols ||
    (gridItemNumberCloned > firstRowCols && gridItemNumberCloned > cols)
  ) {
    gridRow++
    gridItemNumberCloned -= cols
  }
  return gridRow
}
