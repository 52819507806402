import { unstable_serialize } from 'swr/infinite'

import type {
  ComposePageEntry,
  ListingTemplateFields,
} from '@cbhq/cdx-contentful'
import {
  getComposeEntryProps,
  fetchArticlesWithTag,
  fetchSiteConfig,
  getEntryProps,
} from '@cbhq/cdx-contentful'

import type { ListingTemplateExtraProps } from './ListingTemplate'
import { getLandingPaginationInfiniteScrollKey } from './usePagination'
import { getAppConfig } from '../../config'
import type { DataHandlerReturn, StaticQueryOpts } from '../../types'

export const handleStaticPropsListing = async (
  composePage: ComposePageEntry<ListingTemplateFields>,
  queryOpts: StaticQueryOpts,
): DataHandlerReturn<ListingTemplateFields, ListingTemplateExtraProps> => {
  const { contentfulConfig } = getAppConfig()

  const tags = composePage.fields.content.fields.tags

  const contentfulOptions = {
    env: queryOpts.env || null,
    isPreview: queryOpts.isPreview || false,
    slug: queryOpts.slug || null,
  }

  const [articles, siteConfig] = await Promise.all([
    fetchArticlesWithTag(
      contentfulConfig.appTagId,
      tags,
      contentfulOptions,
      20,
    ),
    fetchSiteConfig(queryOpts),
  ])

  const { projectSettings, title } = getComposeEntryProps(composePage)

  const { defaultSeoDescription, seoFallbackImage } =
    getEntryProps(projectSettings)

  const canonicalUrl = `${process.env.HOSTNAME}${composePage?.fields?.slug}`

  return {
    page: composePage,
    extraProps: {
      swrData: {
        [unstable_serialize(getLandingPaginationInfiniteScrollKey(tags))]: [
          articles.items,
        ],
      },
      totalCount: articles.total,
      contentfulOptions,
      siteConfig,
      canonicalUrl,
    },
    seoDefaultProps: {
      title: title || null,
      description: defaultSeoDescription || null,
      imageUrl: seoFallbackImage?.fields.file.url || null,
    },
  }
}
