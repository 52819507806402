import type { Asset, Entry } from 'contentful'

import type { ComponentsThemeStyle } from '@cbhq/cdx-components'
import {
  Wayfinding,
  WayfindingCardResource,
  formatDateWithoutTimeZone,
} from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'
import { getEntryProps } from '../../utils/getEntryProps'

export type WayfindingResourceListFields = {
  headline: string
  body?: string
  link?: Entry<CDXGlobalNavLinkFields>
  style: ComponentsThemeStyle
  hasSpacingTop?: boolean
  resources: Entry<WayfindingResourceListItemFields>[]
  anchor?: string
}

export const WAYFINDING_RESOURCE_LIST_CONTENT_MODEL_ID =
  'cdxWayfindingCardResourceList'

export type WayfindingResourceListItemFields = {
  headline: string
  body?: string
  link?: Entry<CDXGlobalNavLinkFields>
  thumbnail?: Asset
  date?: string
}

type InnerProps = {
  options?: Record<string, string> & {
    hasSpacingTop?: boolean
    hasSpacingHorizontal?: boolean
    style?: ComponentsThemeStyle
  }
}

export const WAYFINDING_RESOURCE_CONTENT_MODEL_ID = 'cdxComponentResource'

export const WayfindingResourceList = ({
  headline,
  body,
  link,
  style,
  resources,
  hasSpacingTop,
  anchor,
  options,
}: WayfindingResourceListFields & InnerProps) => {
  const navLink = link && mapEntryLink(link)

  return (
    <Wayfinding
      headline={headline}
      body={body}
      ctaButtonUrl={navLink?.url}
      ctaButtonLabel={navLink?.label}
      columns={1}
      rows="all"
      style={options?.style || style}
      anchor={anchor}
      hasSpacingTop={
        options?.hasSpacingTop !== undefined
          ? options?.hasSpacingTop
          : hasSpacingTop
      }
      hasSpacingHorizontal={options?.hasSpacingHorizontal}
      hasOneColumnDivider
    >
      {resources?.map((entry, index) => {
        const { headline, body, link, thumbnail, date } = getEntryProps(
          entry,
          {},
        )

        const resourceNavLink = link && mapEntryLink(link)

        return (
          <WayfindingCardResource
            key={index}
            mediaAlt={thumbnail?.fields.title}
            mediaUrl={thumbnail?.fields.file.url}
            headline={headline || ''}
            body={body}
            ctaButtonLabel={resourceNavLink?.label}
            href={resourceNavLink && resourceNavLink.url}
            date={formatDateWithoutTimeZone(date)}
            style={options?.style || style}
          />
        )
      })}
    </Wayfinding>
  )
}
