import dynamic from 'next/dynamic'

import type { CMSComponentMap } from '@cbhq/cdx-components'
import {
  ACTION_BANNER_CENTER_CONTENT_MODEL_ID,
  ACTION_BANNER_CONTENT_MODEL_ID,
  EDITORIAL_BODY_ARTICLE_CONTENT_MODEL_ID,
  GLOBAL_MEDIA_CONTENT_MODEL_ID,
  GLOBAL_NAV_LINK_CONTENT_MODEL_ID,
  HERO_FEATURED_EDITORIAL_CONTENT_MODEL_ID,
  HERO_PRIMARY_CONTENT_MODEL_ID,
  HIGHLIGHT_ACCORDION_CONTENT_MODEL_ID,
  HIGHLIGHT_ASSET_LIST_MODEL_ID,
  HIGHLIGHT_ASSET_TABLE_CONTENT_MODEL_ID,
  HIGHLIGHT_AVATAR_CARDS_CONTENT_MODEL_ID,
  HIGHLIGHT_CARDS_CAROUSEL_MODEL_ID,
  HIGHLIGHT_CARD_ILLUSTRATION_CONTENT_MODEL_ID,
  HIGHLIGHT_COMPARISON_CARD_CONTENT_MODEL_ID,
  HIGHLIGHT_COMPARISON_TABLE_CONTENT_MODEL_ID,
  HIGHLIGHT_IMAGE_TEXT_CONTENT_MODEL_ID,
  HIGHLIGHT_LOGO_CONTENT_MODEL_ID,
  HIGHLIGHT_QUOTE_CONTENT_MODEL_ID,
  HIGHLIGHT_STAT_CHIPS_CONTENT_MODEL_ID,
  HIGHLIGHT_TABLE_CONTENT_MODEL_ID,
  HIGHLIGHT_TEXT_HEADLINE_CONTENT_MODEL_ID,
  HIGHLIGHT_VIDEO_CONTENT_MODEL_ID,
  HIGHLIGHT_VIDEO_LIST_CONTENT_MODEL_ID,
  TAB_CARDS_CONTENT_MODEL_ID,
  WAYFINDING_CARD_ARTICLE_CONTENT_MODEL_ID,
  WAYFINDING_RESOURCE_LIST_CONTENT_MODEL_ID,
  WAYFINDING_ARTICLE_CARD_LIST_CONTENT_MODEL_ID,
} from '@cbhq/cdx-contentful'

export const componentMap: CMSComponentMap = {
  [HIGHLIGHT_IMAGE_TEXT_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightImageText),
  ),
  [EDITORIAL_BODY_ARTICLE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.EditorialBodyArticle),
  ),
  [WAYFINDING_RESOURCE_LIST_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.WayfindingResourceList),
  ),
  [HERO_FEATURED_EDITORIAL_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HeroFeaturedEditorial),
  ),
  [HIGHLIGHT_ACCORDION_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightAccordion),
  ),
  [HIGHLIGHT_ASSET_LIST_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightAssetList),
  ),
  [HIGHLIGHT_ASSET_TABLE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightAssetTable),
  ),
  [HIGHLIGHT_COMPARISON_CARD_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightComparisonCard),
  ),
  [HIGHLIGHT_COMPARISON_TABLE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightComparisonTable),
  ),
  [HIGHLIGHT_VIDEO_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightVideo),
  ),
  [HIGHLIGHT_VIDEO_LIST_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightVideoList),
  ),
  [HERO_PRIMARY_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HeroPrimary),
  ),
  [HIGHLIGHT_LOGO_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightLogo),
  ),
  [HIGHLIGHT_QUOTE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightBlockQuote),
  ),
  [HIGHLIGHT_CARD_ILLUSTRATION_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightCardIllustration),
  ),
  [WAYFINDING_CARD_ARTICLE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.WayfindingCardArticle),
  ),
  [WAYFINDING_ARTICLE_CARD_LIST_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.WayfindingArticleCardList),
  ),
  [HIGHLIGHT_STAT_CHIPS_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightStatChips),
  ),
  [HIGHLIGHT_CARDS_CAROUSEL_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightCardsCarousel),
  ),
  [ACTION_BANNER_CENTER_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.ActionBannerCenter),
  ),
  [ACTION_BANNER_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.ActionBanner),
  ),
  [HIGHLIGHT_AVATAR_CARDS_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightAvatarCards),
  ),
  [TAB_CARDS_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.WayfindingTabCards),
  ),
  [HIGHLIGHT_TEXT_HEADLINE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightTextHeadline),
  ),
  [HIGHLIGHT_TABLE_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.HighlightTable),
  ),
  [GLOBAL_MEDIA_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.GlobalMedia),
  ),
  [GLOBAL_NAV_LINK_CONTENT_MODEL_ID]: dynamic(() =>
    import('@cbhq/cdx-contentful').then((x) => x.GlobalNavLink),
  ),
}
