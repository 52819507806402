import { HighlightTextHeadline as TextHeadlineComponent } from '@cbhq/cdx-components'
import type { ComponentsThemeStyle } from '@cbhq/cdx-components'

export type HighlightTextHeadlineFields = {
  headline: string
  subhead?: string
  anchor?: string
  hasSpacingTop?: boolean
  style?: ComponentsThemeStyle
}

export const HIGHLIGHT_TEXT_HEADLINE_CONTENT_MODEL_ID =
  'cdxHighlightTextHeadline'

export const HighlightTextHeadline = ({
  headline,
  subhead,
  hasSpacingTop,
  anchor,
  style,
}: HighlightTextHeadlineFields) => (
  <TextHeadlineComponent
    headline={headline}
    subhead={subhead}
    anchor={anchor}
    style={style}
    hasSpacingVertical
    hasSpacingTop={hasSpacingTop}
  />
)
