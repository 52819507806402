import { useMemo } from 'react'

import styled from 'styled-components'

import type {
  IconName,
  NavigationIconName,
  PaletteAlias,
} from '@cbhq/cds-common'
import type {
  IllustrationPictogramNames,
  IllustrationSpotRectangleNames,
  IllustrationSpotSquareNames,
} from '@cbhq/cds-web'
import { Icon } from '@cbhq/cds-web/icons'
import {
  Pictogram,
  SpotSquare,
  SpotRectangle,
} from '@cbhq/cds-web/illustrations'

import type { DivProps } from '../../ui/Div'
import { Div } from '../../ui/Div'

export type IconTypes = IconName & NavigationIconName

export type PictogramType =
  | IllustrationPictogramNames
  | IllustrationSpotSquareNames
  | IllustrationSpotRectangleNames
export type PictogramStyleType = 'Pictogram' | 'Spot Square' | 'Spot Rectangle'

export type CardIllustrationIconProps = {
  imageUrl?: string
  imageAlt?: string
  icon?: IconTypes
  pictogram?: PictogramType
  pictogramStyle?: 'Pictogram' | 'Spot Square' | 'Spot Rectangle'
  maxWidth?: DivProps['maxWidth']
  height?: DivProps['height']
  iconColor?: PaletteAlias
}

const pictogramComponentMap: {
  [key in Exclude<CardIllustrationIconProps['pictogramStyle'], undefined>]:
    | typeof Pictogram
    | typeof SpotSquare
    | typeof SpotRectangle
} = {
  Pictogram,
  'Spot Square': SpotSquare,
  'Spot Rectangle': SpotRectangle,
}

const DEFAULT_ALT_TEXT = 'Card Illustration Image'

export const CardIllustrationIcon = ({
  icon,
  imageUrl,
  imageAlt,
  pictogram,
  pictogramStyle = 'Pictogram',
  maxWidth,
  height = 80,
  iconColor = 'foreground',
}: CardIllustrationIconProps) => {
  const content = useMemo(() => {
    if (icon) return <Icon name={icon} color={iconColor as any} size="m" />

    if (imageUrl)
      return <StyledImage alt={imageAlt || DEFAULT_ALT_TEXT} src={imageUrl} />

    if (pictogram) {
      const PictogramComponent = pictogramComponentMap[
        pictogramStyle
      ] as React.FC<{ name: CardIllustrationIconProps['pictogram'] }>
      return (
        <PictogramContainer>
          <PictogramComponent name={pictogram} />
        </PictogramContainer>
      )
    }
  }, [icon, imageAlt, imageUrl, pictogram, pictogramStyle, iconColor])

  return (
    <Div height={height} maxWidth={maxWidth} position="relative" width="100%">
      {content}
    </Div>
  )
}

const PictogramContainer = styled.div`
  display: contents;
  img {
    height: 100% !important;
    width: fit-content;
  }
`

const StyledImage = styled.img`
  width: unset;
  height: 100%;
  object-fit: contain;
  position: absolute;
`
