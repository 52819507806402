import { componentsThemeMap } from '../../componentsThemeMap'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { CTAButtons } from '../../ui/CTAButtons'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'

export type HighlightLogoProps = {
  headline?: string
  subhead?: string
  ctaLabel?: string
  ctaUrl?: string
  children: React.ReactNode
  gridColumns: number
  style: ComponentsThemeStyle
  anchor?: string
  hasSpacingTop?: boolean
}

export const HighlightLogo = ({
  headline,
  subhead,
  ctaLabel,
  ctaUrl,
  children,
  gridColumns,
  style = 'default',
  anchor,
  hasSpacingTop,
}: HighlightLogoProps) => {
  const { background, foreground, foregroundSecondary } =
    componentsThemeMap[style]

  const hasLogoSpacingTop = headline || subhead || ctaLabel

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightLogos">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal
          background={background}
          anchor={anchor}
          hasNonDefaultBackgroundSpacingVertical
        >
          <Div width="100%" flexDirection="column">
            <Div width="100%">
              <Div alignItems="flex-start" flex={1} flexDirection="column">
                {headline && (
                  <Text
                    variant={{ sm: 'title2', lg: 'display2' }}
                    as="h3"
                    color={foreground}
                    spacingEnd={{ md: 10 }}
                  >
                    {headline}
                  </Text>
                )}
                {subhead && (
                  <Text
                    variant="body"
                    as="p"
                    color={foregroundSecondary}
                    spacingTop={2}
                    spacingEnd={{ md: 10 }}
                  >
                    {subhead}
                  </Text>
                )}
              </Div>

              <Div
                alignItems="end"
                justifyContent="end"
                spacingStart={4}
                display={{ sm: 'none', md: 'flex' }}
              >
                {ctaLabel && ctaUrl && (
                  <CTAButtons
                    ctaButtonLabel={ctaLabel}
                    ctaButtonUrl={ctaUrl}
                    contentBackground={background}
                  />
                )}
              </Div>
            </Div>

            <Div
              width="100%"
              spacingTop={hasLogoSpacingTop ? 7 : 0}
              flexDirection="column"
              alignItems="center"
            >
              <Div
                display="grid"
                gap={{ md: 3, lg: gridColumns < 5 ? 7 : 3 }}
                gridTemplateColumns={{
                  sm: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                  lg: `repeat(${gridColumns}, 1fr)`,
                }}
              >
                {children}
              </Div>
            </Div>

            <Div spacingTop={4} width="100%" display={{ md: 'none' }}>
              {ctaLabel && ctaUrl && (
                <CTAButtons
                  ctaButtonLabel={ctaLabel}
                  ctaButtonUrl={ctaUrl}
                  contentBackground={background}
                />
              )}
            </Div>
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
