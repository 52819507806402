import { isYoutube, isVimeo } from './linkUtils'
import { PlayerFrameWithIFrame } from './PlayerFrameWithIFrame'
import { PlayerFrameWithVimeoApi } from './PlayerFrameWithVimeoApi'
import { PlayerFrameWithYoutubeApi } from './PlayerFrameWithYoutubeApi'
import { Only } from '../Only'

type PlayerFrameProps = {
  mediaUrl: string
  autoplay?: boolean
}

export const PlayerFrame = ({ mediaUrl, autoplay }: PlayerFrameProps) => {
  const srcHasQueryParams = mediaUrl.includes('?')

  const videoUrl = `${mediaUrl}${
    srcHasQueryParams ? '&' : '?'
  }autoplay=1&modestbranding=1`

  if (isYoutube(mediaUrl))
    return (
      <>
        <Only on={['phone', 'tablet']}>
          <PlayerFrameWithYoutubeApi src={videoUrl} />
        </Only>

        <Only on="desktop">
          <PlayerFrameWithIFrame
            src={`${videoUrl}${autoplay ? '&mute=1' : ''}`}
          />
        </Only>
      </>
    )

  if (isVimeo(mediaUrl)) return <PlayerFrameWithVimeoApi src={mediaUrl} />

  return (
    <PlayerFrameWithIFrame src={`${videoUrl}${autoplay ? '&mute=1' : ''}`} />
  )
}
