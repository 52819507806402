import type { Document } from '@contentful/rich-text-types'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import type { RichTextOption } from '../../ui/RichText'
import { RichText, articleRichTextOptions } from '../../ui/RichText'
import { Text } from '../../ui/Text'

export type EditorialBodyCopyProps = {
  title?: string
  text?: Document
  style?: ComponentsThemeStyle
  anchor?: string
  hasSpacingTop?: boolean
}

type EditorialBodyCopyInnerProps = {
  richTextOptions?: RichTextOption
}

export const EditorialBodyCopy = ({
  style = 'default',
  title,
  text,
  hasSpacingTop,
  anchor,
  richTextOptions,
}: EditorialBodyCopyProps & EditorialBodyCopyInnerProps) => {
  const { foreground, foregroundSecondary, background, foregroundInlineLink } =
    componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="EditorialBodyCopy">
        <SectionComponentLayout
          as="section"
          background={background}
          hasSpacingHorizontal
          anchor={anchor}
          hasSpacingTop={hasSpacingTop}
          hasNonDefaultBackgroundSpacingVertical
        >
          {title && (
            <Text variant="display2" color={foreground}>
              {title}
            </Text>
          )}
          {text && (
            <RichText
              content={text}
              richTextOptions={richTextOptions || articleRichTextOptions}
              color={foregroundSecondary}
              secondaryColor={foregroundSecondary}
              foregroundInlineLink={foregroundInlineLink}
            />
          )}
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
