import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { CTAButtons } from '../../ui/CTAButtons'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { ImageFullWidth } from '../../ui/ImageFullWidth'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { Text } from '../../ui/Text'
import { componentsThemeMap } from '../../componentsThemeMap'

export type ActionBannerCenterProps = {
  mediaUrl?: string
  mediaAlt?: string
  style?: ComponentsThemeStyle
  hasSpacingTop?: boolean
  anchor?: string
  headline: string
  subhead?: string
  ctaButtonLabel: string
  ctaButtonUrl: string
}

export const ActionBannerCenter = ({
  mediaUrl,
  mediaAlt,
  style = 'default',
  hasSpacingTop = false,
  anchor,
  headline,
  subhead,
  ctaButtonLabel,
  ctaButtonUrl,
}: ActionBannerCenterProps) => {
  const hasMedia = !!mediaUrl && !!mediaAlt

  const { foregroundSecondary, background } = componentsThemeMap[style]
  const spacingBottom = hasMedia
    ? { sm: 7, md: 15 }
    : style !== 'default'
    ? { sm: 4, md: 7 }
    : 0

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="ActionBannerCenter">
        <SectionComponentLayout
          as="section"
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal
          anchor={anchor}
          hasNonDefaultBackgroundSpacingTop
          background={background}
        >
          <Div
            flexDirection="column"
            alignItems="center"
            spacingBottom={spacingBottom}
          >
            <Div>
              <Text
                variant={{ sm: 'title2', lg: 'display2' }}
                as="h2"
                color={foregroundSecondary}
                css={{ textAlign: 'center' }}
              >
                {headline}
              </Text>
            </Div>

            {subhead && (
              <Text
                variant="body"
                as="p"
                spacingTop={2}
                color={foregroundSecondary}
              >
                {subhead}
              </Text>
            )}

            <Div>
              <CTAButtons
                ctaButtonLabel={ctaButtonLabel}
                ctaButtonUrl={ctaButtonUrl}
                contentBackground={background}
              />
            </Div>
          </Div>

          {hasMedia && <ImageFullWidth src={mediaUrl} altText={mediaAlt} />}
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
