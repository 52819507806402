import { forwardRef } from 'react'

import styled from 'styled-components'

export const ScreenReaderOnly = forwardRef(
  (
    props: React.ComponentProps<'div'>,
    ref: React.ForwardedRef<HTMLDivElement>,
  ) => <ScreenReader {...props} ref={ref} />,
)

ScreenReaderOnly.displayName = 'ScreenReaderOnly'

const ScreenReader = styled.div`
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  white-space: nowrap;
  border: 0;
  clip: rect(0, 0, 0, 0);
`
