import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { CTAButtons } from '../../ui/CTAButtons'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'

export type HighlightComparisonCardProps = {
  headline?: string
  subhead?: string
  ctaButtonLabel?: string
  ctaButtonUrl?: string
  style?: ComponentsThemeStyle
  hasSpacingTop?: boolean
  children?: React.ReactNode
  anchor?: string
  cardCount?: number
}

export const HighlightComparisonCard = ({
  headline,
  subhead,
  ctaButtonUrl,
  ctaButtonLabel,
  children,
  hasSpacingTop,
  anchor,
  style = 'default',
  cardCount = 1,
}: HighlightComparisonCardProps) => {
  const hasCta = Boolean(ctaButtonLabel && ctaButtonUrl)

  const { background, foreground, foregroundSecondary } =
    componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightComparisonCard">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal
          background={background}
          anchor={anchor}
          hasNonDefaultBackgroundSpacingVertical
        >
          <Div
            display="flex"
            gap={5}
            justifyContent="space-between"
            flexDirection="column"
          >
            <Div flexDirection="row" width="100%" justifyContent="center">
              <Div flexDirection="column" flex={1} maxWidth={800}>
                <Text
                  as="h2"
                  color={foreground}
                  variant={{ sm: 'title2', lg: 'display2' }}
                >
                  {headline}
                </Text>
                {!!subhead && (
                  <Div spacingTop={1.5}>
                    <Text as="p" color={foregroundSecondary} variant="body">
                      {subhead}
                    </Text>
                  </Div>
                )}
              </Div>
              {hasCta && (
                <Div display="flex">
                  <CTAButtons
                    ctaButtonLabel={ctaButtonLabel}
                    ctaButtonUrl={ctaButtonUrl}
                    contentBackground={background}
                  />
                </Div>
              )}
            </Div>
            <Div
              flexDirection="column"
              spacingTop={2}
              width="100%"
              alignItems="center"
            >
              <Div
                display="grid"
                alignContent="baseline"
                height="100%"
                gridTemplateColumns={{
                  sm: 'repeat(1, 1fr)',
                  md: `repeat(${cardCount === 1 ? 1 : 2} ,1fr)`,
                  lg: `repeat( ${cardCount},1fr)`,
                }}
                rowGap={{ sm: 3, lg: 6 }}
                columnGap={{ sm: 6, md: 3 }}
              >
                {children}
              </Div>
            </Div>
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
