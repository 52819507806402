import type { Document } from '@contentful/rich-text-types'

import { EditorialBodyCopy } from '@cbhq/cdx-components'
import type { ComponentsThemeStyle } from '@cbhq/cdx-components'

export type EditorialBodyArticleFields = {
  title: string
  subtitle?: Document
  style?: ComponentsThemeStyle
  anchor?: string
  hasSpacingTop?: boolean
}

export const EDITORIAL_BODY_ARTICLE_CONTENT_MODEL_ID = 'cdxEditorialBodyArticle'

export const EditorialBodyArticle = ({
  title,
  subtitle,
  style,
  anchor,
  hasSpacingTop,
}: EditorialBodyArticleFields) => (
  <EditorialBodyCopy
    title={title}
    text={subtitle}
    style={style}
    anchor={anchor}
    hasSpacingTop={hasSpacingTop}
  />
)
