import { Fragment } from 'react'

import { Image } from '../../ui/Image'
import type { ImageFit } from '../../ui/Image'
import { Link } from '../../ui/Link'

export type LogoProps = {
  label?: string
  url?: string
  imageAlt?: string
  imageSrc?: string
  imageFit?: ImageFit
}

export const LogoImage = ({
  label,
  url,
  imageAlt,
  imageSrc,
  imageFit,
}: LogoProps) => {
  const LogoContainer = url ? Link : Fragment
  const logoProps = url && { href: url }

  return (
    <LogoContainer {...logoProps}>
      <Image
        imageFit={imageFit}
        alt={imageAlt || label}
        src={imageSrc}
        height={{ sm: 180, md: 230 }}
        maxWidth={[180, 230]}
      />
    </LogoContainer>
  )
}
