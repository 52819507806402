import type { PaletteAlias } from '@cbhq/cds-common'
import type { ComponentsThemeStyle } from '@cbhq/cdx-components'
import { componentsThemeMap } from '@cbhq/cdx-components'

export const customComponentThemeMap: {
  [key in ComponentsThemeStyle]: {
    background: PaletteAlias
    foreground: PaletteAlias
    foregroundSecondary: PaletteAlias
  }
} = {
  default: {
    ...componentsThemeMap.default,
  },
  dark: {
    ...componentsThemeMap.dark,
  },
  subtle: {
    ...componentsThemeMap.subtle,
    background: 'gray15' as PaletteAlias,
  },
  wash: {
    ...componentsThemeMap.wash,
  },
  darkwash: {
    ...componentsThemeMap.darkwash,
  },
  primary: {
    ...componentsThemeMap.primary,
  },
}
