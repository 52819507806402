import type { Entry } from 'contentful'

import type {
  ComposePageEntry,
  LandingTemplateExtraProps,
  LandingTemplateFields,
  HeroFeaturedEditorialFields,
  WayfindingCardArticleFields,
} from '@cbhq/cdx-contentful'
import {
  getComposeEntryProps,
  fetchSiteConfig,
  getEntryProps,
  HERO_FEATURED_EDITORIAL_CONTENT_MODEL_ID,
  WAYFINDING_CARD_ARTICLE_CONTENT_MODEL_ID,
  filterHomeSectionFields,
  getEntryTypeId,
  handleAutomaticWayfinding,
} from '@cbhq/cdx-contentful'

import { getAppConfig } from '../../config'
import type { DataHandlerReturn, StaticQueryOpts } from '../../types'

export const handleStaticPropsLanding = async (
  composePage: ComposePageEntry<LandingTemplateFields>,
  queryOpts: StaticQueryOpts,
): DataHandlerReturn<LandingTemplateFields, LandingTemplateExtraProps> => {
  const { contentfulConfig } = getAppConfig()
  const { projectSettings } = getComposeEntryProps(composePage)

  const { defaultSeoDescription, seoFallbackImage } =
    getEntryProps(projectSettings)

  const { content: template } = getEntryProps(composePage)

  const wayfindingCardArticles: Entry<WayfindingCardArticleFields>[] | null =
    template?.fields?.content?.filter(
      (entry: any) =>
        getEntryTypeId(entry) === WAYFINDING_CARD_ARTICLE_CONTENT_MODEL_ID,
    ) || null

  // Skip featured editorial hero entry from the grid if present in the contents of the page
  const featuredEditorialHero: Entry<HeroFeaturedEditorialFields> | undefined =
    template?.fields?.content?.find(
      (entry: Entry<any>) =>
        getEntryTypeId(entry) === HERO_FEATURED_EDITORIAL_CONTENT_MODEL_ID,
    )
  if (wayfindingCardArticles) {
    await Promise.all(
      wayfindingCardArticles.map(async (wayfinding) => {
        await handleAutomaticWayfinding(contentfulConfig.appTagId, wayfinding, {
          ...queryOpts,
          slug:
            featuredEditorialHero?.fields.featuredEditorialPage.fields.slug ||
            queryOpts.slug,
        })
      }),
    )
  }

  let composePageWithFilteredHero = null
  if (featuredEditorialHero || wayfindingCardArticles) {
    composePageWithFilteredHero = filterHomeSectionFields(
      composePage,
    ) as ComposePageEntry<LandingTemplateFields>
  }

  const siteConfig = await fetchSiteConfig(queryOpts)

  const canonicalUrl = `${process.env.HOSTNAME}${queryOpts.slug}`

  return {
    page: composePageWithFilteredHero ?? composePage,
    seoDefaultProps: {
      title: null,
      description: defaultSeoDescription || null,
      imageUrl: seoFallbackImage?.fields.file.url || null,
    },
    extraProps: {
      siteConfig,
      canonicalUrl,
    },
  }
}
