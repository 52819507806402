import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import styled from 'styled-components'

import type { IconName } from '@cbhq/cds-common'
import type { PaletteForeground } from '@cbhq/cds-web'
import { Icon } from '@cbhq/cds-web/icons/Icon'
import { spacing } from '@cbhq/cds-web/tokens'

import { composeRichTextComponents } from './composeRichTextComponents'
import { ANALYTICS_KEY } from '../../../constants'
import { Div } from '../../Div'
import { Text } from '../../Text'
import { Heading, InlineHyperLink } from '../components'
import type { RichTextOption, RichTextPropsType } from '../types'

export const createListRichTextOptions = (
  listStyle?: IconName,
  iconColor: PaletteForeground = 'primary',
): RichTextOption =>
  composeRichTextComponents({
    customRenderOptions: {
      [INLINES.HYPERLINK]: (props?: RichTextPropsType) =>
        InlineHyperLink({
          dataQa: ANALYTICS_KEY.LabelRichText,
          ...props,
        }),

      [BLOCKS.PARAGRAPH]: (props?: RichTextPropsType) => (node, children) => {
        return (
          <Text
            as="p"
            display="block"
            color={props?.color}
            spacingTop={{ sm: 1, lg: 2 }}
            variant={{ sm: 'label2', lg: 'body' }}
          >
            <BreakSpaces>{children}</BreakSpaces>
          </Text>
        )
      },

      [BLOCKS.HEADING_2]: (props?: RichTextPropsType) =>
        Heading({ as: 'h2', ...props, color: props?.secondaryColor }),

      [BLOCKS.UL_LIST]: () => (node, children) => {
        return <StyledList>{children}</StyledList>
      },

      [BLOCKS.LIST_ITEM]: (props?: RichTextPropsType) => (node, children) => {
        return (
          <StyledLi as="li" color={props?.color} $hasCustomIcon={!!listStyle}>
            {listStyle ? (
              <>
                <Div position="absolute" spacingTop={{ sm: 0.5, lg: 2 }}>
                  <Icon name={listStyle} color={iconColor} size="m" />
                </Div>
                {children}
              </>
            ) : (
              children
            )}
          </StyledLi>
        )
      },
    },
  })

const BreakSpaces = styled.span`
  white-space: break-spaces;
`

const StyledLi = styled(Div)<{ $hasCustomIcon?: boolean }>`
  position: relative;
  display: list-item;
  list-style: disc;

  ${({ $hasCustomIcon }) =>
    $hasCustomIcon &&
    `
    list-style: none;
    p {
      margin-left: ${spacing[5]};
     }
  `};
`

const StyledList = styled.ul`
  padding-inline-start: ${spacing[2]};
`
