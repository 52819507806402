import styled from 'styled-components'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { Image } from '../../ui/Image'
import { LinkButton } from '../../ui/Link'
import { Text } from '../../ui/Text'

export type WayfindingCardResourceProps = {
  headline: string
  href?: string
  body?: string
  mediaUrl?: string
  mediaAlt?: string
  date?: string
  style?: ComponentsThemeStyle
  ctaButtonLabel?: string
}

const DEFAULT_ALT_TEXT = 'Card Image'

export const WayfindingCardResource = ({
  headline,
  href,
  body,
  mediaUrl,
  mediaAlt,
  date,
  style = 'default',
  ctaButtonLabel = 'Go',
}: WayfindingCardResourceProps) => {
  const { foreground, foregroundSecondary } = componentsThemeMap[style]

  return (
    <Div
      display="grid"
      gridTemplateAreas={{
        sm: mobileAreas,
        md: areas,
      }}
      gridTemplateColumns={{
        sm: '48px auto 1fr',
        md: '172px 1fr auto',
        lg: '200px 1fr auto',
      }}
      columnGap={{ sm: 2, md: 4, lg: 8 }}
      rowGap={{ sm: 3, md: 0 }}
      alignItems="center"
    >
      <GridArea area="thumbnail">
        <Image
          alt={mediaAlt || DEFAULT_ALT_TEXT}
          src={mediaUrl}
          aspectRatio={1}
        />
      </GridArea>

      <GridArea area="date">
        {date && (
          <Text variant="legal" as="p" color={foregroundSecondary}>
            {date}
          </Text>
        )}
      </GridArea>

      <GridArea area="title">
        <Text
          as="h3"
          spacingTop={0}
          variant={{ sm: 'title4', lg: 'title2' }}
          color={foreground}
        >
          {headline}
        </Text>
      </GridArea>

      <GridArea area="body">
        {body && (
          <Text
            variant="body"
            spacingTop={0}
            as="p"
            color={foregroundSecondary}
          >
            {body}
          </Text>
        )}
      </GridArea>

      <GridArea area="cta">
        {href && (
          <Div justifyContent="flex-end">
            <LinkButton
              href={href}
              data-qa={ANALYTICS_KEY.CardResourceGrid}
              variant={style === 'primary' ? 'secondary' : 'primary'}
            >
              {ctaButtonLabel}
            </LinkButton>
          </Div>
        )}
      </GridArea>
    </Div>
  )
}

const areas = `
  "thumbnail date cta"
  "thumbnail title cta"
  "thumbnail body cta"
`

const mobileAreas = `
  "thumbnail title title"
  "body body body"
  "date date cta"
`

const GridArea = styled.div<{ area: string }>`
  grid-area: ${({ area }) => area};
`
