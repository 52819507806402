import { Fragment } from 'react'

import { colorToVariable, componentsThemeMap } from '../../componentsThemeMap'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { Div } from '../../ui/Div'
import { Image } from '../../ui/Image'
import type { ImageFit } from '../../ui/Image'
import { Link } from '../../ui/Link'
import { LinkPressable } from '../../ui/Link/LinkPressable'
import { Text } from '../../ui/Text'

export type AvatarCardProps = {
  headline?: string
  subtext?: string
  label?: string
  url?: string
  gridRow?: number
  imageAlt?: string
  imageSrc?: string
  imageFit?: ImageFit
  icon?: React.ReactNode
  style: ComponentsThemeStyle
}

export const AvatarCard = ({
  headline,
  subtext,
  gridRow,
  label,
  url,
  icon,
  style,
  imageFit,
  imageSrc,
  imageAlt,
}: AvatarCardProps) => {
  const { foregroundSecondary, borderLine, primary } = componentsThemeMap[style]

  const linkWrapperProps = url && {
    href: url,
    'data-qa': ANALYTICS_KEY.AvatarCard,
  }
  const LinkWrapper = url ? LinkPressable : Fragment

  return (
    <Div
      width={232}
      alignItems="center"
      flexDirection="column"
      css={{ textAlign: 'center' }}
      gridRow={{ lg: gridRow || 'auto' }}
    >
      <Link href={url} data-qa={ANALYTICS_KEY.AvatarCard}>
        <Image
          css={{
            borderRadius: '50%',
            border: '1px solid',
            borderColor: colorToVariable(borderLine || 'line'),
            padding: 12,
            img: {
              borderRadius: '50%',
            },
          }}
          imageFit={imageFit}
          src={imageSrc}
          alt={imageAlt || label}
          borderRadius="roundedFull"
          maxWidth={{ sm: 180, md: 230 }}
          height={{ sm: 180, md: 230 }}
        />
      </Link>

      <Div spacingTop={3} flexDirection="column" alignItems="center">
        <LinkWrapper {...linkWrapperProps}>
          <Div alignItems="center">
            {icon}
            <Text variant="label1" color={primary} spacingStart={1}>
              {label}
            </Text>
          </Div>
        </LinkWrapper>

        <Text
          variant="label2"
          spacingTop={0.5}
          color={foregroundSecondary}
          css={{ whiteSpace: 'pre-line' }}
        >
          {headline}
        </Text>

        <Text
          variant="legal"
          spacingTop={0.5}
          color={foregroundSecondary}
          css={{ whiteSpace: 'pre-line', fontStyle: 'italic' }}
        >
          {subtext}
        </Text>
      </Div>
    </Div>
  )
}
