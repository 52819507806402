import { notifyError } from '../../utils/logs'

const YOUTUBE_EMBED_URL = 'https://www.youtube.com/embed'
const YOUTUBE_EMBED_REGEX = /(youtube.com\/embed\/)([a-zA-Z0-9_-]{11})($|\?.*)/
const YOUTUBE_REGEX = /(youtube.com\/watch\?v=)([a-zA-Z0-9_-]{11})($|\?.*)/
const WISTIA_REGEX = /(wistia.net\/embed\/iframe\/)([a-zA-Z0-9_-]{10})/

export const isYoutube = (url: string): boolean => /youtube.com/.test(url)
export const isWistia = (url: string): boolean => /wistia.net/.test(url)
export const isVimeo = (url: string): boolean => /vimeo.com/.test(url)

/**
 * Transforms `https://www.youtube.com/watch?v=*` into `https://www.youtube.com/embed/*`
 * @param url
 */
export const standardizeYoutubeUrl = (url: string) => {
  const youtubeIdMatch = url.match(YOUTUBE_REGEX)

  if (youtubeIdMatch) {
    const id = youtubeIdMatch[2]
    return `${YOUTUBE_EMBED_URL}/${id}`
  }

  return url
}

export const getYoutubeVideoId = (url: string) => {
  const youtubeIdMatch = url.match(YOUTUBE_EMBED_REGEX)
  const youtubeId = youtubeIdMatch && youtubeIdMatch[2]
  return youtubeId
}

export const getYoutubeThumbnail = (url: string): string => {
  const youtubeId = getYoutubeVideoId(url)

  return !youtubeId
    ? ''
    : `https://i.ytimg.com/vi/${youtubeId}/maxresdefault.jpg`
}

export const getWistiaThumbnail = (
  url: string,
): Promise<string | undefined> | undefined => {
  const wistiaIdMatch = url.match(WISTIA_REGEX)
  const wistiaId = wistiaIdMatch && wistiaIdMatch[2]

  if (!wistiaId) return

  try {
    return fetch(
      `https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/${wistiaId}?embedType=async_popover&videoWidth=900&popoverWidth=640&popoverHeight=350`,
    )
      .then((res) => res.json())
      .then((data = {}) => data?.thumbnail_url)
  } catch (error) {
    notifyError(
      'Could not extract Wistia video ID from source URL',
      (error as Error).message,
    )
    return
  }
}
