import { Fragment } from 'react'

import { Divider } from '@cbhq/cds-web/layout'

import { HighlightBlockQuoteAuthor } from './HighlightBlockQuoteAuthor'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Link } from '../../ui/Link'
import { Text } from '../../ui/Text'

export type HighlightBlockQuoteProps = {
  authorPhotoUrl?: string
  authorName: string
  authorTitle?: string
  quote: string
  style?: ComponentsThemeStyle
  hasSpacingTop?: boolean
  anchor?: string
  link?: string
  linkLabel?: string
  isFullWidth?: boolean
}

export const HighlightBlockQuote = ({
  quote,
  style = 'default',
  authorPhotoUrl,
  authorName,
  authorTitle,
  hasSpacingTop,
  anchor,
  link,
  linkLabel,
  isFullWidth = false,
}: HighlightBlockQuoteProps) => {
  if (!quote) return null

  const { foreground, foregroundSecondary, background } =
    componentsThemeMap[style]

  const quoteColor =
    style === 'dark' || style === 'primary' || style === 'darkwash'
      ? foreground
      : foregroundSecondary

  const divider = style === 'default' && <Divider />

  const LinkContainer = link && linkLabel ? Link : Fragment
  const linkProps = link && linkLabel ? { href: link } : {}

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightBlockQuote">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal
          background={background}
          fullViewportWidth={isFullWidth}
          anchor={anchor}
        >
          {divider}

          <LinkContainer {...linkProps}>
            <Div
              flexDirection="column"
              spacingVertical={{
                sm: 8,
                md: 9,
                lg: 10,
              }}
              gap={{ sm: 3, md: 5 }}
            >
              <Text
                as="p"
                variant={{ sm: 'body', md: 'display2' }}
                color={quoteColor}
              >
                &quot;{quote}&quot;
              </Text>

              <HighlightBlockQuoteAuthor
                color={foreground}
                secondaryColor={foregroundSecondary}
                photoUrl={authorPhotoUrl}
                name={authorName}
                title={authorTitle}
                linkLabel={linkLabel}
              />
            </Div>
          </LinkContainer>

          {divider}
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
