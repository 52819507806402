import type { Document } from '@contentful/rich-text-types'
import type { Asset, Entry } from 'contentful'

import { HighlightCardsCarousel as HighlightCardsCarouselComponent } from '@cbhq/cdx-components'
import type {
  HighlightCarouselCardProps,
  ComponentsThemeStyle,
  AutoplayInterval,
} from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { getEntryProps } from '../../utils/getEntryProps'

export const HIGHLIGHT_CARDS_CAROUSEL_MODEL_ID = 'cdxHighlightCardsCarousel'

export type HighlightCardsCarouselFields = {
  autoplayInterval?: AutoplayInterval
  autoplayLoop?: boolean
  cards?: Entry<HighlightCardsCarouselCardFields>[]
  style?: ComponentsThemeStyle
  anchor?: string
  hasSpacingTop?: boolean
}

export type HighlightCardsCarouselCardFields = {
  media?: Asset
  headline?: string
  description?: Document
  ctaButton?: Entry<CDXGlobalNavLinkFields>
}

export const HighlightCardsCarousel = ({
  autoplayInterval,
  autoplayLoop,
  cards: cardEntries = [],
  style,
  anchor,
  hasSpacingTop = false,
}: HighlightCardsCarouselFields) => {
  const cards: HighlightCarouselCardProps[] = cardEntries.map((card) => {
    const { headline, description, media, ctaButton } = getEntryProps(card)
    const { url, label } = getEntryProps(ctaButton)

    return {
      headline,
      description,
      ctaLabel: label,
      ctaUrl: url,
      mediaUrl: media?.fields.file.url,
      mediaAlt: media?.fields.title,
    }
  })

  return (
    <HighlightCardsCarouselComponent
      autoplayInterval={autoplayInterval}
      autoplayLoop={autoplayLoop}
      hasSpacingTop={hasSpacingTop}
      style={style}
      anchor={anchor}
      cards={cards}
    />
  )
}
