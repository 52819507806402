import { Icon } from '@cbhq/cds-web/icons/Icon'

import { Div } from '../../ui/Div'
import { Text } from '../../ui/Text'
import type { TextVariant } from '../../ui/Text'

type ArrowTextProps = {
  children: React.ReactNode
  hideIcon?: boolean
  variant?: TextVariant
}

export const ArrowText = ({
  children,
  variant = 'label2',
  hideIcon,
}: ArrowTextProps) => (
  <Div
    spacingTop={3}
    alignItems="center"
    css={{ cursor: 'pointer', width: 'fit-content' }}
  >
    <Text variant={variant} as="span" color="primary">
      {children}
    </Text>
    {!hideIcon && <Icon spacingStart={1} name="forwardArrow" size="xs" />}
  </Div>
)
