import type { Document } from '@contentful/rich-text-types'

import { componentsThemeMap } from '../../componentsThemeMap'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { CarouselSlide } from '../../ui/Carousel'
import { Div } from '../../ui/Div'
import { LinkButton } from '../../ui/Link'
import { Media } from '../../ui/media'
import { RichText } from '../../ui/RichText'
import { Text } from '../../ui/Text'

export type HighlightCarouselCardProps = {
  mediaUrl?: string
  mediaAlt?: string
  headline?: string
  description?: Document
  ctaLabel?: string
  ctaUrl?: string
}

type InnerCarouselCardProps = {
  style?: ComponentsThemeStyle
}

export const CAROUSEL_CARD_IMAGE_HEIGHTS = {
  sm: 178,
  md: 306,
  lg: 480,
}

export const HighlightCardsCarouselCard = ({
  mediaUrl,
  mediaAlt = 'Carousel Slide Image',
  headline,
  description,
  ctaLabel,
  ctaUrl: ctaUrl,

  style = 'default',
}: HighlightCarouselCardProps & InnerCarouselCardProps) => {
  const { foreground, foregroundSecondary, background } =
    componentsThemeMap[style]

  return (
    <CarouselSlide css={{ background }}>
      <SectionComponentLayout hasSpacingHorizontal background={background}>
        <Div flexDirection="column" width="100%">
          <Media
            mediaType="image"
            src={mediaUrl}
            alt={mediaAlt}
            imageFit="cover"
            height={CAROUSEL_CARD_IMAGE_HEIGHTS}
            loading="eager"
            priority
          />

          <Div
            flexDirection="column"
            alignItems="center"
            css={{ textAlign: 'center' }}
            spacingTop={{ sm: 7, lg: 11 }}
          >
            <Text
              variant={{ sm: 'title2', lg: 'display2' }}
              as="h1"
              color={foreground}
            >
              {headline}
            </Text>

            {description && (
              <RichText content={description} color={foregroundSecondary} />
            )}

            {ctaLabel && ctaUrl && (
              <Div spacingTop={{ sm: 3, md: 6 }}>
                <LinkButton
                  href={ctaUrl}
                  data-qa={ANALYTICS_KEY.CTAButtonsFirst}
                  variant={background === 'primary' ? 'secondary' : 'primary'}
                >
                  {ctaLabel}
                </LinkButton>
              </Div>
            )}
          </Div>
        </Div>
      </SectionComponentLayout>
    </CarouselSlide>
  )
}
