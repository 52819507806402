import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'

export type HighlightTextHeadlineProps = {
  headline: string
  subhead?: string
  anchor?: string
  hasSpacingTop?: boolean
  hasSpacingVertical?: boolean
  style?: ComponentsThemeStyle
}

export const HighlightTextHeadline = ({
  headline,
  subhead,
  anchor,
  hasSpacingTop,
  hasSpacingVertical,
  style = 'default',
}: HighlightTextHeadlineProps) => {
  const { background, foreground, foregroundSecondary } =
    componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightTextHeadline">
        <SectionComponentLayout
          as="section"
          anchor={anchor}
          hasSpacingHorizontal
          background={background}
          hasSpacingTop={hasSpacingTop}
          hasNonDefaultBackgroundSpacingVertical
          hasSpacingVertical={hasSpacingVertical}
        >
          <Div
            flexDirection="column"
            css={{
              whiteSpace: 'break-spaces',
            }}
          >
            <Text as="h1" variant="display1" color={foreground}>
              {headline}
            </Text>
            {subhead && (
              <Text
                as="p"
                variant="title4"
                spacingTop={2}
                color={foregroundSecondary}
              >
                {subhead}
              </Text>
            )}
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
