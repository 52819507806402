import type { Document } from '@contentful/rich-text-types'
import styled from 'styled-components'

import type { IconName } from '@cbhq/cds-common'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { colorToVariable, componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { TwoColumnLayout } from '../../layout/TwoColumnLayout'
import type { CTAButtonsProps } from '../../ui/CTAButtons'
import { CTAButtons } from '../../ui/CTAButtons'
import { Div } from '../../ui/Div'
import { createListRichTextOptions } from '../../ui/RichText'
import { RichText } from '../../ui/RichText/RichText'
import { Text } from '../../ui/Text'

export type HighlightTextImageContentProps = CTAButtonsProps & {
  eyebrow?: string
  headline: string
  subhead?: string
  list?: Document
  legalText?: string
  listStyle?: IconName
}

type InnerProps = {
  alignContent: 'two-columns' | 'start'
  style: ComponentsThemeStyle
}

export const HighlightTextImageContent = ({
  alignContent,
  eyebrow,
  headline,
  subhead,
  list,
  listStyle,
  ctaButtonLabel,
  ctaButtonUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,
  legalText,

  style,
}: HighlightTextImageContentProps & InnerProps) => {
  const isTwoColumns = alignContent === 'two-columns'

  const { foreground, foregroundSecondary, background, foregroundInlineLink } =
    componentsThemeMap[style]

  return (
    <SectionComponentLayout
      hasSpacingHorizontal
      hasSpacingVertical
      flexDirection="row"
      height="100%"
      background="transparent"
    >
      <Div alignItems="center">
        <TwoColumnLayout
          mainContentPosition={isTwoColumns ? 'left' : 'top'}
          wrapOnTablet
          mainContent={
            <Div flexDirection="column">
              {eyebrow && (
                <Text variant="label1" as="p" color={foregroundSecondary}>
                  {eyebrow}
                </Text>
              )}

              <Text
                variant={{ sm: 'title2', lg: 'display2' }}
                as="h2"
                color={foreground}
                spacingEnd={isTwoColumns ? { sm: 0, lg: 2 } : 0}
              >
                {headline}
              </Text>
            </Div>
          }
          secondaryContent={
            <Div flexDirection="column" gap={3}>
              {subhead && (
                <Text
                  variant="body"
                  color={foregroundSecondary}
                  as="p"
                  spacingTop={isTwoColumns ? { sm: 2, lg: 0 } : 2}
                >
                  <BreakSpaces>{subhead}</BreakSpaces>
                </Text>
              )}

              {list && (
                <Div
                  css={{
                    display: 'contents',
                    p: {
                      color: colorToVariable(foregroundSecondary),
                    },
                  }}
                >
                  <RichText
                    content={list}
                    richTextOptions={createListRichTextOptions(listStyle)}
                    color={foregroundSecondary}
                    secondaryColor={foregroundSecondary}
                    foregroundInlineLink={foregroundInlineLink}
                  />
                </Div>
              )}

              {(ctaButtonLabel || secondaryButtonLabel) && (
                <CTAButtons
                  ctaButtonLabel={ctaButtonLabel}
                  ctaButtonUrl={ctaButtonUrl}
                  secondaryButtonLabel={secondaryButtonLabel}
                  secondaryButtonUrl={secondaryButtonUrl}
                  contentBackground={background}
                />
              )}

              {legalText && (
                <Text
                  variant="legal"
                  as="p"
                  color={foregroundSecondary}
                  spacingTop={{ sm: 3, lg: 5 }}
                >
                  {legalText}
                </Text>
              )}
            </Div>
          }
        />
      </Div>
    </SectionComponentLayout>
  )
}

const BreakSpaces = styled.span`
  white-space: break-spaces;
`
