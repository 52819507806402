import styled from 'styled-components'

import type { PaletteForeground } from '@cbhq/cds-common'
import { Icon } from '@cbhq/cds-web/icons/Icon'

import { useCarouselContext } from './useCarouselContext'
import { media } from '../../breakpoints'
import { ScreenReaderOnly } from '../ScreenReaderOnly'

export const CAROUSEL_BUTTON_SIZE = 32

type CarouselButtonProps = {
  className?: string
  color?: 'currentColor' | PaletteForeground
}

export const CarouselButtons = ({ color, ...props }: CarouselButtonProps) => {
  const { activeSlideIndex, numberOfSlides, hideButtonsOnEdges, paginate } =
    useCarouselContext()

  return (
    <>
      {(!hideButtonsOnEdges || activeSlideIndex !== 0) && (
        <LeftButton onClick={() => paginate(-1)} {...props}>
          <Icon size="l" name="caretLeft" color={color} />
          <ScreenReaderOnly>Previous slide</ScreenReaderOnly>
        </LeftButton>
      )}
      {(!hideButtonsOnEdges || activeSlideIndex !== numberOfSlides - 1) && (
        <RightButton onClick={() => paginate(1)} {...props}>
          <Icon size="l" name="caretRight" color={color} />
          <ScreenReaderOnly>Next slide</ScreenReaderOnly>
        </RightButton>
      )}
    </>
  )
}

const CarouselButton = styled.button`
  position: absolute;
  padding-left: 0;
  padding-right: 0;

  top: 0;
  bottom: 0;

  margin-top: auto;
  margin-bottom: auto;
  background-color: transparent;
  z-index: 1;
  cursor: pointer;
  width: ${CAROUSEL_BUTTON_SIZE}px;
  height: ${CAROUSEL_BUTTON_SIZE}px;
`

const LeftButton = styled(CarouselButton)`
  left: 0;

  ${media.md} {
    left: 16px;
  }

  ${media.lg} {
    left: 40px;
  }
`

const RightButton = styled(CarouselButton)`
  right: 0;

  ${media.md} {
    right: 16px;
  }

  ${media.lg} {
    right: 40px;
  }
`
