import { useState, useEffect, useRef } from 'react'

import type { Entry } from 'contentful'
import useSWRInfinite from 'swr/infinite'

import { fetchArticlesWithTag } from '@cbhq/cdx-contentful'
import type {
  CDXGlobalTagFields,
  ContentfulQueryOptions,
} from '@cbhq/cdx-contentful'

export const LANDING_PAGE_SIZE = 20

export const getLandingPaginationInfiniteScrollKey =
  (tags?: Entry<CDXGlobalTagFields>[], order: string = 'asc') =>
  (pageIndex: number = 0) =>
    [
      'landing-pagination',
      tags?.map((tag) => tag?.sys.id).join('-') || 'no-tag',
      order,
      pageIndex,
    ]

const fetcher =
  (
    contentfulOptions: ContentfulQueryOptions,
    productTag: string,
    tags?: Entry<CDXGlobalTagFields>[],
  ) =>
  async (args: any[]) => {
    const [, , order, page] = args

    const data = await fetchArticlesWithTag(
      productTag,
      tags,
      contentfulOptions,
      LANDING_PAGE_SIZE,
      +page * LANDING_PAGE_SIZE,
      order,
    )

    return data
  }

export const usePagination = ({
  productTag,
  contentfulOptions,
  tags,
  page = 0,
  order,
}: {
  productTag: string
  contentfulOptions: ContentfulQueryOptions
  tags?: Entry<CDXGlobalTagFields>[]
  page?: number
  order?: string
}) => {
  const [canLoadMore, setCanLoadMore] = useState(false)
  const wasValidating = useRef<boolean>(false)

  const { data, error, isValidating, size, setSize } = useSWRInfinite(
    getLandingPaginationInfiniteScrollKey(tags, order),
    fetcher(contentfulOptions, productTag, tags),
  )

  useEffect(() => {
    if (page + 1 !== size) setSize(page + 1)
  }, [setSize, page, size])

  useEffect(() => {
    if (wasValidating.current) {
      const totalArticles = data?.[0]?.total || 0

      setCanLoadMore(size * LANDING_PAGE_SIZE < totalArticles)
    }

    wasValidating.current = isValidating
  }, [data, isValidating, size])

  return {
    articles: data && data.flatMap(({ items }) => items),
    articlesCount: data && data[0]?.total,
    isLoading: isValidating,
    isError: error,
    canLoadMore,
    isLoadingInitialData: !data,
  }
}
