import type { Asset, Entry } from 'contentful'

import { postSubscribe } from '@cbhq/cdx-api'
import type { EmailApiProductId } from '@cbhq/cdx-api'
import type {
  ActionBannerLayouts,
  FormSettingsFields,
} from '@cbhq/cdx-components'
import {
  ActionBanner as ActionBannerComponent,
  useLogger,
  useDeploymentTargetContext,
} from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { mapEntryLink } from '../../globals/navlink'
import type { ContentfulComponentsStyleOptions } from '../contentToPropsMap'
import { CONTENTFUL_COMPONENTS_STYLE_MAP } from '../contentToPropsMap'

export const ACTION_BANNER_CONTENT_MODEL_ID = 'cdxActionBannerFull'

export type LayoutPosition = '50-50-left' | '50-50-right' | 'Full'
export type ContentPosition =
  | 'Text left'
  | 'Text right'
  | 'Text top-down'
  | 'Text bottom-up'

type CMSOptions = {
  productId: EmailApiProductId
}
export type ActionBannerFields = {
  media?: Asset
  headline: string
  subhead?: string
  options: CMSOptions
  style: ContentfulComponentsStyleOptions
  layout: LayoutPosition
  anchor?: string
  hasSpacingTop?: boolean
  type: 'email' | 'links'

  link?: Entry<CDXGlobalNavLinkFields>
  ctaButtonLabel?: string // TODO: deprecate, and update secondy button to also be a link
  ctaButtonUrl?: string
  secondaryButtonLabel?: string
  secondaryButtonUrl?: string

  productId?: EmailApiProductId
  successMessage?: string
  placeholder?: string
  submitButtonLabel?: string
  ctaType?: 'links' | 'form'
  formSettings?: Entry<FormSettingsFields>
}

type InnerProps = { hasSpacingVertical?: boolean }

export const positionMap: {
  [key in LayoutPosition]: ActionBannerLayouts
} = {
  '50-50-left': 'left',
  '50-50-right': 'right',
  Full: 'stacked',
}

export const ActionBanner = ({
  layout: layoutProp,
  style,
  media,
  headline: title,
  subhead: body,
  anchor,
  hasSpacingTop = true,
  options,

  ctaButtonLabel,
  ctaButtonUrl,
  secondaryButtonLabel,
  secondaryButtonUrl,
  link,

  ctaType,
  formSettings,

  hasSpacingVertical,
}: ActionBannerFields & InnerProps) => {
  const contentStyle = CONTENTFUL_COMPONENTS_STYLE_MAP[style]
  const layout = positionMap[layoutProp]
  const {
    submitButtonLabel,
    productId,
    placeholder,
    successMessage,
    formType,
  } = formSettings?.fields || {}

  const { notifyError } = useLogger()
  const { deploymentTargetName } = useDeploymentTargetContext()

  const { country, localeCode, currency } = {
    country: 'US',
    localeCode: 'en',
    currency: 'USD',
  }

  const onSubmit = async (email: string) => {
    if (!productId && !options.productId) return false

    const result = await postSubscribe({
      email,
      countryCode: country,
      locale: localeCode,
      currency,
      productId: productId || options.productId,
      isProd: deploymentTargetName === 'production',
      onError: notifyError as (e: string | Error) => void,
    })

    return result
  }

  const linkProps = link && mapEntryLink(link)

  if (ctaType === 'form')
    return (
      <ActionBannerComponent
        style={contentStyle}
        layout={layout}
        mediaUrl={media?.fields.file.url}
        mediaAlt={media?.fields.title}
        headline={title}
        body={body}
        placeholder={placeholder || 'Enter your email'} // TODO: Use microcopies as fallback values
        successMessage={successMessage || 'Thanks for subscribing!'}
        submitButtonLabel={submitButtonLabel || 'Submit'}
        onSubmit={onSubmit}
        ctaType="form"
        hasSpacingTop={hasSpacingTop}
        anchor={anchor}
        hasSpacingVertical={hasSpacingVertical}
        formType={formType}
      />
    )

  return (
    <ActionBannerComponent
      style={contentStyle}
      layout={layout}
      mediaUrl={media?.fields.file.url}
      mediaAlt={media?.fields.title}
      headline={title}
      body={body}
      ctaType="links"
      ctaButtonLabel={linkProps?.label || ctaButtonLabel}
      ctaButtonUrl={linkProps?.url || ctaButtonUrl}
      secondaryButtonLabel={secondaryButtonLabel}
      secondaryButtonUrl={secondaryButtonUrl}
      hasSpacingTop={hasSpacingTop}
      hasSpacingVertical={hasSpacingVertical}
    />
  )
}
