import type { HighlightAssetProps } from './HighlightAssetList'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { Image } from '../../ui/Image'
import { Link } from '../../ui/Link'
import { Text } from '../../ui/Text'

type InnerProps = {
  style: ComponentsThemeStyle
}

const AssetContent = ({
  logoSrc,
  name,
  style,
}: HighlightAssetProps & InnerProps) => {
  const { background, foreground, borderLine } = componentsThemeMap[style]

  return (
    <Div
      width="max-content"
      height={48}
      css={{ 'border-radius': 100 }}
      spacingHorizontal={1.75}
      spacingVertical={1.5}
      background={background}
      elevation={1}
      borderColor={borderLine}
    >
      <Image
        width={24}
        alt={`${name} icon`}
        src={logoSrc || ''}
        imageFit="contain"
      />
      <Text variant="body" as="span" color={foreground} spacingStart={0.5}>
        {name}
      </Text>
    </Div>
  )
}

export const HighlightAsset = ({
  link,
  ...props
}: HighlightAssetProps & InnerProps) => (
  <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.HighlightAsset}>
    {link ? (
      <Link href={link || ''}>
        <AssetContent {...props} />
      </Link>
    ) : (
      <AssetContent {...props} />
    )}
  </AnalyticsProvider>
)
