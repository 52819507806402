import type { ReactNode } from 'react'
import React from 'react'

import { PortalProvider } from '@cbhq/cds-web/overlays/PortalProvider'

const Provider = PortalProvider as any
export type ModalProviderProps = {
  children: ReactNode
}
export const ModalProvider = ({ children }: any) => {
  return <Provider>{children}</Provider>
}
