import styled from 'styled-components'

import type { DivProps } from '../Div'
import { Div } from '../Div'

export type CarouselSlideProps = {
  slideIndex?: number
} & DivProps

export const CarouselSlide = ({
  slideIndex = 0,
  ...props
}: CarouselSlideProps) => (
  <Container draggable="true">
    <Div height="100%" width="100%" {...props} />
  </Container>
)

const Container = styled.div`
  display: contents;

  img {
    pointer-events: none;
  }
`
