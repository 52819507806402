import { ActionBannerContent } from './ActionBannerContent'
import type { ActionContentProps } from './ActionBannerContent'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { TwoColumnLayout } from '../../layout/TwoColumnLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Image } from '../../ui/Image'

export type ActionBannerLayouts = 'left' | 'right' | 'stacked'

export type ActionBannerProps = ActionContentProps & {
  mediaUrl?: string
  mediaAlt?: string
  style?: ComponentsThemeStyle
  layout: ActionBannerLayouts
  hasSpacingTop?: boolean
  anchor?: string
  hasSpacingVertical?: boolean
}

const DEFAULT_ALT_TEXT = 'Action Banner Image'

export const ActionBanner = ({
  mediaUrl,
  mediaAlt,
  layout,
  style = 'default',
  hasSpacingTop = false,
  hasSpacingVertical = false,
  anchor,
  ...contentProps
}: ActionBannerProps) => {
  const { background } = componentsThemeMap[style]

  const isLayoutStacked = layout === 'stacked'

  const imageContent = mediaUrl && (
    <Image
      alt={mediaAlt || DEFAULT_ALT_TEXT}
      src={mediaUrl}
      maxHeight={
        isLayoutStacked
          ? { sm: 200, md: 260, lg: 375 }
          : { sm: 375, md: 'unset', lg: 'unset' }
      }
      aspectRatio={!isLayoutStacked ? { md: 1 } : undefined}
    />
  )

  const shouldRenderLayoutStacked = isLayoutStacked || !imageContent

  const textContent = (
    <ActionBannerContent
      alignContent={shouldRenderLayoutStacked ? 'two-columns' : 'start'}
      style={style}
      {...contentProps}
    />
  )

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="ActionBanner">
        <SectionComponentLayout
          background={background}
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal={false}
          anchor={anchor}
          hasSpacingVertical={hasSpacingVertical}
        >
          <TwoColumnLayout
            mainContentPosition={shouldRenderLayoutStacked ? 'top' : layout}
            mainContent={textContent}
            mainContentPercentage={50}
            secondaryContent={imageContent}
            secondaryContentPercentage={50}
          />
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
