import { useState, useEffect } from 'react'

import type { PaletteAlias } from '@cbhq/cds-web'
import { palette } from '@cbhq/cds-web/tokens'

import { Div } from '../../ui/Div'
import { Text } from '../../ui/Text'

type Tab = {
  label?: string
  id: string
}

type TabsProps = {
  pillTabs?: Tab[]
  onTabChange?: (id?: string) => void
  color?: PaletteAlias
}

export const Tabs = ({
  pillTabs = [],
  onTabChange,
  color = 'foreground',
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState(pillTabs[0])

  useEffect(
    () => onTabChange && onTabChange(activeTab.id),
    [onTabChange, activeTab.id],
  )

  const onClick = (tab: Tab) => {
    if (!!tab) setActiveTab(tab)
  }

  return (
    <Div gap={4} overflowX={{ sm: 'auto', md: 'hidden' }}>
      {pillTabs.map((tab) => {
        const isActive = activeTab.id === tab.id

        return (
          <Div key={tab.id} onClick={() => onClick(tab)}>
            <Text
              as="p"
              variant="headline"
              spacingVertical={2}
              spacingHorizontal={0}
              color={isActive ? 'primary' : color}
              css={{
                cursor: 'pointer',
                borderBottom: isActive
                  ? `2px solid ${palette.primary}`
                  : 'none',
              }}
            >
              {tab.label || tab.id}
            </Text>
          </Div>
        )
      })}
    </Div>
  )
}
