import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'
import { VideoPlayer } from '../../ui/VideoPlayer'

export type HighlightVideoProps = {
  mediaUrl: string
  thumbnailUrl?: string
  thumbnailAlt?: string
  caption?: string
  hasSpacingTop?: boolean
  hasSpacingAround?: boolean
  style?: ComponentsThemeStyle
  anchor?: string
  hasLightbox?: boolean
}

const DEFAULT_ALT_TEXT = 'Video highlight'

export const HighlightVideo = ({
  mediaUrl,
  thumbnailUrl,
  thumbnailAlt = DEFAULT_ALT_TEXT,
  caption,
  hasSpacingTop,
  hasSpacingAround = false,
  style = 'default',
  anchor,
  hasLightbox,
}: HighlightVideoProps) => {
  const { background, foregroundSecondary } = componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightVideo">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal={hasSpacingAround}
          hasNonDefaultBackgroundSpacingVertical
          anchor={anchor}
          background={background}
        >
          <Div flexDirection="column">
            <VideoPlayer
              src={mediaUrl}
              thumbnailUrl={thumbnailUrl}
              thumbnailAlt={thumbnailAlt}
              hasLightbox={hasLightbox}
            />

            {caption && (
              <Text
                variant="caption"
                as="p"
                color={foregroundSecondary}
                spacingTop={3}
              >
                {caption}
              </Text>
            )}
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
