import { filterLinkFields } from './filterLinkFields'
import type { ComposePageEntry } from '../globals/composePage'
import type { EditorialTemplateFields } from '../templates/Editorial'

/**
 * Filters Editorial's Realted Stories's fields and sorts out unnecessary data
 */

export const filterRelatedStoryFields = (
  composePage: ComposePageEntry<EditorialTemplateFields>,
) => ({
  ...composePage,
  fields: {
    ...composePage.fields,
    content: {
      ...composePage.fields.content,
      fields: {
        ...composePage.fields.content.fields,
        relatedStories: composePage.fields.content.fields?.relatedStories?.map(
          (storyArticle) => ({
            fields: {
              slug: storyArticle.fields.slug,
              content: {
                ...storyArticle.fields.content,
                fields: {
                  headline: storyArticle.fields.content.fields.headline ?? null,
                  tags: (storyArticle.fields.content.fields.tags || []).map(
                    (tag) => ({
                      ...tag,
                      fields: {
                        ...tag.fields,
                        link: tag.fields.link
                          ? filterLinkFields(tag.fields.link)
                          : null,
                      },
                    }),
                  ),
                },
              },
            },
          }),
        ),
      },
    },
  },
})
