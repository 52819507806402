import { useMemo, useState } from 'react'

import type { Document } from '@contentful/rich-text-types'
import type { Property } from 'csstype'

import type { PaletteForeground } from '@cbhq/cds-web'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap, colorToVariable } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { RichText, articleRichTextOptions } from '../../ui/RichText'
import { Tabs } from '../../ui/Tabs'
import { Text } from '../../ui/Text'

export type HighlightTableProps<T = any> = {
  headline?: string
  subhead?: Document
  legalDisclaimer?: string
  data: T[]
  columns: ColumnDefinition<T>[]
  tabs?: TabDefinition<T>[]
  onRowClick?(row: T): void
  tableStyle?: 'alternate' | 'white'
  hasSpacingTop?: boolean
  style?: ComponentsThemeStyle
  anchor?: string
}

export type ColumnDefinitionWidth = 'fr' | 'px' | '%'
export type ColumnDefinition<T> = {
  label: string
  dataKey?: keyof T
  subDataKey?: keyof T
  size?: `${string}${ColumnDefinitionWidth}` | 'auto'
  hideOnMobile?: boolean
  color?: PaletteForeground
  verticalAlignment?: 'center' | 'top' | 'bottom'
}

export type TabDefinition<T> = {
  label?: string
  dataKey: keyof T | undefined
  value?: any
  maxRows?: number
  order?: 'asc' | 'desc'
  orderDataKey?: keyof T
}

type InnerProps = {
  hasSpacingHorizontal?: boolean
  fullViewportWidth?: boolean
}

export function HighlightTable<T>({
  headline,
  subhead,
  legalDisclaimer,
  data,
  columns,
  tabs = [],
  onRowClick: onRowClickProp,
  tableStyle = 'white',
  hasSpacingTop,
  anchor,
  style = 'default',
  hasSpacingHorizontal = true,
  fullViewportWidth = true,
}: HighlightTableProps<T> & InnerProps) {
  const [currentTab, setCurrentTab] = useState<number>(0)

  const currentTabValue = tabs && tabs[currentTab]

  const rows = useMemo(() => {
    if (data.length === 0) return data

    const { dataKey, value, order, orderDataKey, maxRows } =
      currentTabValue || {}

    let filteredData = dataKey
      ? data.filter((entry) => entry[dataKey] === value)
      : data

    if (order) {
      const orderingKey = orderDataKey ?? dataKey

      if (orderingKey) {
        const isAsc = order === 'asc'

        const sortFn =
          typeof data[0][orderingKey] === 'number'
            ? (a: any, b: any) =>
                Number(a[orderingKey]) - Number(b[orderingKey])
            : (a: any, b: any) => {
                const valueA = a[orderingKey] ?? ''
                const valueB = b[orderingKey] ?? ''

                return valueA.toString().localeCompare(valueB.toString())
              }

        const sortedData = [...filteredData].sort(sortFn)

        filteredData = isAsc ? sortedData : sortedData.reverse()
      }
    }

    if (maxRows) {
      filteredData = filteredData.slice(0, maxRows)
    }

    return filteredData
  }, [data, currentTabValue])

  const gridTemplateColumns = useMemo(() => {
    return {
      sm: columns
        .filter((column) => !column.hideOnMobile)
        .map(({ size }) => `${size ? `minMax(min-content, ${size})` : '1fr'}`)
        .join(' '),
      md: columns
        .map(({ size }) => `${size ? `minMax(min-content, ${size})` : '1fr'}`)
        .join(' '),
    }
  }, [columns])

  const rowsData = useMemo(() => {
    return rows.map((row) => {
      const columnsData = columns.map(
        ({
          label,
          dataKey,
          subDataKey,
          color,
          hideOnMobile,
          verticalAlignment,
        }) => ({
          label,
          data: dataKey && row[dataKey],
          subData: subDataKey && row[subDataKey],
          color,
          hideOnMobile,
          verticalAlignment,
        }),
      )

      return {
        onRowClick: onRowClickProp ? () => onRowClickProp(row) : undefined,
        columnsData,
      }
    })
  }, [rows, columns, onRowClickProp])

  const pillTabs = useMemo(() => {
    return tabs?.map(({ label }, index) => ({ id: index.toString(), label }))
  }, [tabs])

  const {
    foreground,
    foregroundSecondary,
    background,
    backgroundAlternate,
    backgroundHover,
  } = componentsThemeMap[style]

  const hasHeadingLabels = columns.some((c) => c.label)

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightTable">
        <SectionComponentLayout
          background={background}
          hasSpacingTop={hasSpacingTop}
          anchor={anchor}
          hasNonDefaultBackgroundSpacingVertical={fullViewportWidth}
          hasSpacingHorizontal={hasSpacingHorizontal}
          fullViewportWidth={fullViewportWidth}
        >
          {headline && (
            <Text
              variant={{ sm: 'title2', lg: 'display2' }}
              as="h2"
              color={foreground}
            >
              {headline}
            </Text>
          )}

          {subhead && (
            <Div flexDirection="column">
              <RichText
                content={subhead}
                color={foregroundSecondary}
                richTextOptions={articleRichTextOptions}
              />
            </Div>
          )}

          {tabs.length > 0 && (
            <Div spacingTop={{ sm: 3, lg: 6 }} spacingBottom={{ sm: 1, lg: 2 }}>
              <Tabs
                pillTabs={pillTabs}
                onTabChange={(id) => setCurrentTab(Number(id) || 0)}
                color={foreground}
              />
            </Div>
          )}

          <Div
            as="table"
            flex={1}
            display="grid"
            gridTemplateColumns={gridTemplateColumns}
            spacingTop={subhead || headline || tabs.length ? 3 : 0}
            overflow="auto"
          >
            {hasHeadingLabels && (
              <Div as="thead" display="contents">
                <Div as="tr" display="contents">
                  {columns.map(({ label = '', hideOnMobile }, index) => (
                    <Div
                      as="td"
                      key={index}
                      spacingHorizontal={{ sm: 1, md: 2 }}
                      spacingVertical={2}
                      background={backgroundAlternate}
                      css={{ borderBottom: '1px solid' }}
                      borderColor="lineHeavy"
                      display={{
                        sm: hideOnMobile ? 'none' : 'flex',
                        md: 'flex',
                      }}
                    >
                      <Text
                        variant="label1"
                        overflow="hidden"
                        color={foreground}
                      >
                        {label}
                      </Text>
                    </Div>
                  ))}
                </Div>
              </Div>
            )}

            <Div as="tbody" display="contents">
              {rowsData.map(({ onRowClick, columnsData }, index) => (
                <Div
                  as="tr"
                  display="contents"
                  key={`row-${index}`}
                  onClick={(e) => {
                    if (onRowClick) {
                      e.preventDefault()
                      onRowClick()
                    }
                  }}
                  css={{
                    ':hover': onRowClick && {
                      '> td': {
                        background: colorToVariable(
                          backgroundHover || 'primaryWash',
                        ),
                        cursor: 'pointer',
                      },
                    },
                  }}
                >
                  {columnsData.map(
                    (
                      {
                        label,
                        data,
                        subData,
                        color = foregroundSecondary,
                        hideOnMobile,
                        verticalAlignment = 'center',
                      },
                      columnIndex,
                    ) => {
                      return (
                        <Div
                          as="td"
                          key={`${label}-${columnIndex}`}
                          spacingHorizontal={{ sm: 1, md: 2 }}
                          spacingVertical={2}
                          background={
                            tableStyle === 'white'
                              ? background
                              : (index + (hasHeadingLabels ? 0 : 1)) % 2
                              ? backgroundAlternate
                              : background
                          }
                          alignItems={
                            VERTICAL_ALIGNMENT_PROP_MAP[verticalAlignment]
                          }
                          display={{
                            sm: hideOnMobile ? 'none' : 'flex',
                            md: 'flex',
                          }}
                        >
                          {!data ||
                          typeof data === 'string' ||
                          typeof data === 'number' ? (
                            <Div
                              flex={1}
                              gap={1}
                              flexWrap="wrap"
                              overflow="hidden"
                            >
                              {data && (
                                <Text variant="headline" color={color}>
                                  <>{data}</>
                                </Text>
                              )}
                              {subData && (
                                <Text variant="body" color={color}>
                                  <>{subData}</>
                                </Text>
                              )}
                            </Div>
                          ) : (
                            <>{data}</>
                          )}
                        </Div>
                      )
                    },
                  )}
                </Div>
              ))}
            </Div>
          </Div>

          {legalDisclaimer && (
            <Text
              spacingTop={5}
              variant="legal"
              color={foregroundSecondary}
              as="p"
              css={{ whiteSpace: 'break-spaces' }}
            >
              {legalDisclaimer}
            </Text>
          )}
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}

const VERTICAL_ALIGNMENT_PROP_MAP: { [key: string]: Property.AlignItems } = {
  top: 'baseline',
  bottom: 'end',
  center: 'center',
}
