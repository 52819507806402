import { useState } from 'react'

import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import { TextInput } from '@cbhq/cds-web/controls/TextInput'
import { Icon } from '@cbhq/cds-web/icons/Icon'
import { ThemeProvider as CDSThemeProvider } from '@cbhq/cds-web/system/ThemeProvider'
import type { ThemeProviderProps } from '@cbhq/cds-web/system/ThemeProvider'

import { componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { useGlobalMicrocopy } from '../../providers/MicrocopyProvider'
import type {
  EmailOnlyUserFormInnerProps,
  EmailOnlyUserFormProps,
  FormProps,
} from '../../ui/Form'
import { emailRegex } from '../../utils/getIsValidEmail'
import { Button } from '../Button'
import { Div } from '../Div'
import { Text } from '../Text'

export const EmailOnlyUserForm = ({
  successMessage,
  placeholder,
  submitButtonLabel,
  onSuccess,
  onSubmit,
  style,
  formType,
}: EmailOnlyUserFormProps & EmailOnlyUserFormInnerProps) => {
  const [showForm, setShowForm] = useState(true)
  const microcopies = useGlobalMicrocopy()

  if (formType === 'Signup') {
    // TODO: Switch on formType
  }

  if (showForm) {
    const onSuccessHandle = (email: string) => {
      setShowForm(false)

      if (onSuccess) onSuccess(email)
    }

    return (
      <Form
        placeholder={placeholder || microcopies.global.emailPlaceholder}
        submitButtonLabel={submitButtonLabel || microcopies.global.submit}
        onSuccess={onSuccessHandle}
        onSubmit={onSubmit}
        style={style}
      />
    )
  }

  return (
    <Div
      css={{ display: 'flex', alignItems: 'center' }}
      justifyContent={{ sm: 'center', md: 'flex-start' }}
    >
      <Icon name="circleCheckmark" color="positive" size="s" spacingEnd={2} />
      <Text variant="headline" as="p" color="positive">
        {successMessage || microcopies.global.subscribed}
      </Text>
    </Div>
  )
}

const CTAEmailFormValues = {
  email: 'email',
}

type CTAEmailFormValues = typeof CTAEmailFormValues

const CDSThemeProviderWithChildren =
  CDSThemeProvider as React.FunctionComponent<
    React.PropsWithChildren<ThemeProviderProps>
  >

const Form = ({
  placeholder,
  submitButtonLabel,
  onSuccess,
  onSubmit: onSubmitProp,
  style,
}: FormProps & EmailOnlyUserFormInnerProps) => {
  const { register, handleSubmit, getFieldState, formState } =
    useForm<CTAEmailFormValues>()

  const emailError = getFieldState('email', formState).error

  const onSubmit: SubmitHandler<CTAEmailFormValues> = async ({ email }) => {
    const success = await onSubmitProp(email)

    if (success) {
      if (onSuccess) onSuccess(email)
    }
  }

  const isDarkMode = style === 'dark' || style === 'darkwash'
  const { background } = componentsThemeMap[style]

  return (
    <Div
      flexDirection={{ sm: 'column', md: 'row' }}
      width={{ sm: '100%', md: 'auto' }}
      spacingTop={3}
    >
      <Div
        display="grid"
        alignItems="center"
        gridTemplateColumns={{ sm: '1fr', md: '1fr auto' }}
        width="100%"
        maxWidth={550}
        gap={3}
      >
        <CDSThemeProviderWithChildren spectrum={isDarkMode ? 'dark' : 'light'}>
          <TextInput
            label=""
            placeholder={placeholder}
            {...register('email', {
              required: true,
              pattern: emailRegex,
            })}
            type="email"
            variant={emailError && 'negative'}
          />
        </CDSThemeProviderWithChildren>

        <AnalyticsProvider analyticsPrefix={ANALYTICS_KEY.Submit}>
          <Button
            onPress={handleSubmit(onSubmit)}
            variant={background === 'primary' ? 'secondary' : 'primary'}
          >
            {submitButtonLabel || ''}
          </Button>
        </AnalyticsProvider>
      </Div>
    </Div>
  )
}
