import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { Text } from '../../ui/Text'

export type HighlightStatChipsProps = {
  anchor?: string
  subText?: string
  headline?: string
  style?: ComponentsThemeStyle
  children: React.ReactNode
  hasSpacingTop?: boolean
}

export const HighlightStatChips = ({
  headline,
  subText,
  children,
  style = 'default',
  anchor,
  hasSpacingTop,
}: HighlightStatChipsProps) => {
  const { background, foreground, foregroundSecondary } =
    componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightStatChips">
        <SectionComponentLayout
          anchor={anchor}
          hasSpacingHorizontal
          background={background}
          hasNonDefaultBackgroundSpacingVertical
          hasSpacingTop={hasSpacingTop}
        >
          <Div width="100%" flexDirection="column">
            <Div
              width="100%"
              gap={4}
              flexDirection={{ sm: 'column', lg: 'row' }}
            >
              {headline && (
                <Text as="h3" color={foreground} variant="display2" flex={1}>
                  {headline}
                </Text>
              )}
              {subText && (
                <Text
                  as="p"
                  flex={1}
                  variant="body"
                  color={foregroundSecondary}
                  css={{ whiteSpace: 'pre-line' }}
                >
                  {subText}
                </Text>
              )}
            </Div>
            <Div
              display="grid"
              spacingTop={8}
              gridTemplateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
              rowGap={{ sm: 3, lg: 7 }}
              columnGap={{ sm: 0, lg: 12, xl: 7 }}
            >
              {children}
            </Div>
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
