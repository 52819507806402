import { Div } from '../Div'
import { Image } from '../Image'

type MediaFields = {
  src: string
  altText: string
}

export const ImageFullWidth = ({
  src = '',
  altText = 'image',
}: MediaFields) => {
  if (!src) return null
  return (
    <Div
      css={{
        width: '100vw',
        maxWidth: '100vw',
        marginLeft: 'calc(50% - 50vw)',
        '> div': {
          height: 'auto',
        },
      }}
    >
      <Image
        src={src}
        alt={altText}
        width="100%"
        loading="lazy"
        maxHeight={478}
      />
    </Div>
  )
}
