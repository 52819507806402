import styled from 'styled-components'

import type { PaletteForeground } from '@cbhq/cds-common'

import type { HighlightCarouselCardProps } from './HighlightCardsCarouselCard'
import {
  CAROUSEL_CARD_IMAGE_HEIGHTS,
  HighlightCardsCarouselCard,
} from './HighlightCardsCarouselCard'
import { media } from '../../breakpoints'
import { componentsThemeMap } from '../../componentsThemeMap'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import {
  Carousel,
  CarouselButtons,
  CarouselIndicators,
  CAROUSEL_BUTTON_SIZE,
} from '../../ui/Carousel'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'

export type AutoplayInterval =
  | '0'
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'

export const iconStyleMap: {
  [key in ComponentsThemeStyle]: 'currentColor' | PaletteForeground
} = {
  default: 'currentColor',
  dark: 'primaryForeground',
  subtle: 'currentColor',
  wash: 'currentColor',
  darkwash: 'primaryForeground',
  primary: 'currentColor',
}

export type HighlightCardsCarouselProps = {
  autoplayInterval?: AutoplayInterval
  autoplayLoop?: boolean
  cards?: HighlightCarouselCardProps[]
  hasSpacingTop?: boolean
  anchor?: string
  style?: ComponentsThemeStyle
}

export const HighlightCardsCarousel = ({
  autoplayInterval = '0',
  autoplayLoop = false,
  cards = [],
  hasSpacingTop,
  anchor,
  style = 'default',
}: HighlightCardsCarouselProps) => {
  const { background, primary, borderLine } = componentsThemeMap[style]
  const isAutoplay = Number(autoplayInterval) > 0
  const autoIntervalSeconds = Number(autoplayInterval) * 1000

  const firstCard = cards[0]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HighlightCardsCarousel">
        <SectionComponentLayout
          hasSpacingTop={hasSpacingTop}
          hasSpacingHorizontal={false}
          background={background}
          anchor={anchor}
        >
          <Carousel
            autoplay={isAutoplay}
            autoplayInterval={autoIntervalSeconds}
            autoplayLoop={autoplayLoop}
            carouselIndicators={
              <SpacedCarouselIndicators
                color={primary}
                colorInactive={style === 'primary' ? 'foreground' : borderLine}
              />
            }
            carouselButtons={
              <SpacedCarouselButtons color={iconStyleMap[style]} />
            }
            isFixedHeight
          >
            {cards.map((card, index) => (
              <HighlightCardsCarouselCard key={index} style={style} {...card} />
            ))}
          </Carousel>

          <Div opacity={0} aria-disabled="true">
            <HighlightCardsCarouselCard style={style} {...firstCard} />
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}

const SpacedCarouselIndicators = styled(CarouselIndicators)`
  bottom: auto;
  top: ${CAROUSEL_CARD_IMAGE_HEIGHTS.sm + 32}px;

  ${media.md} {
    top: ${CAROUSEL_CARD_IMAGE_HEIGHTS.md + 32}px;
  }

  ${media.lg} {
    top: ${CAROUSEL_CARD_IMAGE_HEIGHTS.lg + 32}px;
  }
`

const SpacedCarouselButtons = styled(CarouselButtons)`
  display: none;

  ${media.md} {
    display: flex;
    margin-top: ${CAROUSEL_CARD_IMAGE_HEIGHTS.md / 2 -
    CAROUSEL_BUTTON_SIZE / 2}px;
  }

  ${media.lg} {
    display: flex;
    margin-top: ${CAROUSEL_CARD_IMAGE_HEIGHTS.lg / 2 -
    CAROUSEL_BUTTON_SIZE / 2}px;
  }
`
