import { filterLinkFields } from './filterLinkFields'
import type { ComposePageEntry } from '../globals/composePage'
import type { EditorialTemplateFields } from '../templates/Editorial'

/**
 * Filters Editorial's fields and sorts out unnecessary data
 */

export const filterArticleFields = (
  articles: ComposePageEntry<EditorialTemplateFields>[],
) =>
  articles?.map((article) => ({
    ...article,
    fields: {
      ...article.fields,
      content: {
        ...article.fields.content,
        fields: {
          headline: article.fields.content.fields.headline ?? null,
          subheadRichText:
            article.fields.content.fields.subheadRichText ?? null,
          excerpt: article.fields.content.fields.excerpt ?? null,
          image: article.fields.content.fields.image ?? null,
          readTime: article.fields.content.fields.readTime ?? null,
          tags: (article.fields.content.fields.tags || []).map((tag) => ({
            ...tag,
            fields: {
              ...tag.fields,
              link: tag.fields.link ? filterLinkFields(tag.fields.link) : null,
            },
          })),
        },
      },
    },
  }))
