import type { ReactElement } from 'react'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap } from '../../componentsThemeMap'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import type { Tab } from '../../ui/TabNavigation'
import { TabNavigation } from '../../ui/TabNavigation'
import { Text } from '../../ui/Text'

export type WayfindingTabCardsProps = {
  eyebrow?: string
  headline?: string
  anchor?: string
  tabs: Tab[]
  children: ReactElement[]
  style?: ComponentsThemeStyle
}

export const WayfindingTabCards = ({
  eyebrow,
  headline,
  children,
  tabs,
  anchor,
  style = 'default',
}: WayfindingTabCardsProps) => {
  const colors = componentsThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="WayfindingTabCards">
        <SectionComponentLayout
          hasSpacingTop
          hasSpacingHorizontal
          as="section"
          anchor={anchor}
          flexDirection="column"
          background={colors.background}
          hasNonDefaultBackgroundSpacingVertical
        >
          {eyebrow && (
            <Text variant="label1" color={colors.foreground}>
              {eyebrow}
            </Text>
          )}

          {headline && (
            <Text
              spacingTop={eyebrow ? 4 : 0}
              variant="display2"
              color={colors.foreground}
            >
              {headline}
            </Text>
          )}

          <TabNavigation startTabId="0" tabs={tabs} style={style}>
            {children}
          </TabNavigation>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
