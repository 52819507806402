import type {
  ComponentsThemeStyle,
  TwoColumnLayoutMainContentPosition,
  MediaType,
} from '@cbhq/cdx-components'

// TODO: refactor Contentful to not need those mappings

export type ContentfulComponentsStyleOptions =
  | 'Default'
  | 'Dark'
  | 'Wash'
  | 'Subtle'
  | 'Primary'
  | 'Dark Subtle'
export const CONTENTFUL_COMPONENTS_STYLE_MAP: {
  [key in ContentfulComponentsStyleOptions]: ComponentsThemeStyle
} = {
  Default: 'default',
  Dark: 'dark',
  Subtle: 'subtle',
  Wash: 'wash',
  'Dark Subtle': 'darkwash',
  Primary: 'primary',
}
export type ContentfulLayoutOptionss = 'Left' | 'Right' | 'Top' | 'Bottom'
export const LAYOUT_MAP: {
  [key in ContentfulLayoutOptionss]: TwoColumnLayoutMainContentPosition
} = {
  Top: 'top',
  Left: 'left',
  Right: 'right',
  Bottom: 'bottom',
}

export type ContentfulMediaTypeOptions = 'Image' | 'Lotti' | 'Video' | 'None'
export const MEDIA_TYPE_MAP: {
  [key in ContentfulMediaTypeOptions]: MediaType | 'none'
} = {
  Image: 'image',
  Lotti: 'lotti',
  Video: 'video',
  None: 'none',
}

export type ContentfulImageFitOptions = 'Cover' | 'Contain'
export const IMAGE_FIT_MAP: {
  [key in ContentfulImageFitOptions]: 'cover' | 'contain'
} = {
  Cover: 'cover',
  Contain: 'contain',
}
