import type { Asset, Entry } from 'contentful'

import {
  Wayfinding,
  WayfindingCardArticle,
  formatDateWithoutTimeZone,
} from '@cbhq/cdx-components'
import { getEntryProps } from '../../utils/getEntryProps'
import { mapEntryLink } from '../../globals/navlink'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'

export type WayfindingArticleCardListFields = {
  headline: string
  body?: string
  ctaButtonUrl?: string
  ctaButtonLabel?: string
  link?: Entry<CDXGlobalNavLinkFields>
  hasSpacingTop?: boolean
  style: 'default' | 'dark' | 'subtle'
  cards: Entry<CardArticlelistFields>[]
  anchor?: string
  ctaPosition?: 'top' | 'bottom'
  hideThumbnails?: boolean
  compact?: boolean
}

export const WAYFINDING_ARTICLE_CARD_LIST_CONTENT_MODEL_ID =
  'cdxWayfindingArticleCardList'

export type CardArticlelistFields = {
  headline: string
  body?: string
  url?: string
  link?: Entry<CDXGlobalNavLinkFields>
  thumbnail?: Asset
  date?: string
  readTime?: string
  tagLabel?: string
  tagHref?: string
}

export const WayfindingArticleCardList = ({
  headline,
  body,
  link,
  style,
  cards,
  hasSpacingTop,
  anchor,
  ctaPosition = 'top',
  hideThumbnails,
  compact = true,
}: WayfindingArticleCardListFields) => {
  const navLink = link && mapEntryLink(link)
  const navLinkLabel = link?.fields?.label

  return (
    <Wayfinding
      headline={headline}
      body={body}
      ctaButtonUrl={navLink?.url}
      ctaButtonLabel={navLinkLabel}
      columns={1}
      rows="all"
      style={style}
      hasSpacingTop={hasSpacingTop}
      anchor={anchor}
      compact={compact}
      ctaPosition={ctaPosition}
    >
      {cards?.map((entry, index) => {
        const {
          headline,
          body,
          url,
          thumbnail,
          date,
          readTime,
          tagLabel,
          tagHref,
        } = getEntryProps(entry)

        return (
          <WayfindingCardArticle
            key={index}
            mediaAlt={thumbnail?.fields?.title}
            mediaUrl={thumbnail?.fields?.file?.url}
            headline={headline || ''}
            body={body}
            href={url}
            mediaType={hideThumbnails ? 'none' : 'image'}
            datePublished={formatDateWithoutTimeZone(date)}
            readTime={readTime}
            tagLabel={tagLabel}
            tagHref={tagHref}
            style={style}
          />
        )
      })}
    </Wayfinding>
  )
}
