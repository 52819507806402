import type { Asset, Entry } from 'contentful'

import { HighlightBlockQuote as HighlightBlockQuoteComponent } from '@cbhq/cdx-components'

import type { CDXGlobalNavLinkFields } from '../../globals/navlink'
import { getEntryProps } from '../../utils/getEntryProps'
import type { ContentfulComponentsStyleOptions } from '../contentToPropsMap'
import { CONTENTFUL_COMPONENTS_STYLE_MAP } from '../contentToPropsMap'

export const HIGHLIGHT_QUOTE_CONTENT_MODEL_ID = 'cdxHighlightBlockQuote'

export type ComponentAuthorFields = {
  authorName: string
  authorTitle?: string
  authorImage?: Asset
  authorBioRichText?: Document
}

export type HighlightBlockQuoteFields = {
  style: ContentfulComponentsStyleOptions
  quote: string
  attribution: Entry<ComponentAuthorFields>
  anchor?: string
  link?: Entry<CDXGlobalNavLinkFields>
  hasSpacingTop?: boolean
}

type InnerProps = {
  isFullWidth?: boolean
}

export const HighlightBlockQuote = ({
  style,
  quote,
  attribution,
  hasSpacingTop,
  isFullWidth = true,
}: HighlightBlockQuoteFields & InnerProps) => {
  const styleValue = CONTENTFUL_COMPONENTS_STYLE_MAP[style]

  const { authorName, authorTitle, authorImage } = getEntryProps(
    attribution,
    {},
  )

  return (
    <HighlightBlockQuoteComponent
      quote={quote}
      authorPhotoUrl={authorImage?.fields.file.url}
      authorName={authorName || ''}
      authorTitle={authorTitle}
      style={styleValue}
      hasSpacingTop={hasSpacingTop}
      isFullWidth={isFullWidth}
    />
  )
}
