import type { Document } from '@contentful/rich-text-types'
import type { Asset, Entry } from 'contentful'

import type { IconName } from '@cbhq/cds-common'
import { HighlightImageText as HighlightImageTextComponent } from '@cbhq/cdx-components'
import type {
  HighlightLayout,
  ComponentsThemeStyle,
  ImageFit,
} from '@cbhq/cdx-components'

import { mapEntryLink } from '../../globals/navlink'
import type { CDXGlobalNavLinkFields } from '../../globals/navlink'

export type HighlightImageTextFields = {
  eyebrow?: string
  headline: string
  subhead?: string
  list?: Document
  listStyle?: IconName
  media?: Asset
  imageFit?: ImageFit
  legalText?: string
  link?: Entry<CDXGlobalNavLinkFields>
  layout: HighlightImageTextLayout
  style?: ComponentsThemeStyle
  anchor?: string
  ctaButtonLabel?: string
  ctaButtonUrl?: string
  hasSpacingTop?: boolean
  hasLightbox?: boolean
}

export const HIGHLIGHT_IMAGE_TEXT_CONTENT_MODEL_ID = 'cdxHighlightImageText'

export type HighlightImageTextLayout = 'Left' | 'Right' | 'Stacked' | 'Vertical'

const layoutMap: {
  [key in HighlightImageTextLayout]: HighlightLayout
} = {
  Left: 'left',
  Right: 'right',
  Stacked: 'stacked',
  Vertical: 'vertical',
}

export const HighlightImageText = ({
  headline,
  list,
  listStyle,
  eyebrow,
  subhead,
  legalText,
  media,
  imageFit,
  link,
  layout,
  style,
  anchor,
  ctaButtonLabel,
  ctaButtonUrl,
  hasSpacingTop = true,
  hasLightbox,
}: HighlightImageTextFields) => {
  const navLink = link && mapEntryLink(link)

  return (
    <HighlightImageTextComponent
      eyebrow={eyebrow}
      headline={headline}
      subhead={subhead}
      list={list}
      listStyle={listStyle}
      legalText={legalText}
      mediaUrl={media?.fields.file?.url}
      mediaAlt={media?.fields.title}
      imageFit={imageFit}
      layout={layoutMap[layout]}
      ctaButtonLabel={ctaButtonLabel}
      ctaButtonUrl={ctaButtonUrl}
      secondaryButtonLabel={navLink?.label}
      secondaryButtonUrl={navLink?.url}
      hasSpacingTop={hasSpacingTop}
      style={style}
      anchor={anchor}
      hasLightbox={hasLightbox}
    />
  )
}
