import styled from 'styled-components'

import type { PaletteAlias } from '@cbhq/cds-common'

import type { HeroFeaturedEditorialContentProps } from './Content'
import { HeroFeaturedEditorialContent } from './Content'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { colorToVariable, componentsThemeMap } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { SectionComponentLayout } from '../../layout/SectionComponentLayout'
import { TwoColumnLayout } from '../../layout/TwoColumnLayout'
import { AnalyticsProvider } from '../../providers/AnalyticsProvider'
import { Div } from '../../ui/Div'
import { ErrorBoundary } from '../../ui/ErrorBoundary'
import { LinkPressable } from '../../ui/Link'
import { Media } from '../../ui/media'

export type HeroFeaturedEditorialProps = HeroFeaturedEditorialContentProps & {
  mediaUrl?: string
  mediaAlt?: string
  style?: ComponentsThemeStyle
  layout: 'left' | 'right'
  imageFit?: 'cover' | 'contain'
  bordered?: boolean
  anchor?: string
}

const DEFAULT_ALT_TEXT = 'Hero image'

export const HeroFeaturedEditorial = ({
  mediaUrl,
  mediaAlt,
  layout,
  imageFit = 'cover',
  bordered = false,
  style = 'default',
  anchor,
  ...contentProps
}: HeroFeaturedEditorialProps) => {
  const { background } = componentsThemeMap[style]

  const animationColor = background === 'primary' ? 'background' : 'primary'

  const textContent = (
    <HeroFeaturedEditorialContent
      {...contentProps}
      layout={layout}
      style={style}
    />
  )

  const imageContent = (
    <LinkPressable
      href={contentProps.url || ''}
      width="100%"
      data-qa={ANALYTICS_KEY.SecondaryImage}
    >
      <Media
        mediaType="image"
        priority
        loading="eager"
        alt={mediaAlt || DEFAULT_ALT_TEXT}
        src={mediaUrl}
        maxHeight={{
          sm: 419,
          md: 'none',
        }}
        imageFit={imageFit}
        className={IMAGE_CLASS}
        bordered={bordered}
        overflow="hidden"
      />
    </LinkPressable>
  )

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HeroFeaturedEditorial">
        <SectionComponentLayout
          as="header"
          hasSpacingHorizontal
          background={background}
          anchor={anchor}
        >
          <Div spacingTop={5}>
            <AnimationContainer $hoverColor={animationColor}>
              <TwoColumnLayout
                mainContentPosition={layout}
                mainContent={textContent}
                mainContentPercentage={34}
                secondaryContent={imageContent}
                secondaryContentPercentage={66}
                mainContentPositionOnWrap="bottom"
                wrapOnTablet
              />
            </AnimationContainer>
          </Div>
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}

const IMAGE_CLASS = 'featured-image'

const AnimationContainer = styled(Div)<{ $hoverColor: PaletteAlias }>`
  display: contents;

  &:hover {
    h1 {
      color: ${({ $hoverColor }) => colorToVariable($hoverColor)};
    }
  }
`
