import type { Entry } from 'contentful'

import { getEntryProps } from './getEntryProps'
import type { WayfindingCardArticleFields } from '../components/WayfindingCardArticle'
import { fetchArticlesWithTag } from '../queries/fetchArticlesWithTag'
import type { ContentfulQueryOptions } from '../queries/types'

/**
 * Fetches articles based on card generation 'automatic', depending on the number of columns and rows, and injects into the cards (non-pure).
 */
export const handleAutomaticWayfinding = async (
  productTag: string,
  wayfinding: Entry<WayfindingCardArticleFields>,
  queryOpts: ContentfulQueryOptions,
) => {
  const { cardGeneration, tags, columns, rows } = getEntryProps(wayfinding)

  if (cardGeneration === 'manual') {
    return wayfinding
  }

  const limit = columns && typeof rows === 'number' ? columns * rows : 20

  const articles = (
    await fetchArticlesWithTag(productTag, tags, queryOpts, limit)
  ).items

  wayfinding.fields.cards = articles
}
