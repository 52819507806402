import { customComponentThemeMap } from ':cbam/components/customComponentThemeMap'
import type { HeroPrimaryContentProps } from ':cbam/components/HeroPrimary/HeroPrimaryContent'
import { HeroPrimaryContent } from ':cbam/components/HeroPrimary/HeroPrimaryContent'
import type {
  ComponentsThemeStyle,
  ImageFit,
  LottieTypes,
  MediaType,
} from '@cbhq/cdx-components'
import {
  SectionComponentLayout,
  TwoColumnLayout,
  AnalyticsProvider,
  ErrorBoundary,
  Media,
  BackgroundBleed,
} from '@cbhq/cdx-components'

export type HeroPrimaryImageFit = ImageFit

export type HeroPrimaryProps = HeroPrimaryContentProps & {
  mediaUrl?: string
  mediaAlt?: string
  mediaType?: MediaType | 'none'
  style?: ComponentsThemeStyle
  layout: 'right' | 'left' | 'top' | 'bottom'
  imageFit?: HeroPrimaryImageFit
  lottiLoop?: boolean
  lottieType?: LottieTypes
  thumbnailUrl?: string
  thumbnailAlt?: string
  hasLightbox?: boolean
  anchor?: string
}

const DEFAULT_ALT_TEXT = 'Hero image'

export const HeroPrimaryComponent = ({
  style = 'default',
  layout,

  mediaUrl,
  mediaAlt,
  mediaType = 'image',
  hasLightbox,
  imageFit,
  lottiLoop,
  lottieType,
  anchor,

  thumbnailUrl,
  thumbnailAlt,

  ...contentProps
}: HeroPrimaryProps) => {
  const { background } = customComponentThemeMap[style]

  return (
    <ErrorBoundary>
      <AnalyticsProvider analyticsPrefix="HeroPrimary">
        <SectionComponentLayout
          as="header"
          hasSpacingHorizontal={false}
          anchor={anchor}
        >
          <BackgroundBleed background={background} />
          <TwoColumnLayout
            mainContentPosition={layout}
            mainContentPositionOnWrap={layout === 'bottom' ? 'bottom' : 'top'}
            mainContent={
              <HeroPrimaryContent
                {...contentProps}
                alignContent="start"
                style={style}
                layout={layout}
              />
            }
            mainContentPercentage={50}
            secondaryContent={
              <>
                {mediaType === 'image' && (
                  <Media
                    priority
                    loading="eager"
                    mediaType="image"
                    alt={mediaAlt || DEFAULT_ALT_TEXT}
                    src={mediaUrl}
                    maxHeight={
                      layout === 'top' || layout === 'bottom'
                        ? { sm: 197, md: 403, lg: 756 }
                        : { sm: 473, md: 'unset' }
                    }
                    imageFit={imageFit}
                    hasLightbox={hasLightbox}
                  />
                )}

                {mediaType === 'video' && mediaUrl && (
                  <Media
                    mediaType="video"
                    src={mediaUrl}
                    thumbnailUrl={thumbnailUrl}
                    thumbnailAlt={thumbnailAlt}
                  />
                )}

                {mediaType === 'lotti' && lottieType && (
                  <Media
                    mediaType="lotti"
                    lottieLoop={lottiLoop}
                    lottieType={lottieType}
                    lottieImageFit={imageFit}
                  />
                )}
              </>
            }
            secondaryContentPercentage={50}
          />
        </SectionComponentLayout>
      </AnalyticsProvider>
    </ErrorBoundary>
  )
}
