import type { Document } from '@contentful/rich-text-types'

import { LogoMark } from '@cbhq/cds-web/icons/LogoMark'

import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import { componentsThemeMap, colorToVariable } from '../../componentsThemeMap'
import { ANALYTICS_KEY } from '../../constants'
import { useGlobalMicrocopy } from '../../providers/MicrocopyProvider'
import { Div } from '../../ui/Div'
import { LinkPressable } from '../../ui/Link'
import { createListRichTextOptions } from '../../ui/RichText'
import { RichText } from '../../ui/RichText/RichText'
import { Text } from '../../ui/Text'
import { formatDateWithoutTimeZone } from '../../utils/formatDateWithoutTimeZone'

export type HeroFeaturedEditorialContentProps = {
  eyebrow?: string
  hasEyebrowIcon?: boolean
  headline: string
  excerpt?: string
  list?: Document
  legalText?: string
  readTime?: string
  publicationDate?: string
  categoryTagLabel?: string
  categoryTagUrl?: string
  url?: string
  layout: 'left' | 'right'
}

type HeroConentRenderProps = {
  style: ComponentsThemeStyle
}

export const HeroFeaturedEditorialContent = ({
  eyebrow,
  hasEyebrowIcon = false,
  headline,
  excerpt,
  list,
  legalText,
  categoryTagLabel,
  categoryTagUrl,
  readTime,
  publicationDate: date,
  url,
  layout,

  style,
}: HeroFeaturedEditorialContentProps & HeroConentRenderProps) => {
  const { foreground, foregroundSecondary, primary } = componentsThemeMap[style]
  const microcopies = useGlobalMicrocopy()

  return (
    <Div
      flexGrow={1}
      flexDirection="column"
      justifyContent="center"
      spacingStart={layout === 'right' ? { lg: 4 } : undefined}
      spacingEnd={layout === 'left' ? { lg: 4 } : undefined}
      spacingTop={5}
      spacingBottom={{ lg: 5 }}
      minHeight={{ lg: 420 }}
    >
      <LinkPressable href={url || ''} data-qa={ANALYTICS_KEY.Content}>
        <>
          {!!eyebrow && (
            <Div css={{ gap: '8px' }}>
              {hasEyebrowIcon && <LogoMark size={16} />}
              <Text variant="label2" as="p" color={foregroundSecondary}>
                {eyebrow}
              </Text>
            </Div>
          )}

          <Text
            as="h1"
            css={{ margin: 0 }}
            color={foreground}
            spacingTop={{ sm: 1, md: 2, lg: 3 }}
            variant={{ sm: 'title2', lg: 'display3' }}
          >
            {headline}
          </Text>

          {excerpt && (
            <Text
              as="p"
              variant="body"
              spacingTop={1}
              css={{ margin: 0 }}
              color={foregroundSecondary}
            >
              {excerpt}
            </Text>
          )}

          {list && (
            <Div
              css={{
                display: 'contents',
                p: {
                  color: colorToVariable(foregroundSecondary),
                },
              }}
            >
              <RichText
                content={list}
                richTextOptions={createListRichTextOptions()}
                color={foregroundSecondary}
              />
            </Div>
          )}

          {legalText && (
            <Text
              as="p"
              variant="legal"
              css={{ margin: 0 }}
              color={foregroundSecondary}
              spacingTop={{ sm: 3, lg: 5 }}
            >
              {legalText}
            </Text>
          )}
        </>
      </LinkPressable>

      <Div spacingTop={3} gap={1} alignItems="center">
        {categoryTagLabel &&
          (categoryTagUrl ? (
            <LinkPressable
              href={categoryTagUrl || ''}
              data-qa={ANALYTICS_KEY.CategoryTag}
            >
              <Text
                as="p"
                variant="legal"
                color={primary}
                css={{ textDecoration: 'underline', margin: 0 }}
              >
                {categoryTagLabel}
                {(date || readTime) && ','}
              </Text>
            </LinkPressable>
          ) : (
            <Text as="p" variant="legal" color={foreground} css={{ margin: 0 }}>
              {categoryTagLabel}
              {(date || readTime) && ','}
            </Text>
          ))}

        {date && (
          <Text variant="legal" as="p" color={foregroundSecondary}>
            {formatDateWithoutTimeZone(date, {
              month: 'short',
              year: 'numeric',
              day: 'numeric',
            })}
            {readTime && ','}
          </Text>
        )}

        {readTime && (
          <Text variant="legal" as="p" color={foregroundSecondary}>
            {readTime} {microcopies.global.readTime}
          </Text>
        )}
      </Div>
    </Div>
  )
}
