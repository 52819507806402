import type { ReactNode } from 'react'
import { useRef, useEffect, useState } from 'react'

import { createPortal } from 'react-dom'
import styled, { css, keyframes } from 'styled-components'

import { Div } from '../Div'

type LightboxProps = {
  close?: () => void
  children?: ReactNode
}

export const Lightbox = ({ close, children }: LightboxProps) => {
  const [shouldClose, setShouldClose] = useState(false)
  const overlayRef = useRef<HTMLDivElement>(document.createElement('div'))

  useEffect(() => {
    if (typeof document !== undefined) {
      if (document.body) document.body.appendChild(overlayRef.current)

      setShouldClose(false)
    }
    return () => {
      if (typeof document !== undefined && document.body) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.body.removeChild(overlayRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (shouldClose && close) {
      setTimeout(() => close(), 200)
    }
  }, [shouldClose, close])

  const handleKeyPress = (event: any) => {
    if (event.key === 'Escape' && !shouldClose) {
      setShouldClose(true)
    }
  }

  return createPortal(
    <Overlay
      flexDirection="column"
      zIndex={40}
      position="fixed"
      width="100%"
      height="100%"
      left={0}
      top={0}
      onKeyDown={handleKeyPress}
      css={{
        backgroundColor: '#000000e6',
      }}
      show={!shouldClose}
      onClick={() => setShouldClose(true)}
    >
      <Div justifyContent="flex-end">
        <CloseButton onClick={() => setShouldClose(true)} />
      </Div>

      <Div spacing={1} flex={1} position="relative" css={{ margin: 24 }}>
        {children}
      </Div>
    </Overlay>,
    overlayRef.current,
  )
}

const CloseButton = styled.button`
  height: 32px;
  width: 32px;
  margin: 16px 16px 0 16px;

  font-size: 24px;

  background: none;
  color: white;

  :hover {
    color: gray;
    cursor: pointer;
  }

  ::before {
    content: '✕';
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`

const Overlay = styled(Div)<{
  show: boolean
}>`
  width: 100%;

  animation: ${fadeIn} ${({ show }) => (show ? 200 : 0)}ms ease-in;

  ${({ show }) =>
    !show &&
    css`
      animation: 200ms ${fadeOut} forwards;
    `}
`
