import dynamic from 'next/dynamic'

import { HERO_PRIMARY_CONTENT_MODEL_ID } from ':cbam/components/HeroPrimary'
import type { CMSComponentMap } from '@cbhq/cdx-components'

export const componentMap: CMSComponentMap = {
  [HERO_PRIMARY_CONTENT_MODEL_ID]: dynamic(() =>
    import('./HeroPrimary').then((x) => x.HeroPrimary),
  ),
}
