import type {
  ElevationLevels,
  PaletteAlias,
  SpectrumAlias,
} from '@cbhq/cds-web'

import { CardIllustrationIcon } from './CardIllustrationIcon'
import type { CardIllustrationIconProps } from './CardIllustrationIcon'
import type { ComponentsThemeStyle } from '../../componentsThemeMap'
import type { CtaType } from '../../ui/LinkArrow'
import { Div } from '../../ui/Div'
import { LinkArrow } from '../../ui/LinkArrow'
import { LinkPressable } from '../../ui/Link'
import { Text } from '../../ui/Text'
import { componentsThemeMap } from '../../componentsThemeMap'
import { palette } from '@cbhq/cds-web/tokens'
import styled from 'styled-components'
import { useMemo } from 'react'

const customComponentThemeMap: {
  [key in ComponentsThemeStyle]: {
    background: PaletteAlias | SpectrumAlias
    foreground: PaletteAlias
    foregroundSecondary: PaletteAlias
    elevation: ElevationLevels
  }
} = {
  default: {
    ...componentsThemeMap.default,
    elevation: 1,
  },
  dark: {
    background: 'gray80',
    foreground: 'background',
    foregroundSecondary: 'background',
    elevation: 0,
  },
  subtle: {
    ...componentsThemeMap.subtle,
    elevation: 0,
    background: 'background',
  },
  wash: {
    ...componentsThemeMap.subtle,
    elevation: 0,
    background: 'background',
  },
  darkwash: {
    background: 'gray80',
    foreground: 'background',
    foregroundSecondary: 'background',
    elevation: 0,
  },
  primary: {
    elevation: 0,
    background: 'background',
    foreground: 'foreground',
    foregroundSecondary: 'foreground',
  },
}

export type CardIllustrationProps = {
  style?: ComponentsThemeStyle
  layout?: 'vertical' | 'horizontal'
  eyebrowLabel?: string
  title: string
  description?: string
  longDescription?: string
  ctaLabel?: string
  ctaUrl?: string
  hasArrow?: boolean
  ctaType?: CtaType
} & CardIllustrationIconProps

export const CardIllustration = ({
  layout = 'vertical',
  eyebrowLabel,
  title,
  description,
  longDescription,
  ctaLabel,
  ctaUrl,
  ctaType,
  hasArrow = false,
  style = 'default',
  ...iconProps
}: CardIllustrationProps) => {
  const isCardCta = Boolean(ctaUrl)
  const isVertical = layout === 'vertical'
  const showIcon =
    iconProps?.icon || iconProps?.pictogram || iconProps?.imageUrl

  const LinkWrapper = useMemo(() => {
    if (!ctaUrl || hasArrow) {
      const Wrapper = ({ children }: { children: React.ReactElement }) => (
        // This div is necessary to avoid issues with height calculation in Safari
        <Div as="div">{children}</Div>
      )
      return Wrapper
    } else {
      const Link = ({ children }: { children: React.ReactElement }) => (
        <LinkPressable href={ctaUrl}>{children}</LinkPressable>
      )

      return Link
    }
  }, [ctaUrl, hasArrow])

  const { elevation, background, foreground, foregroundSecondary } =
    customComponentThemeMap[style]

  return (
    <LinkWrapper>
      <Div height="100%" width="100%">
        <CardContainer
          flexDirection={isVertical ? 'column' : 'row'}
          gap={isVertical ? 3 : 6}
          background={background}
          elevation={elevation}
          spacing={isCardCta ? 4 : { sm: 2, md: 5 }}
          borderRadius="roundedSmall"
          minHeight={
            isVertical ? (isCardCta ? 256 : { sm: 150, md: 300 }) : 'none'
          }
          height={isVertical ? '100%' : 'unset'}
          width="100%"
          $hasAnimation={Boolean(ctaUrl && !hasArrow)}
        >
          {showIcon && (
            <CardIllustrationIcon
              {...iconProps}
              maxWidth={!isVertical ? 80 : 'unset'}
              height={isVertical && isCardCta ? (iconProps.icon ? 24 : 48) : 80}
              iconColor={foreground}
            />
          )}

          <Div flexDirection="column" flex={1}>
            <StyledTitle content={eyebrowLabel}>
              <Text
                as="span"
                color={foreground}
                variant={
                  !isVertical
                    ? 'headline'
                    : { sm: 'title4', md: isCardCta ? 'title4' : 'title2' }
                }
              >
                {title}
              </Text>
            </StyledTitle>

            <Text
              as="p"
              variant={
                !isVertical
                  ? 'body'
                  : { sm: 'label2', md: isCardCta ? 'label2' : 'body' }
              }
              spacingTop={1}
              color={foregroundSecondary}
            >
              {description ? description : longDescription}
            </Text>
          </Div>

          {ctaUrl && hasArrow && (
            <LinkArrow ctaType={ctaType} ctaUrl={ctaUrl}>
              {ctaLabel}
            </LinkArrow>
          )}
        </CardContainer>
      </Div>
    </LinkWrapper>
  )
}

const CardContainer = styled(Div)<{
  $hasAnimation?: boolean
}>`
  ${({ $hasAnimation }) =>
    $hasAnimation &&
    `
      &:hover {
        transition: transform .3s;
        transform: scale(1.01);
      }
  `}
`

const StyledTitle = styled.div<{ content?: string }>`
  ${({ content }) =>
    content
      ? `> span:after {
    content: '${content}';
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 10px;
    white-space: nowrap;
    height: 15px;
    padding: 0 6px;
    margin-left: 8px;
    background: ${palette.primary};
    color: ${palette.background};
  }`
      : ''}
`
